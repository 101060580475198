import classNames from 'classnames'
import { useRef, useState, useEffect, useCallback, useContext } from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { createUseStyles } from 'react-jss'
import { useLocation } from 'react-router-dom'
import CreateJs from 'preload-js'
import { Context as GeneralContext } from '@/context'
import { Context as LoadingContext } from '@/context/loading'
import LocomotiveScroll from '@/components/LocomotiveScroll'
import Meta from '@/components/Meta'
import Hero from '@/components/Hero'
import InViewTrigger from '@/components/InViewTrigger'
import RevealText from '@/components/RevealText'
import FeaturedNews from '@/components/FeaturedNews'
import Footer from '@/components/Footer'
import PageElements from '@/components/PageElements'
import useHandleAllMediaWithCb from '@/hooks/useHandleAllMediaWithCb'
import * as contentActions from '@/actions/content'
import { getSlug } from '@/utils/path'
import style from './style'

const useStyles = createUseStyles(style)

const Post = () => {
  const { setPageAnimationReady, headerHeight, setBackButtonVisible } = useContext(GeneralContext)
  const { setReady, setSiteLoaded } = useContext(LoadingContext)
  const classes = useStyles({ headerHeight })
  const [load, setLoad] = useState(false)
  const preload = useRef(new CreateJs.LoadQueue())
  const location = useLocation()
  const stub = useRef(getSlug(location.pathname))
  const $root = useRef()

  /*------------------------------
  Executes a callback on loading each image
  ------------------------------*/
  useHandleAllMediaWithCb({
    init: load,
    ref: $root.current?.ref,
  })

  /*------------------------------
  Redux Connect
  ------------------------------*/
  const { post, relatedNews } = useSelector((state) => ({
    post: state.content.posts.find((p) => p.slug === stub.current) || {},
    relatedNews: state.content.posts.find((p) => p.slug === stub.current)?.acf.related_news,
  }), shallowEqual)

  /*------------------------------
  Redux Actions
  ------------------------------*/
  const dispatch = useDispatch()
  const fetchPost = useCallback((slug) => dispatch(contentActions.fetchPost(slug)), [dispatch])

  /*------------------------------
  Complete Preload
  ------------------------------*/
  const completePreload = () => {
    setReady(true)
    setSiteLoaded(true)
    setLoad(true)
  }

  /*------------------------------
  Preload Media
  ------------------------------*/
  const preloadMedia = () => {
    preload.current.on('complete', completePreload)
    if (post.featured_image) preload.current.loadFile({ src: post.featured_image })
  }

  /*------------------------------
  Preload Media when there are Data of Page in Redux
  ------------------------------*/
  useEffect(() => {
    if (Object.keys(post).length > 0) preloadMedia()
  }, [post])

  /*------------------------------
  Fetch all data
  ------------------------------*/
  const fetchData = (slug) => {
    if (Object.keys(post).length === 0) fetchPost(slug, true)
    return false
  }

  /*------------------------------
  Initialize
  ------------------------------*/
  const initialize = () => {
    fetchData(stub.current)
  }

  /*------------------------------
  Did Mount
  ------------------------------*/
  useEffect(() => {
    if (!load) initialize()
    setBackButtonVisible(true)
    return () => {
      setBackButtonVisible(false)
    }
  }, [])

  /*------------------------------
  Activate Page Animation
  ------------------------------*/
  useEffect(() => {
    if (load) setPageAnimationReady(true)
  }, [load])

  /*------------------------------
  Render Helmet
  ------------------------------*/
  const renderHelmet = () => {
    return load && (
      <Meta
        title={post.title.rendered}
        meta={post.yoast_meta}
        schema={post.yoast_json_ld}
      />
    )
  }

  /*------------------------------
  Render Hero
  ------------------------------*/
  const renderHero = () => {
    return Object.keys(post).length > 0 && (
      <Hero
        image={post.featured_image}
        imageMobile={post.acf.featured_image_mobile?.url}
        title={post.title.rendered}
        text={post.content.rendered}
        verticalCentered={false}
      />
    )
  }

  /*------------------------------
  Render Elements
  ------------------------------*/
  const renderElements = () => {
    return post.acf.page_builder && (
      <PageElements
        className={classes.pageElements}
        elements={post.acf.page_builder}
      />
    )
  }

  /*------------------------------
  Render FeaturedEvents
  ------------------------------*/
  const renderRelatedNews = () => {
    return relatedNews?.length > 0 && (
      <div className={classNames({
        [classes.wrapper]: true,
        [classes.wrapperFeaturedNews]: true,
      })}
      >
        {/* <div className={classes.featuredProductsHeading}>
          <span className={classes.pretitle}>{page.acf.pretitle_rp}</span>
        </div> */}
        <InViewTrigger className={classes.featuredProductsHeading}>
          <RevealText
            tag="h2"
            type="lines"
            value={post.acf.pretitle_rn}
            className={classes.pretitle}
          />
        </InViewTrigger>
        <FeaturedNews
          items={relatedNews.map((p) => ({
            id: p.id,
            title: p.title.rendered,
            description: p.content.rendered,
            link: p.link,
            image: p.featured_image,
          }))}
        />
      </div>
    )
  }

  /*------------------------------
  Render Footer
  ------------------------------*/
  const renderFooter = () => <Footer />

  /*------------------------------
  Render Content
  ------------------------------*/
  const renderContent = () => {
    return load && (
      <>
        <div className={classes.page}>
          {renderHero()}
          {renderElements()}
          {renderRelatedNews()}
        </div>
        {renderFooter()}
      </>
    )
  }

  return (
    <>
      <LocomotiveScroll
        init={load}
        className={`post pagePost ${classes.root}`}
        ref={$root}
      >
        {renderHelmet()}
        {renderContent()}
      </LocomotiveScroll>
    </>
  )
}

export default Post
