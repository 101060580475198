const style = (theme) => {
  const output = {
    root: {
      overflow: 'hidden',
      // marginTop: 120,
      background: theme.colors[4],
      fontSize: 14,
    },
    container: {
      extend: theme.wrapper,
      margin: '0 auto',
      display: 'flex',
      justifyContent: 'space-between',
      paddingBottom: 100,
      paddingTop: 100,
    },
    info: {
      marginLeft: 70,
      '& p': {
        margin: 0,
      },
    },
    containerLeft: {
      display: 'flex',
      width: '50%',
      '& a': {
        color: theme.colors[0],
      },
      '& > p': {
        margin: 0,
        marginLeft: 70,
        display: 'inline-block',
      },
    },
    containerSocial: {
      display: 'flex',
      justifyContent: 'flex-end',
      '& a': {
        color: theme.colors[0],
        '& svg': {
          fill: theme.colors[0],
          height: 18,
          width: 18,
          marginLeft: 18,
        },
      },
    },
    containerCopy: {
      display: 'flex',
      justifyContent: 'flex-end',
      '& p': {
        margin: 0,
      },
      '& a': {
        color: theme.colors[0],
      },
      '& > *:first-child': {
        display: 'flex',
      },
      '& > *:last-child': {
        marginLeft: 70,
      },
    },
    containerRight: {
      // width: '30%',
      // '& > *:first-child': {
      //   textTransform: 'uppercase',
      //   marginBottom: 20,
      // },
      // '& > *:last-child': {
      //   marginTop: 40,
      // },
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    logo: {
      '& svg': {
        fill: theme.colors[0],
      },
    },
  }

  /*------------------------------
  MD
  ------------------------------*/
  output[theme.mediaInverse.md] = {
    wrapperBottom: {
      padding: '40px 0',
      display: 'block',
    },
    footerCopy: {
      marginTop: 20,
      flexDirection: 'column',
    },

    containerTop: {
      paddingTop: 100,
      paddingBottom: 100,
      display: 'block',
    },
    linkFooterTop: {
      '& span': {
        fontSize: 38,
      },
      '& svg': {
        display: 'none',
      },
    },
    containerSvg: {
      width: 'auto',
      display: 'block',
      textAlign: 'center',
      '&:nth-child(2)': {
        marginTop: 10,
      },
    },
    wrapperBottomLeft: {
      display: 'block',
      '& div': {
        marginRight: 0,
        marginBottom: 10,
      },
    },
  }

  /*------------------------------
  SM
  ------------------------------*/
  output[theme.mediaInverse.sm] = {
    root: {
      fontSize: 14,
    },
    containerLeft: {
      display: 'block',
      width: '100%',
    },
    info: {
      marginLeft: 0,
      marginTop: 30,
    },
    containerRight: {
      display: 'block',
      width: '100%',
    },
    container: {
      extend: theme.smallWrapper,
      display: 'block',
      paddingBottom: '70px !important',
      paddingTop: '70px !important',
    },
    containerSocial: {
      display: 'block',
      marginTop: 30,
      '& > *:first-child': {
        '& svg': {
          marginLeft: 0,
        },
      },
    },
    containerCopy: {
      display: 'block',
      '& > *:first-child': {
        marginTop: 30,
      },
      '& > *:last-child': {
        marginLeft: 0,
        marginTop: 15,
      },
    },
  }

  return output
}

export default style
