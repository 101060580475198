import { createContext, useState, useEffect } from 'react'
import { useSelector, shallowEqual } from 'react-redux'
import usePrevious from '@/hooks/usePrevious'

const Context = createContext({})
const { Provider, Consumer } = Context

const ContextComponent = ({
  children,
}) => {
  const [layout, setLayout] = useState('dark')
  const [isFullscreenMode, setFullscreenMode] = useState(false)
  const [headerHeight, setHeaderHeight] = useState(0)
  const [headerRef, setHeaderRef] = useState({})
  const [cookieRef, setCookieRef] = useState({})
  const [headerInverse, setHeaderInverse] = useState(false)
  const [isHome, setIsHome] = useState(false)
  const [isProductShop, setIsProductShop] = useState(false)
  const [pageAnimationReady, setPageAnimationReady] = useState(false)
  const [isBackButtonVisible, setBackButtonVisible] = useState(false)
  const [isBackVisible, setBackVisible] = useState(false)

  /*------------------------------
  Redux Connect
  ------------------------------*/
  const { pathname, routerAction } = useSelector((state) => ({
    routerAction: state.router.action,
    pathname: state.router.location.pathname,
  }), shallowEqual)
  const prevPathname = usePrevious(pathname)

  /*------------------------------
  Reset Page animations on change page
  ------------------------------*/
  useEffect(() => {
    if (prevPathname !== undefined && prevPathname !== pathname) {
      setPageAnimationReady(false)
    }
  }, [pathname])

  /*------------------------------
  Manage Back Button with router
  ------------------------------*/
  useEffect(() => {
    if (isBackButtonVisible && routerAction !== 'POP') setBackVisible(isBackButtonVisible)
    setBackVisible(isBackButtonVisible)
  }, [isBackButtonVisible])

  return (
    <Provider value={{
      layout,
      setLayout,
      isFullscreenMode,
      setFullscreenMode,
      headerHeight,
      setHeaderHeight,
      setHeaderRef,
      headerRef,
      setCookieRef,
      cookieRef,
      setHeaderInverse,
      headerInverse,
      isHome,
      setIsHome,
      isProductShop,
      setIsProductShop,
      setPageAnimationReady,
      pageAnimationReady,
      isBackButtonVisible,
      setBackButtonVisible,
      isBackVisible,
    }}
    >
      { children }
    </Provider>
  )
}

export {
  Context,
  Consumer,
}

export default ContextComponent
