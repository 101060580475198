import { useEffect, useContext } from 'react'
import injectSheet from 'react-jss'
import { Context as GeneralContext } from '@/context'
import { Context as LoadingContext } from '@/context/loading'
import style from './style'

const NotFound = ({
  classes,
}) => {
  const { setHeaderInverse } = useContext(GeneralContext)
  const { setReady, setSiteLoaded } = useContext(LoadingContext)

  useEffect(() => {
    setReady(true)
    setSiteLoaded(true)
  }, [])

  /*------------------------------
  Inverse Header
  ------------------------------*/
  useEffect(() => {
    setHeaderInverse(true)
    return () => {
      setHeaderInverse(false)
    }
  })

  return (
    <div className={`page page404 ${classes.root}`}>
      <h2 className={classes.title}>404</h2>
    </div>
  )
}

export default injectSheet(style)(NotFound)
