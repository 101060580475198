import { useRef, useEffect } from 'react'
import { createUseStyles } from 'react-jss'
import gsap from 'gsap'
import style from './style'

const useStyles = createUseStyles(style)

const ScrollIcon = (props) => {
  const classes = useStyles()
  const $component = useRef()
  const $wrapScroll = useRef()
  const $line = useRef()
  const timelineIn = useRef(false)
  const timelineLine = useRef(false)

  /*------------------------------
  Show
  ------------------------------*/
  useEffect(() => {
    const { show } = props
    if (show && timelineIn.current !== false) {
      gsap.to($line.current, 0.5, { autoAlpha: 1, delay: 0.7 })
      timelineIn.current.play()
    }
  }, [props.show])

  /*------------------------------
  Did Mount
  ------------------------------*/
  useEffect(() => {
    const { show } = props
    timelineIn.current = gsap.timeline({
      paused: true,
    })
    timelineIn.current
      .fromTo($component.current, 1, { opacity: 0 }, { opacity: 1, ease: 'none' }, 0)

    if (props.line !== false) {
      timelineLine.current = gsap.timeline({ repeat: -1 })
      timelineLine.current
        .fromTo($line.current, 2.8, { y: '-100%' }, { y: '100%', ease: 'power4.out' })
      if (show) {
        gsap.to($line.current, 0.5, { autoAlpha: 1, delay: 0.7 })
        timelineIn.current.play()
      }
    }
  }, [])

  return (
    <div
      className={classes.component}
      ref={$component}
    >
      {props.label && (
        <div className={classes.wrapScroll} ref={$wrapScroll}>
          <div className={classes.text}>{props.label}</div>
        </div>
      )}
      {props.line && (
        <div className={classes.wrapperLine}>
          <div className={classes.line} ref={$line} />
        </div>
      )}
    </div>
  )
}

ScrollIcon.defaultProps = {
  label: '',
  line: false,
  show: false,
}

export default ScrollIcon
