import { useEffect, useRef, useContext } from 'react'
import { createUseStyles } from 'react-jss'
import classNames from 'classnames'
import Logo from '@/components/Logo'
import { Context as GeneralContext } from '@/context'
import { Context as LoadingContext } from '@/context/loading'
import gsap from 'gsap'
import style from './style'

const useStyles = createUseStyles(style)

const Loader = () => {
  const classes = useStyles()
  const { siteLoaded } = useContext(LoadingContext)
  const { setPageAnimationReady } = useContext(GeneralContext)
  const $root = useRef()
  const $logo = useRef()
  const $spinner = useRef()

  const animateLoaderEnd = () => {
    const timeline = gsap.timeline({ onComplete: () => setPageAnimationReady(true) })
    timeline
      .to([$logo.current, $spinner.current], ({
        autoAlpha: 0,
      }), 1.2)
      .to($root.current, ({
        autoAlpha: 0,
      }), 2)
  }

  useEffect(() => {
    if (siteLoaded) animateLoaderEnd()
  }, [siteLoaded])

  /*------------------------------
  Render
  ------------------------------*/
  return (
    <div
      className={classNames({
        [classes.root]: true,
        [classes.hide]: siteLoaded,
      })}
      ref={$root}
    >
      <div className={classes.logoContainer}>
        <Logo
          className={classes.logo}
          ref={$logo}
        />
      </div>
      <div className={classes.spinnerContainer}>
        <div
          ref={$spinner}
          className={classes.spinner}
        />
      </div>
    </div>
  )
}

export default Loader
