const style = (theme) => {
  const output = {
    menuWrapper: {
      position: 'relative',
    },
    follow: {
      position: 'absolute',
      overflow: 'hidden',
      zIndex: 99,
      width: 360,
      height: 245,
      marginLeft: 0,
      opacity: 0,
      top: 0,
      left: 0,
      // top: '10%',
      // right: '25%',
      // marginTop: -30,
      // marginRight: -30,
      pointerEvents: 'none',
      '& img': {
        position: 'absolute',
        zIndex: 1,
        top: 0,
        left: 0,
        objectFit: 'cover',
        opacity: 0,
        height: '100%',
        width: '100%',
        willChange: 'opacity',
      },
    },
    // navItemWrapper: {
    //   '& a': {
    //     position: 'relative',
    //     fontSize: 80,
    //     fontFamily: theme.fonts[1],
    //     lineHeight: 1.1,
    //     textTransform: 'none',
    //     '& span': {
    //       pointerEvents: 'none',
    //       color: theme.colors[0],
    //       '-webkit-text-fill-color': 'transparent',
    //       '-webkit-text-stroke-width': '1px',
    //       '-webkit-text-stroke-color': theme.colors[0],
    //     },
    //     '&:after': {
    //       pointerEvents: 'none',
    //       color: theme.colors[0],
    //       '-webkit-text-fill-color': theme.colors[0],
    //       '-webkit-text-stroke': 'unset',
    //       position: 'absolute',
    //       // top: '-6px',
    //       // bottom: 0,
    //       left: 0,
    //       display: 'inline-block',
    //       overflow: 'hidden',
    //       width: '0%',
    //       whiteSpace: 'pre',
    //       content: 'attr(data-text)',
    //       transition: 'width 0.6s cubic-bezier(0.86, 0, 0.07, 1) 0.1s',
    //       willChange: 'width',
    //     },
    //     '&:hover': {
    //       '&:after': {
    //         width: 'calc(100% + 10px)',
    //         fallbacks: {
    //           width: '120%',
    //         },
    //       },
    //     },
    //   },
    //   '& .divider': {
    //     position: 'absolute',
    //     bottom: 15,
    //     left: 0,
    //     right: 0,
    //     width: '100%',
    //     height: 1,
    //     backgroundColor: theme.colors[4],
    //   },
    // },
    // navItemWrapperItemHover: {
    //   '& a': {
    //     color: theme.colors[4],
    //   },
    // },
    wrapperLink: {
      fontFamily: theme.fonts[1],
      color: theme.colors[1],
      fontSize: 80,
      position: 'relative',
      // '& svg': {
      //   position: 'absolute',
      //   top: 0,
      //   left: 0,
      //   width: '100%',
      //   height: '100%',
      //   pointerEvents: 'none',
      //   fill: 'transparent',
      //   strokeWidth: '1px',
      //   stroke: theme.colors[0],
      //   strokeDasharray: 1220,
      //   strokeDashoffset: 1220,
      //   transition: 'stroke-dashoffset 0.6s cubic-bezier(.455,.03,.515,.955)',
      //   '& path': {
      //     width: '100%',
      //     height: '100%',
      //   },
      // },
      // '&:hover svg': {
      //   strokeDashoffset: 0,
      // },
      '& p': {
        margin: 0,
        marginTop: 30,
        mixBlendMode: 'difference',
        position: 'relative',
        zIndex: 100,
      },
    },
    productHoverTrigger: {
      width: 'fit-content',
    },
    containerItems: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      /* '&:nth-child(1)': {
        width: 300,
      },
      '&:nth-child(2)': {
        width: 400,
      },
      '&:nth-child(3)': {
        width: 500,
      }, */
    },
    item: {
      display: 'block',
      height: 'fit-content',
      '&:nth-child(6n+1)': {
        width: '35%',
        marginRight: '20%',
        '& span': {
          height: '19vw',
          '& img': {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            objectPosition: '50% 50%',
          },
        },
      },
      '&:nth-child(6n+2)': {
        width: '45%',
        marginTop: '16vw',
        '& span': {
          height: '24vw',
          '& img': {
            width: '100%',
            height: '120%',
            objectFit: 'cover',
            objectPosition: '50% 50%',
          },
        },
      },
      '&:nth-child(6n+3)': {
        width: '30%',
        marginTop: '-6vw',
        marginRight: '20%',
        '& span': {
          height: '29vw',
          '& img': {
            width: '100%',
            height: '120%',
            objectFit: 'cover',
            objectPosition: '50% 50%',
          },
        },
      },
      '&:nth-child(6n+4)': {
        width: '35%',
        marginTop: '22vw',
        '& span': {
          height: '19vw',
          '& img': {
            width: '100%',
            height: '120%',
            objectFit: 'cover',
            objectPosition: '50% 50%',
          },
        },
      },
      '&:nth-child(6n+5)': {
        width: '45%',
        marginTop: '-4vw',
        '& span': {
          height: '24vw',
          '& img': {
            width: '100%',
            height: '120%',
            objectFit: 'cover',
            objectPosition: '50% 50%',
          },
        },
      },
      '&:nth-child(6n+6)': {
        width: '30%',
        marginTop: '18vw',
        '& span': {
          height: '29vw',
          '& img': {
            width: '100%',
            height: '120%',
            objectFit: 'cover',
            objectPosition: '50% 50%',
          },
        },
      },
      '&:nth-child(7)': {
        marginTop: '-4vw',
      },
      '& p': {
        fontSize: 20,
        color: theme.colors[4],
        textTransform: 'uppercase',
        margin: 0,
        marginTop: 20,
      },
      '& svg': {
        fill: theme.colors[4],
        transform: 'rotate(-45deg)',
        transformOrigin: 'center',
        transition: `all .3s ${theme.easings['power3.inOut']}`,
        width: 21,
        height: 21,
        marginTop: 20,
      },
      '&:hover svg': {
        transform: 'rotate(0deg)',
      },
    },
    containerDiscover: {
      display: 'flex',
      justifyContent: 'space-between',
    },
  }

  /*------------------------------
  SM
  ------------------------------*/
  output[theme.mediaInverse.sm] = {
    follow: {
      display: 'none',
    },
    menuLines: {
      flexDirection: 'column',
    },
    navItemWrapper: {
      display: 'block',
      '& .divider': {
        bottom: 8,
      },
    },
    wrapperLink: {
      fontSize: 38,
      '& svg': {
        display: 'none',
      },
    },
    containerItems: {
      display: 'block',
    },
    item: {
      // height: 'fit-content',
      '&:nth-child(6n+1)': {
        width: '100%',
        marginRight: 0,
        '& span': {
          height: '60vw',
        },
      },
      '&:nth-child(6n+2)': {
        width: '100%',
        marginTop: 70,
        '& span': {
          height: '60vw',
        },
      },
      '&:nth-child(6n+3)': {
        width: '100%',
        marginTop: 70,
        marginRight: 0,
        '& span': {
          height: '60vw',
        },
      },
      '&:nth-child(6n+4)': {
        width: '100%',
        marginTop: 70,
        '& span': {
          height: '60vw',
        },
      },
      '&:nth-child(6n+5)': {
        width: '100%',
        marginTop: 70,
        '& span': {
          height: '60vw',
        },
      },
      '&:nth-child(6n+6)': {
        width: '100%',
        marginTop: 70,
        '& span': {
          height: '60vw',
        },
      },
      '&:nth-child(7)': {
        marginTop: 70,
      },
    },
  }

  return output
}

export default style
