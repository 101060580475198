import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle } from 'react'
import classNames from 'classnames'
import { createUseStyles } from 'react-jss'
import YouTube from 'react-youtube'
import VideoTimeline from '@/components/YoutubeVideoTimeline'
import usePrevious from '@/hooks/usePrevious'
import style from './style'

const useStyles = createUseStyles(style)

const YoutubeVideo = forwardRef(({
  videoId,
  poster,
  className,
  autoplay,
}, ref) => {
  const classes = useStyles(style)
  const [isPlaying, setPlaying] = useState(false)
  const [isFirst, setFirst] = useState(true)
  const [isLoading, setLoading] = useState(false)
  const $video = useRef(null)
  const videoPlayer = useRef(null)
  const posterImg = useRef(`https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`)
  const opts = useRef({
    playerVars: {
      autoplay: 0,
      controls: 0,
      showinfo: 0,
      color: 'white',
      rel: 0,
      fs: 0,
      modestbranding: 1,
      playsinline: 1,
    },
    host: 'https://www.youtube-nocookie.com',
  })

  const onClickPlay = () => {
    if (videoPlayer.current) {
      if (isFirst) {
        videoPlayer.current.playVideo()
        videoPlayer.current.mute()
        setLoading(true)
      } else {
        videoPlayer.current.playVideo()
        videoPlayer.current.unMute()
        setPlaying(true)
      }
    }
  }

  useImperativeHandle(ref, () => ({
    play: onClickPlay,
  }))

  const prevIsFirst = usePrevious(isFirst)
  useEffect(() => {
    if (prevIsFirst && !isFirst) {
      onClickPlay()
      setLoading(false)
    }
  }, [isFirst])

  const onReady = (event) => {
    videoPlayer.current = event.target
    if (autoplay) onClickPlay()
  }

  const onStateChange = (e) => {
    if (e.target.getPlayerState() === 1 && isFirst) {
      setTimeout(() => videoPlayer.current.pauseVideo(), 100)
      setTimeout(() => videoPlayer.current.seekTo(0), 200)
      setTimeout(() => setFirst(false), 3000)
    }
  }

  const onClickPause = () => {
    if (videoPlayer.current) setPlaying(false)
  }

  const onEnd = () => {
    videoPlayer.current.seekTo(0)
    onClickPause()
  }

  const prevIsPlaying = usePrevious(isPlaying)
  useEffect(() => {
    if (prevIsPlaying && !isPlaying) setTimeout(() => videoPlayer.current.pauseVideo(), 300)
  }, [isPlaying])

  return (
    <div className={`${classes.root} ${className} youtubeVideo`}>
      <YouTube
        ref={$video}
        videoId={videoId}
        opts={opts.current}
        onReady={onReady}
        onStateChange={onStateChange}
        onEnd={onEnd}
      />
      <div
        className={`${classNames({
          [classes.poster]: true,
          [classes.posterHide]: isPlaying,
        })} youtubeVideo__poster`}
        style={{ backgroundImage: `url(${poster || posterImg.current})` }}
      >
        <button
          className={`${classNames({
            [classes.play]: true,
            [classes.playHide]: isLoading,
          })} youtubeVideo__play`}
          type="button"
          onClick={onClickPlay}
        >
          <svg>
            <title>Play</title>
            <use xlinkHref="#ico-play" />
          </svg>
        </button>
        <div
          className={`${classNames({
            [classes.loading]: true,
            [classes.loadingShow]: isLoading,
          })} youtubeVideo__loading`}
        >
          <svg className="spinner" viewBox="0 0 50 50">
            <circle className="path" cx="25" cy="25" r="20" fill="none" />
          </svg>
        </div>
      </div>
      {videoPlayer.current && (
        <div className={`${classes.timeline} youtubeVideo__timeline`}>
          <VideoTimeline
            video={videoPlayer.current}
            isPlaying={isPlaying}
            onClickPause={onClickPause}
          />
        </div>
      )}
    </div>
  )
})

YoutubeVideo.defaultProps = {
  autoplay: false,
}

export default YoutubeVideo
