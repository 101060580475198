import { useRef, useContext, useCallback, useEffect, useState } from 'react'
import classNames from 'classnames'
import { createUseStyles, useTheme } from 'react-jss'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Logo from '@/components/Logo'
import Burger from '@/components/Burger'
import DelayLink from '@/components/DelayLink'
import LangSwitcher from '@/components/LangSwitcher'
import { Context } from '@/context'
import { Context as ScrollbarContext } from '@/context/scrollbar'
import { useRaf, useResize } from '@/components/Handlers'
import useMediaQuery from '@/hooks/useMediaQuery'
import * as layerActions from '@/actions/layer'
import style from './style'

const useStyles = createUseStyles(style)

const Header = ({
  treshold,
}) => {
  const { setHeaderRef, setHeaderHeight, headerInverse, isBackVisible } = useContext(Context)
  const { scroll } = useContext(ScrollbarContext)
  const classes = useStyles({ headerInverse })
  const theme = useTheme()
  const [isMainButtonHover, setMainButtonHover] = useState(false)
  const [isTop, setTop] = useState(true)
  const $root = useRef()
  const history = useHistory()
  const mediaQueryKey = useMediaQuery()
  const rootBounds = useRef()

  /*------------------------------
  Redux Connect
  ------------------------------*/
  const { isMenuOpen, headerMenu, languages } = useSelector((state) => ({
    isMenuOpen: state.layer.layers.some((layer) => layer.id === 'menu' && layer.isOpen),
    headerMenu: state.nav.header_menu,
    languages: state.locale.languages,
  }), shallowEqual)

  /*------------------------------
  Redux Actions
  ------------------------------*/
  const dispatch = useDispatch()
  const openMenu = useCallback(() => dispatch(layerActions.openMenu()), [dispatch])
  const closeMenu = useCallback(() => dispatch(layerActions.closeMenu()), [dispatch])

  const handleMouseMove = (e) => {
    if (e.type === 'mouseenter') setMainButtonHover(true)
    if (e.type === 'mouseleave') setMainButtonHover(false)
  }

  // share header ref to using it in <Nav/> for accessibility purpose
  useEffect(() => {
    setHeaderRef($root)
  }, [])

  useResize(() => {
    if ($root.current) {
      rootBounds.current = $root.current.getBoundingClientRect()
      setHeaderHeight($root.current.getBoundingClientRect().height)
    }
  })

  const handleMainMenuClick = () => {
    if (isMenuOpen) closeMenu()
    if (!isMenuOpen) openMenu()
  }

  const preventPressSpacebar = useCallback((node) => {
    if (node !== null) {
      node.addEventListener('keyup', (e) => {
        if (e.keyCode === 32 && e.code === 'Space') e.preventDefault()
      })
    }
  }, [])

  const update = () => {
    if (scroll.current.y <= treshold) setTop(true)
    if (scroll.current.y > treshold) setTop(false)
  }

  useRaf(() => {
    update()
  }, [isTop])

  // const handleCartClick = () => {
  //   if (window.Ecwid) window.Ecwid.Cart.gotoCheckout()
  // }

  /*------------------------------
  Render Burger
  ------------------------------*/
  const renderBurgerButton = useCallback(() => {
    return (
      <button
        className={classNames({
          [classes.burgerButton]: true,
        })}
        onClick={handleMainMenuClick}
        aria-label="toggle-main-menu"
        ref={preventPressSpacebar}
        onMouseEnter={(e) => handleMouseMove(e)}
        onMouseLeave={(e) => handleMouseMove(e)}
      >
        <Burger
          isActive={isMenuOpen}
          isHover={isMainButtonHover}
          color={theme.colors[4]}
          headerInverse={headerInverse}
          className={classNames({
            // [classes.changeBurgerColor]: !isTop,
            [classes.changeBurgerColor]: isMenuOpen,
          })}
        />
      </button>
    )
  }, [headerInverse, isMenuOpen, isMainButtonHover, isTop])

  // const renderCartButton = () => {
  //   return window.Ecwid && (
  //     <CartButton
  //       onClick={handleCartClick}
  //       link={linkShop}
  //     />
  //   )
  // }

  /*------------------------------
  Render BackButton
  ------------------------------*/
  const renderBackButton = () => {
    return (
      <button
        className={classNames({
          [classes.back]: true,
          [classes.hide]: !isBackVisible || isMenuOpen,
        })}
        onClick={() => history.goBack()}
        aria-label="go-back"
        ref={preventPressSpacebar}
      >
        <svg className={classes.arrow}><use xlinkHref="#arrow" /></svg>
      </button>
    )
  }

  return (
    <header
      className={classNames({
        [classes.root]: true,
        // [classes.background]: theme.detect.isNativeScroll ? !isTop : (direction !== 'down' && !isTop),
        // [classes.minimize]: direction === 'down' && !isTop && !theme.detect.isNativeScroll,
        [classes.minimize]: !isTop && !isMenuOpen,
      })}
      ref={$root}
    >
      <div className={classes.container}>
        <div className={classes.wrapper}>
          <div className={classNames({
            [classes.logo]: true,
            [classes.logoMinimize]: mediaQueryKey[0] <= 2 && isMenuOpen,
          })}
          >
            <Logo isTop={isTop} inHeader={true} />
          </div>
          <div className={classes.nav}>
            {languages && Object.keys(languages).length > 1 && (
            <div
              className={classNames({
                [classes.lang]: true,
                [classes.hideLang]: isMenuOpen,
              })}
            >
              <LangSwitcher />
            </div>
            )}
            {renderBackButton()}
            {headerMenu?.length > 0 && (
              headerMenu.map((item) => (
                <DelayLink
                  key={item.ID}
                  className={classNames({
                    [classes.headerLink]: true,
                    [classes.hideHeaderLink]: isMenuOpen,
                  })}
                  to={item.url}
                >
                  {item.title}
                </DelayLink>
              ))
            )}
            {renderBurgerButton()}
          </div>
        </div>
      </div>
    </header>
  )
}

Header.defaultProps = {
  treshold: 20,
  menuLabel: 'Menu',
}

export default Header
