import { useRef } from 'react'
import { createUseStyles } from 'react-jss'
import HTMLParse from 'react-html-parser'
import RevealText from '@/components/RevealText'
import RevealImage from '@/components/RevealImage'
import RevealFadeIn from '@/components/RevealFadeIn'
import InViewTrigger from '@/components/InViewTrigger'
import CarouselSimple from '@/components/CarouselSimple'
import Banner from '@/components/Banner'
import YoutubeVideo from '@/components/YoutubeVideo'
import style from './style'

const useStyles = createUseStyles(style)

const PageElements = ({
  className,
  elements,
}) => {
  const classes = useStyles()
  const $root = useRef()

  /*------------------------------
  Render ColumnText
  ------------------------------*/
  const renderColumnText = (el, i) => {
    const parser = new DOMParser()
    const doc = parser.parseFromString(el.text, 'text/html')
    if (doc.body.getElementsByTagName('hr').length > 0) {
      const splitted = el.text.split('<hr />')
      return Object.keys(el).length > 1 && (
        <div
          key={i}
          className={classes.columnText}
        >
          <InViewTrigger
            className={classes.columnTextTrigger}
          >
            <InViewTrigger className={classes.titleContainer}>
              <RevealText
                tag="h2"
                type="lines"
                value={el.title}
              />
            </InViewTrigger>
            <RevealFadeIn
              className={classes.textContainer1}
            >
              <div>{HTMLParse(splitted[0])}</div>
              <div>{HTMLParse(splitted[1])}</div>
            </RevealFadeIn>
          </InViewTrigger>
        </div>
      )
    } if (el.text.split('<br />').length > 30) {
      const allItems = []
      el.text.split('<br />').map((it) => {
        it = it.replace(/(<([^>]+)>)/gi, '')
        allItems.push(it)
        return null
      })
      return (
        <div
          key={i}
          className={classes.columnText}
        >
          <InViewTrigger
            className={classes.columnTextTrigger}
          >
            <InViewTrigger className={classes.titleContainer}>
              <RevealText
                tag="h2"
                type="lines"
                value={el.title}
              />
            </InViewTrigger>
            <RevealFadeIn
              className={classes.textContainer}
            >
              <p>
                {
                  allItems.map((c) => (
                    <>
                      <span>{HTMLParse(c)}</span>
                      <br />
                    </>
                  ))
                }
              </p>
            </RevealFadeIn>
          </InViewTrigger>
        </div>
      )
    }
    return Object.keys(el).length > 1 && (
      <div
        key={i}
        className={classes.columnText}
      >
        <InViewTrigger
          className={classes.columnTextTrigger}
        >
          <InViewTrigger className={classes.titleContainer}>
            <RevealText
              tag="h2"
              type="lines"
              value={el.title}
            />
          </InViewTrigger>
          <RevealFadeIn
            className={classes.textContainer}
          >
            {HTMLParse(el.text)}
          </RevealFadeIn>
        </InViewTrigger>
      </div>
    )
  }

  /*------------------------------
  Render SingleImage
  ------------------------------*/
  const renderSingleImage = (el, i) => {
    return Object.keys(el).length > 1 && (
      <div
        key={i}
        className={classes.singleImage}
      >
        <InViewTrigger>
          <RevealImage
            image={el.image.url}
            label={el.image.alt}
          />
        </InViewTrigger>
      </div>
    )
  }

  /*------------------------------
  Render SimpleText
  ------------------------------*/
  const renderSimpleText = (el, i) => {
    if (el.text.split('&nbsp').length > 1) {
      return (
        <div
          key={i}
          className={classes.simpleText}
        >
          <InViewTrigger>
            <RevealFadeIn>
              {HTMLParse(el.text)}
            </RevealFadeIn>
          </InViewTrigger>
        </div>
      )
    }
    return (
      <div
        key={i}
        className={classes.simpleText}
      >
        <InViewTrigger>
          <RevealText
            tag="h2"
            type="lines"
            value={el.text}
          />
        </InViewTrigger>
      </div>
    )
  }

  /*------------------------------
  Render Simple Title/Text
  ------------------------------*/
  const renderSimpleTitleText = (el, i) => {
    return Object.keys(el).length > 1 && (
      <div
        key={i}
        className={classes.simpleTitleText}
      >
        <InViewTrigger>
          <RevealText
            tag="h2"
            type="lines"
            value={el.title}
            className={classes.title}
          />
          <RevealFadeIn className={classes.text}>
            <div dangerouslySetInnerHTML={{ __html: el.text }} />
          </RevealFadeIn>
        </InViewTrigger>
      </div>
    )
  }

  /*------------------------------
  Render ArrowList
  ------------------------------*/
  const renderArrowList = (el, i) => {
    return Object.keys(el).length > 1 && (
      <div
        key={i}
        className={classes.arrowList}
      >
        <InViewTrigger>
          <RevealFadeIn>
            <ul>
              {el.list_items.map((item, index) => (
                <li
                  key={index.toString()}
                >
                  {item.item}
                </li>
              ))}
            </ul>
          </RevealFadeIn>
        </InViewTrigger>
      </div>
    )
  }

  /*------------------------------
  Render DoubleImage
  ------------------------------*/
  const renderDoubleImage = (el, i) => {
    return Object.keys(el).length > 1 && (
      <div
        key={i}
        className={classes.doubleImage}
      >
        <InViewTrigger
          className={classes.triggerDoubleImageN1}
        >
          <RevealImage
            image={el.double_gallery[0].url}
            label={el.double_gallery[0].caption}
            className={classes.doubleImageN1}
          />
          <RevealFadeIn>
            <h3>{HTMLParse(el.double_gallery[0].caption)}</h3>
            <p>{HTMLParse(el.double_gallery[0].description)}</p>
          </RevealFadeIn>
        </InViewTrigger>
        <InViewTrigger
          className={classes.triggerDoubleImageN2}
        >
          <RevealImage
            image={el.double_gallery[1].url}
            label={el.double_gallery[1].caption}
            className={classes.doubleImageN2}
          />
          <RevealFadeIn>
            <h3>{HTMLParse(el.double_gallery[1].caption)}</h3>
            <p>{HTMLParse(el.double_gallery[1].description)}</p>
          </RevealFadeIn>
        </InViewTrigger>
      </div>
    )
  }

  /*------------------------------
  Render Related Content
  ------------------------------*/
  const renderRelatedContent = (el, i) => {
    return el && (
      <Banner
        key={i}
        pretitle={el?.pretitle}
        image={el?.image?.url}
        title={el?.title}
        text={el?.text}
        link={el?.link}
      />
    )
  }

  /*------------------------------
  Render Related Page
  ------------------------------*/
  const renderRelatedPage = (el, i) => {
    return Object.keys(el).length > 1 && (
      <Banner
        key={i}
        pretitle={el.pretitle}
        image={el.related_page[0].featured_image}
        title={el.related_page[0].title?.rendered}
        text={el.related_page[0].content?.rendered}
        link={el.related_page[0].link}
      />
    )
  }

  /*------------------------------
  Render Gallery
  ------------------------------*/
  const renderGallery = (el, i) => {
    return Object.keys(el).length > 1 && (
      <div
        key={i}
        className={classes.gallery}
      >
        <InViewTrigger>
          <RevealFadeIn delay={0.5}>
            <CarouselSimple
              imagesList={el.free_gallery}
            />
          </RevealFadeIn>
        </InViewTrigger>
      </div>
    )
  }

  /*------------------------------
  Render Video
  ------------------------------*/
  const renderVideo = (el, i) => {
    return Object.keys(el).length > 1 && (
      <div
        className={classes.videoWrapper}
        key={i}
      >
        {el.title && (
          <h2 className={classes.videoTitle}>
            {el.title}
          </h2>
        )}
        <YoutubeVideo
          className={classes.video}
          videoId={el.id}
        />
      </div>
    )
  }

  /*------------------------------
  Render Page Elements
  ------------------------------*/
  const renderPageElements = (pageElements) => {
    return pageElements && pageElements.map((element, i) => {
      if (element.acf_fc_layout === 'column_text') return renderColumnText(element, i)
      if (element.acf_fc_layout === 'single_image') return renderSingleImage(element, i)
      if (element.acf_fc_layout === 'simple_text') return renderSimpleText(element, i)
      if (element.acf_fc_layout === 'simple_text_title') return renderSimpleTitleText(element, i)
      if (element.acf_fc_layout === 'arrow_list') return renderArrowList(element, i)
      if (element.acf_fc_layout === 'double_image') return renderDoubleImage(element, i)
      if (element.acf_fc_layout === 'related_content') return renderRelatedContent(element, i)
      if (element.acf_fc_layout === 'related_page') return renderRelatedPage(element, i)
      if (element.acf_fc_layout === 'gallery') return renderGallery(element, i)
      if (element.acf_fc_layout === 'youtube_video') return renderVideo(element, i)
      return null
    })
  }

  /*------------------------------
  Render
  ------------------------------*/
  return elements.length !== 0 && (
    <div
      className={`${classes.root} ${className}`}
      ref={$root}
    >
      {renderPageElements(elements)}
    </div>
  )
}

PageElements.defaultProps = {
  className: '',
  elements: [],
}

export default PageElements
