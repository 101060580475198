import { useState, useEffect, useRef, createRef } from 'react'
import { createUseStyles, useTheme } from 'react-jss'
import classNames from 'classnames'
import gsap from 'gsap'
import HTMLParse from 'react-html-parser'
import { useDrag } from 'react-use-gesture'
import style from './style'

const useStyles = createUseStyles(style)

const CarouselTeam = ({ items, className }) => {
  const $root = useRef()
  const $wrapper = useRef()
  const $bar = useRef()
  const $items = useRef(items.map(createRef))
  const [active, setActive] = useState(0)
  const [itemsWidth, setItemsWidth] = useState([])
  const [isDown, setIsDown] = useState(false)
  const classes = useStyles()
  const finalX = useRef(0)
  const theme = useTheme()
  const threshold = theme.detect.hasTouch ? 5 : 50

  /*--------------------
  Handle Resize
  --------------------*/
  const handleResize = () => {
    const arr = []
    let start = 0
    $items.current.forEach((item) => {
      arr.push(start)
      start += item.current.getBoundingClientRect().width
    })
    setItemsWidth(arr)
  }

  /*--------------------
  Init
  --------------------*/
  useEffect(() => {
    window.addEventListener('resize', handleResize)
    handleResize()

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  /*--------------------
  Animate
  --------------------*/
  const animate = () => {
    // const rootBounds = $root.current.getBoundingClientRect()
    // const activeBounds = $items.current[active].current.getBoundingClientRect()
    // finalX.current = rootBounds.width / 2 - activeBounds.width / 2 - itemsWidth[active]
    finalX.current = -itemsWidth[active] - 30
    gsap.to($wrapper.current, {
      x: finalX.current,
      ease: 'power3.out',
      duration: 1.4,
    })

    gsap.to($bar.current, {
      width: `${(100 / items.length) * (active + 1)}%`,
      ease: 'power3.out',
      duration: 1.4,
    })
  }
  useEffect(() => {
    animate()
  }, [active, itemsWidth, items])

  /*--------------------
  Reset Animation
  --------------------*/
  const resetAnimation = () => {
    gsap.to($wrapper.current, {
      x: finalX.current,
      ease: 'elastic.out(1, .55)',
      duration: 1.4,
    })
  }

  /*--------------------
  Handle Prev
  --------------------*/
  const handlePrev = () => {
    setActive(Math.max(0, active - 1))
  }

  /*--------------------
  Handle Next
  --------------------*/
  const handleNext = () => {
    setActive(Math.min(items.length - 1, active + 1))
  }

  /*--------------------
  Bind Drag
  --------------------*/
  const bindDrag = useDrag(({ event, down, movement, last, direction }) => {
    gsap.to($wrapper.current, {
      x: finalX.current + movement[0] * 0.3,
      ease: 'power3.out',
      duration: 0.3,
    })
    setIsDown(down)
    if (last) {
      if (Math.abs(movement[0]) > threshold) {
        event.preventDefault()
        if (direction[0] > 0 && active !== 0) {
          handlePrev()
        } else if (direction[0] < 0 && active !== $items.current.length - 1) {
          handleNext()
        } else {
          resetAnimation()
        }
      } else {
        resetAnimation()
      }
    }
  })

  /*--------------------
  Render Images
  --------------------*/
  const renderImages = () => items.map((item, index) => (
    <div
      className={classes.container}
      key={index.toString()}
    >
      <div
        key={index.toString()}
        ref={$items.current[index]}
        className={classNames({
          [classes.image]: true,
          'carousel-image': true,
          [classes.active]: active === index,
          [`carousel-image-${index}`]: true,
        })}
      >
        <img onLoad={handleResize} src={item.featured_image} alt={item.title} />
      </div>
      <h3>{HTMLParse(item.content.rendered)}</h3>
      <h2>{item.title.rendered}</h2>
    </div>
  ))

  /*--------------------
  Render Indicator
  --------------------*/
  const renderIndicator = () => (
    <div
      className={classNames({
        [classes.indicator]: true,
        'react-carousel--indicator': true,
      })}
    >
      {active + 1}
      {' '}
      -
      {' '}
      {items.length}
    </div>
  )

  /*--------------------
  Render Progess Bar
  --------------------*/
  const renderBar = () => (
    <div
      className={classNames({
        [classes.barWrapper]: true,
        'react-carousel--bar-wrapper': true,
      })}
    >
      <div
        ref={$bar}
        className={classNames({
          [classes.bar]: true,
          'react-carousel--bar': true,
        })}
      />
    </div>
  )

  /*--------------------
  Render Buttons
  --------------------*/
  const renderButtons = () => (
    <div
      className={classNames({
        [classes.buttons]: true,
        'react-carousel--bar-buttons': true,
      })}
    >
      <button
        className={classNames({
          [classes.button]: true,
          'react-carousel--bar-button': true,
        })}
        onClick={handlePrev}
      >
        <svg><use xlinkHref="#ico-arrow-left" /></svg>
      </button>
      <button
        className={classNames({
          [classes.button]: true,
          'react-carousel--bar-button': true,
        })}
        onClick={handleNext}
      >
        <svg><use xlinkHref="#ico-arrow-right" /></svg>
      </button>
    </div>
  )

  /*--------------------
  Render
  --------------------*/
  return (
    <div
      className={classNames({
        [className]: true,
        [classes.root]: true,
        [classes.down]: isDown,
        'react-carousel': true,
      })}
    >
      <div
        ref={$root}
        className={classNames({
          [classes.carousel]: true,
          'react-carousel--carousel': true,
        })}
      >
        <div
          ref={$wrapper}
          {...bindDrag()}
          className={classNames({
            [classes.wrapper]: true,
            'react-carousel--wrapper': true,
          })}
        >
          {renderImages()}
        </div>
        <div
          className={classNames({
            [classes.controllers]: true,
            'react-carousel--controllers': true,
          })}
        >
          {renderIndicator()}
          {renderBar()}
          {renderButtons()}
        </div>
      </div>
    </div>
  )
}

export default CarouselTeam
