const scrollIconOpacity = 1.0
const style = (theme) => ({
  component: {
    // bottom: `-${theme.header.spacing[0]}px`,
    top: 0,
    left: 0,
    padding: 0,
    position: 'relative',
    zIndex: '4',
    width: '100%',
    height: '100%',
    // mixBlendMode: 'difference',
  },
  line: {
    width: 1,
    height: '54px',
    backgroundColor: theme.colors[4],
    margin: '0 auto',
  },
  wrapperLine: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    height: '54px',
    overflow: 'hidden',
    display: 'block',
    textAlign: 'center',
    opacity: scrollIconOpacity,
  },
  text: {
    fontFamily: theme.fonts[1],
    transition: 'opacity .4s ease-in-out',
    fontSize: 10,
    fontWeight: 'bold',
    color: theme.colors[2],
    textTransform: 'uppercase',
    transformOrigin: 'left center',
    position: 'relative',
    width: 'auto',
    top: 0,
    left: 0,
    letterSpacing: 3,
    textAlign: 'left',
    opacity: scrollIconOpacity,
  },
  wrapScroll: {
    top: 0,
    position: 'relative',
  },
  [theme.mediaInverse.md]: {
    component: {
      // display: 'none',
    },
  },
})

export default style
