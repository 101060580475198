const style = (theme) => {
  const output = {
    root: {
      color: theme.colors[0],
      background: theme.colors[0],
      overflow: 'hidden',
    },
    page: {
      '& > *:not(:first-child)': {
        marginTop: 150,
        // paddingBottom: 100,
      },
    },
    wrapper: {
      extend: theme.smallWrapper,
      position: 'relative',
    },
    wrapperList: {
      extend: theme.smallWrapper,
      position: 'relative',
      zIndex: 1,
      '& h2': {
        fontFamily: theme.fonts[1],
        color: theme.colors[0],
        fontSize: 11,
        textTransform: 'uppercase',
        fontWeight: 'bold',
        letterSpacing: 1,
        paddingLeft: 10,
        marginBottom: 40,
      },
    },
    featuredProductsHeading: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 40,
    },
    pretitle: {
      extend: theme.pretitle,
      color: theme.colors[0],
    },
    bannerList: {
      '& > *': {
        marginBottom: 100,
      },
    },
  }

  /*------------------------------
  MD
  ------------------------------*/
  output[theme.mediaInverse.md] = {
  }

  /*------------------------------
  SM
  ------------------------------*/
  output[theme.mediaInverse.sm] = {
    page: {
      '& > *:not(:first-child)': {
        marginTop: 120,
      },
    },
    wrapperList: {
      '& h2': {
        paddingLeft: 3,
      },
    },
  }

  return output
}

export default style
