const style = (theme) => {
  const output = {
    root: {
      color: theme.colors[0],
      paddingTop: (props) => props.headerHeight,
      backgroundColor: theme.colors[0],
      overflow: 'hidden',
    },
    page: {
      height: '100vh',
      // minHeight: (props) => `calc(100vh - ${props.headerHeight}px)`,
      /* '& > *:not(:first-child)': {
        marginTop: 100,
        paddingBottom: 100,
      },
      '& > *:last-child': {
        paddingBottom: 200,
      }, */
    },
    wrapper: {
      extend: theme.wrapper,
      position: 'relative',
    },

    title: {
      extend: theme.smallWrapper,
      paddingTop: 50,
      color: theme.colors[4],
      fontSize: 80,
      lineHeight: 90,
    },
    slider: {
      marginTop: 20,
      extend: theme.smallWrapper,
      // height: '40vh',
      height: 'auto',
    },
  }

  /*------------------------------
  MD
  ------------------------------*/
  output[theme.mediaInverse.md] = {
  }

  /*------------------------------
  SM
  ------------------------------*/
  output[theme.mediaInverse.sm] = {
    title: {
      paddingTop: 40,
      fontSize: 38,
      lineHeight: 42,
    },
    slider: {
      marginTop: 0,
    },
  }

  return output
}

export default style
