import { useRef } from 'react'
import classNames from 'classnames'
import injectSheet from 'react-jss'
import DelayLink from '@/components/DelayLink'
import InViewTrigger from '@/components/InViewTrigger'
import RevealFadeIn from '@/components/RevealFadeIn'
import RevealImage from '@/components/RevealImage'
import style from './style'

const ListProjects = ({
  classes,
  items,
}) => {
  const $root = useRef()

  // const handleMouseOver = (e, id) => {
  //   const target = e.currentTarget
  //   const allItems = $root.current.querySelectorAll('.navItem')
  //   const allDividers = $root.current.querySelectorAll('.divider')
  //   const allImages = $follow.current.querySelectorAll('img')
  //   const img = $follow.current.querySelector(`[data-img="${id}"]`)

  //   gsap.to([allItems, allDividers], {
  //     duration: 0.3,
  //     opacity: defaultOpacity,
  //   })
  //   gsap.to(target, {
  //     duration: 0.3,
  //     opacity: 1,
  //   })

  //   gsap.to(allImages, {
  //     duration: 0.5,
  //     x: -10,
  //     y: -10,
  //     scale: 1.1,
  //     opacity: 0,
  //     ease: 'power3.out',
  //   })
  //   gsap.to(img, {
  //     duration: 0.5,
  //     x: 0,
  //     y: 0,
  //     scale: 1,
  //     opacity: 0.6,
  //     ease: 'power3.out',
  //   })

  //   gsap.to($follow.current, {
  //     duration: 0.3,
  //     opacity: 1,
  //   })
  // }

  // const handleMouseOut = () => {
  //   const allItems = $root.current.querySelectorAll('.navItem')
  //   const allDividers = $root.current.querySelectorAll('.divider')
  //   gsap.to([allItems, allDividers], {
  //     duration: 0.3,
  //     opacity: defaultOpacity,
  //   })
  //   gsap.to($follow.current, {
  //     duration: 0.3,
  //     opacity: 0,
  //   })
  // }

  // const handleMouseMove = (e) => {
  //   gsap.to($follow.current, {
  //     x: e.clientX - $root.current.getBoundingClientRect().left + ($follow.current.getBoundingClientRect().width / 2),
  //     y: e.clientY - $root.current.getBoundingClientRect().top - ($follow.current.getBoundingClientRect().height / 1.5),
  //     duration: 1.4,
  //     ease: 'power3.out',
  //   })
  // }

  return items.length > 0 && (
    <>
      <div
        className={classNames({
          [classes.menuWrapper]: true,
          productHover__menuWrapper: true,
        })}
        ref={$root}
      // onMouseMove={handleMouseMove}
      >
        <div className={classes.containerItems}>
          {items.map((item, index) => {
            return (
              <DelayLink
                to={item.link1}
                className={classes.item}
                key={index.toString()}
              >
                <InViewTrigger>
                  <RevealImage
                    image={item.image.url}
                    label={item.title}
                  />
                  <RevealFadeIn className={classes.containerDiscover}>
                    <p>{item.title}</p>
                    <svg><use xlinkHref="#arrow" /></svg>
                  </RevealFadeIn>
                </InViewTrigger>
              </DelayLink>
            )
          })}
        </div>
      </div>
    </>
  )
}

export default injectSheet(style)(ListProjects)
