import { useRef, useState, useEffect, useCallback, useContext } from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { createUseStyles } from 'react-jss'
import { useLocation } from 'react-router-dom'
import CreateJs from 'preload-js'
import { Context as GeneralContext } from '@/context'
import { Context as LoadingContext } from '@/context/loading'
import LocomotiveScroll from '@/components/LocomotiveScroll'
import Meta from '@/components/Meta'
import Hero from '@/components/Hero'
import Banner from '@/components/Banner'
import Footer from '@/components/Footer'
import PageElements from '@/components/PageElements'
import useHandleAllMediaWithCb from '@/hooks/useHandleAllMediaWithCb'
import * as contentActions from '@/actions/content'
import { getSlug } from '@/utils/path'
import style from './style'

const useStyles = createUseStyles(style)

const Editorial = () => {
  const { setPageAnimationReady, headerHeight } = useContext(GeneralContext)
  const { setReady, setSiteLoaded } = useContext(LoadingContext)
  const classes = useStyles({ headerHeight })
  const [load, setLoad] = useState(false)
  const preload = useRef(new CreateJs.LoadQueue())
  const location = useLocation()
  const stub = useRef(getSlug(location.pathname))
  const $root = useRef()

  /*------------------------------
  Executes a callback on loading each image
  ------------------------------*/
  useHandleAllMediaWithCb({
    init: load,
    ref: $root.current?.ref,
  })

  /*------------------------------
  Redux Connect
  ------------------------------*/
  const { page, strings } = useSelector((state) => ({
    page: state.content.pages[stub.current] || {},
    strings: state.options.strings,
  }), shallowEqual)

  /*------------------------------
  Redux Actions
  ------------------------------*/
  const dispatch = useDispatch()
  const fetchPage = useCallback((slug, child) => dispatch(contentActions.fetchPage(slug, child)), [dispatch])

  /*------------------------------
  Complete Preload
  ------------------------------*/
  const completePreload = () => {
    setReady(true)
    setSiteLoaded(true)
    setLoad(true)
  }

  /*------------------------------
  Preload Media
  ------------------------------*/
  const preloadMedia = () => {
    preload.current.on('complete', completePreload)
    if (page.featured_image) preload.current.loadFile({ src: page.featured_image })
  }

  /*------------------------------
  Preload Media when there are Data of Page in Redux
  ------------------------------*/
  useEffect(() => {
    if (Object.keys(page).length > 0) preloadMedia()
  }, [page])

  /*------------------------------
  Fetch all data
  ------------------------------*/
  const fetchData = (slugPage) => {
    if (Object.keys(page).length === 0) fetchPage(slugPage, true)
    return false
  }

  /*------------------------------
  Initialize
  ------------------------------*/
  const initialize = () => {
    fetchData(stub.current)
  }

  /*------------------------------
  Did Mount
  ------------------------------*/
  useEffect(() => {
    if (!load) initialize()
  }, [])

  /*------------------------------
  Activate Page Animation
  ------------------------------*/
  useEffect(() => {
    if (load) setPageAnimationReady(true)
  }, [load])

  /*------------------------------
  Render Helmet
  ------------------------------*/
  const renderHelmet = () => {
    return load && (
      <Meta
        title={page.title.rendered}
        meta={page.yoast_meta}
        schema={page.yoast_json_ld}
      />
    )
  }

  /*------------------------------
  Render Hero
  ------------------------------*/
  const renderHero = () => {
    return Object.keys(page).length > 0 && (
      <Hero
        image={page.featured_image}
        imageMobile={page.acf.featured_image_mobile.url}
        title={page.title.rendered}
        text={page.content.rendered}
        verticalCentered={false}
      />
    )
  }

  /*------------------------------
  Render Child
  ------------------------------*/
  const renderChild = () => {
    return page.child.length > 0 && (
      <div className={classes.bannerList}>
        {page.child.map((child, i) => (
          <Banner
            key={child.id}
            image={child.featured_image}
            title={child.title.rendered}
            text={child.content.rendered}
            label={strings.read_more_label}
            link={child.link}
            imageRight={i % 2 === 0}
          />
        ))}
      </div>
    )
  }

  /*------------------------------
  Render Elements
  ------------------------------*/
  const renderElements = () => {
    return page.acf.page_builder && (
      <PageElements
        className={classes.pageElements}
        elements={page.acf.page_builder}
      />
    )
  }

  /*------------------------------
  Render Footer
  ------------------------------*/
  const renderFooter = () => <Footer />

  /*------------------------------
  Render Content
  ------------------------------*/
  const renderContent = () => {
    return load && (
      <>
        <div className={classes.page}>
          {renderHero()}
          {/* renderChild() */}
          {renderElements()}
        </div>
        {renderFooter()}
      </>
    )
  }

  return (
    <>
      <LocomotiveScroll
        init={load}
        className={`page pageEditorial ${classes.root}`}
        ref={$root}
      >
        {renderHelmet()}
        {renderContent()}
      </LocomotiveScroll>
    </>
  )
}

export default Editorial
