const style = (theme) => {
  const output = {
    root: {
      // background: theme.colors[0],
      '& > *:not(:first-child)': {
        marginTop: 150,
      },
      '& > *:last-child': {
        marginBottom: 150,
      },
    },
    columnText: {
      extend: theme.smallWrapper,
    },
    columnTextTrigger: {
      display: 'flex',
    },
    titleContainer: {
      width: '30%',
      marginRight: '10%',
      '& h2': {
        fontFamily: theme.fonts[1],
        fontSize: 40,
        color: theme.colors[4],
        lineHeight: 60,
        '& div': {
          '& div': {
            '& div': {
              // textAlign: 'justify',
              textAlign: 'left',
              // textAlignLast: 'justify',
              textAlignLast: 'left',
            },
          },
          '& > *:last-child': {
            '& div': {
              textAlign: 'left',
              textAlignLast: 'left',
            },
          },
        },
      },
    },
    borderColumnText: {
      '& div': {
        borderTop: `1px solid ${theme.colors[0]}`,
      },
    },
    textContainer: {
      width: '60%',
      '& > p': {
        fontSize: 20,
        color: theme.colors[4],
        columns: 'auto 2',
        columnGap: 80,
        // textAlign: 'justify',
        textAlign: 'left',
        textAlignLast: 'left',
        '& span': {
          marginBottom: 10,
          display: 'inline-block',
          textAlign: 'left',
          textAlignLast: 'left',
        },
      },
    },
    textContainer1: {
      width: '60%',
      display: 'flex',
      '& > div': {
        fontSize: 20,
        color: theme.colors[4],
      },
      '& > *:first-child': {
        width: '50%',
        paddingRight: 40,
        // textAlign: 'justify',
        textAlign: 'left',
        textAlignLast: 'left',
      },
      '& > *:nth-child(2)': {
        width: '50%',
        paddingLeft: 40,
        textAlign: 'left',
      },
    },

    singleImage: {
      extend: theme.smallWrapper,
      '& img': {
        objectFit: 'cover',
        objectPosition: '50% 50%',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
      },
      '& > div': {
        '& > picture': {
          '& > span': {
            position: 'relative',
            overflow: 'hidden',
            '& > span': {
              paddingBottom: '55%',
            },
          },
        },
      },
    },

    simpleText: {
      extend: theme.smallWrapper,

      '& div': {
        '& div': {
          '& p': {
            // textAlign: 'justify',
            textAlign: 'left',
            textAlignLast: 'left',
          },
        },
      },

      '& h2': {
        fontSize: 40,
        color: theme.colors[4],
        '& div': {
          '& p': {
            '& div': {
              '& div': {
                // textAlign: 'justify',
                textAlign: 'left',
                // textAlignLast: 'justify',
                textAlignLast: 'left',
              },
            },
            '& > *:last-child': {
              '& div': {
                textAlign: 'left',
                textAlignLast: 'left',
              },
            },
          },
        },
      },
      '& p': {
        fontSize: 40,
        color: theme.colors[4],
        // textAlign: 'justify',
        textAlign: 'left',
        // textAlignLast: 'justify',
        textAlignLast: 'left',
        margin: 0,
      },
    },

    title: {},
    text: {},
    simpleTitleText: {
      extend: theme.smallWrapper,
      '& h2': {
        fontSize: 40,
        color: theme.colors[4],
        lineHeight: 40,
      },
      '& $text': {
        marginTop: 40,
        fontSize: 20,
        color: theme.colors[4],
      },
    },

    arrowList: {
      extend: theme.smallWrapper,
      '& ul': {
        columns: 'auto 2',
        columnGap: 80,
        '& li': {
          textTransform: 'uppercase',
          fontSize: 20,
          color: theme.colors[4],
          marginBottom: 10,
          position: 'relative',
          paddingLeft: 25,
          '&:before': {
            // bordercolor: theme.colors[4],
            content: '""',
            height: 8,
            width: 8,
            // transform: 'translateY(-4px)',
            transform: 'rotate(-45deg)',
            position: 'absolute',
            left: 0,
            bottom: 11,
            border: `1px solid${theme.colors[4]}`,
          },

        },
      },
    },

    doubleImage: {
      extend: theme.smallWrapper,
      display: 'flex',
      '& h3': {
        marginTop: 20,
        fontSize: 20,
        color: theme.colors[4],
        fontFamily: theme.fonts[1],
      },
      '& p': {
        marginTop: 20,
        fontSize: 20,
        color: theme.colors[4],
      },
    },

    triggerDoubleImageN1: {
      width: '45%',
      marginRight: '10%',
    },

    triggerDoubleImageN2: {
      width: '45%',
    },

    doubleImageN1: {
      '& img': {
        objectFit: 'cover',
        objectPosition: '50% 50%',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
      },
      '& > span': {
        position: 'relative',
        overflow: 'hidden',
        '& > span': {
          width: '100%',
          paddingBottom: '100%',
        },
      },
    },
    doubleImageN2: {
      '& img': {
        objectFit: 'cover',
        objectPosition: '50% 50%',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
      },
      '& > span': {
        position: 'relative',
        overflow: 'hidden',
        '& > span': {
          width: '100%',
          paddingBottom: '65%',
        },
      },
    },

    gallery: {
      extend: theme.smallWrapper,
      position: 'relative',
      /* '& .slick-dots li button::before': {
        border: 'none',
        // borderColor: `${theme.colors[2]} !important`,
        backgroundColor: theme.colors[6],
        borderRadius: 0,
      },
      '& .slick-dots li.slick-active button::before': {
        background: theme.colors[0],
      },
      '& .slick-dots li button': {
        overflow: 'hidden',
      }, */
    },
    videoWrapper: {
      extend: theme.smallWrapper,
      '& h2': {
        fontSize: 40,
        color: theme.colors[4],
        marginBottom: 30,
      },
    },
  }

  /*------------------------------
MD
------------------------------*/
  output[theme.mediaInverse.md] = {
    root: {
      '& > *:not(:first-child)': {
        marginTop: 120,
      },
      '& > *:last-child': {
        marginBottom: 120,
      },
    },
    columnTextTrigger: {
      display: 'block',
    },
    titleContainer: {
      width: '100%',
      marginRight: 0,
      '& h2': {
        fontSize: 25,
        lineHeight: 32,
      },
    },
    textContainer: {
      marginTop: 30,
      width: '100%',
      '& > p': {
        columns: 'auto 1',
        columnGap: 0,
      },
    },
    textContainer1: {
      marginTop: 30,
      width: '100%',
      display: 'block',
      '& > *:first-child': {
        width: '100%',
        paddingRight: '0',
      },
      '& > *:nth-child(2)': {
        width: '100%',
        paddingLeft: '0',
      },
    },
    simpleText: {
      '& h2': {
        fontSize: 25,
        lineHeight: 32,
      },
      '& p': {
        fontSize: 25,
        lineHeight: 32,
      },
    },
    arrowList: {
      '& ul': {
        columns: 'auto 1',
        columnGap: 0,
        '& li': {
          fontSize: 20,
        },
      },
    },

    doubleImage: {
      display: 'block',
      '& p': {
        marginTop: 20,
        fontSize: 20,
        color: theme.colors[4],
      },
    },

    triggerDoubleImageN1: {
      width: '100%',
      marginRight: 0,
    },

    triggerDoubleImageN2: {
      width: '100%',
      marginTop: 60,
    },
    slider: {
      height: '40vh',
    },
    gallery: {
      paddingBottom: 55,
    },
    videoWrapper: {
      '& h2': {
        fontSize: 25,
      },
    },
  }

  return output
}

export default style
