const style = (theme) => {
  const output = {
    root: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: theme.zindex.loader,
      background: theme.colors[0],
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    },
    hide: {
      pointerEvents: 'none',
    },
    logoContainer: {
      animation: '$fadeIn 0.4s ease-in-out forwards',
      '& svg': {
        fill: theme.colors[4],
        width: 180,
        height: 132,
      },
    },
    spinnerContainer: {
      animation: '$fadeIn 0.4s ease-in-out forwards',
    },
    spinner: {
      marginTop: 30,
      width: 220,
      height: 2,
      background: theme.colors[5],
      borderRadius: 20,
      position: 'relative',
      overflow: 'hidden',
      '&:after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        top: 0,
        left: 0,
        width: 0,
        height: '100%',
        background: theme.colors[4],
        animation: '$loading 1s ease-in-out infinite',
      },
    },
    '@keyframes fadeIn': {
      from: { opacity: 0 },
      to: { opacity: 1 },
    },
    '@keyframes loading': {
      '50%': { width: '100%' },
      '100%': { width: 0, right: 0, left: 'unset' },
    },
  }

  /*------------------------------
  SM
  ------------------------------*/
  output[theme.mediaInverse.sm] = {
    logoContainer: {
      '& svg': {
        width: 120,
        height: 88,
      },
    },
    spinner: {
      width: 180,
    },
  }

  return output
}

export default style
