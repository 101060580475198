const style = (theme) => {
  const burgerBorderHeight = 2
  const output = {
    root: {
      width: (props) => props.width,
      height: (props) => props.height,
      cursor: 'pointer',
      display: 'flex',
      backgroundColor: 'trasparent',
    },
    box: {
      position: 'relative',
      display: 'inline-block',
      width: (props) => props.width,
      height: (props) => props.height,
    },
    inner: {
      position: 'absolute',
      width: (props) => props.width,
      height: burgerBorderHeight,
      backgroundColor: 'trasparent',
      '&::before': {
        content: '""',
        display: 'inline-block',
        transition: '.3s',
        position: 'absolute',
        width: '100%',
        height: '100%',
        backgroundColor: theme.colors[4],
        right: 0,
        top: 0,
        bottom: 0,
        willChange: 'transform',
      },
      '&::after': {
        content: '""',
        display: 'inline-block',
        transition: '.3s',
        position: 'absolute',
        width: '100%',
        height: '100%',
        backgroundColor: theme.colors[4],
        right: 0,
        top: (props) => `calc(${props.height - burgerBorderHeight}px)`,
        transformOrigin: 'right top',
        willChange: 'transform',
        transform: 'scaleX(0.6)',
      },
    },
    isActive: {
      '& $inner': {
        top: '50%',
        transform: 'translateY(-50%)',
        '&::before': {
          backgroundColor: theme.colors[0],
          transformOrigin: '50% 50%',
          top: 0,
          transform: 'rotate(45deg)',
        },
        '&::after': {
          backgroundColor: theme.colors[0],
          transformOrigin: '50% 50%',
          top: 0,
          transform: 'rotate(-45deg) scaleX(1)',
        },
      },
    },
    isHover: {
      '@media (hover: hover)': {
        '&:not($isActive)': {
          '& $inner': {
            '&::after': {
              transformOrigin: 'right',
              transform: 'scaleX(1)',
            },
          },
        },
      },
    },
  }

  return output
}

export default style
