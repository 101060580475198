import { useRef, useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { useSelector, shallowEqual } from 'react-redux'
// import gsap from 'gsap'
import useOnClickOutside from '@/hooks/useOnClickOutside'
import { cleanOrigin } from '@/utils/path'
import style from './style'

const useStyles = createUseStyles(style)

const LangSwitcher = () => {
  const classes = useStyles()

  /*------------------------------
  Redux Connect
  ------------------------------*/
  const { currentLanguage, languages } = useSelector((state) => ({
    currentLanguage: state.locale.currentLanguage,
    languages: state.locale.languages,
  }), shallowEqual)

  const [open, setOpen] = useState(false)
  const $root = useRef()
  const $select = useRef()
  const $options = useRef()

  /*------------------------------
  Click Outside
  ------------------------------*/
  useOnClickOutside($root, () => {
    setOpen(false)
  })

  /*------------------------------
  Open/Close
  ------------------------------*/
  useEffect(() => {
    const toggle = open ? 'add' : 'remove'
    $root.current.classList[toggle]('open')
  }, [open])

  /*------------------------------
  Render
  ------------------------------*/
  return (
    <div
      ref={$root}
      className={classes.root}
    >
      <button
        className={classes.select}
        ref={$select}
        onClick={() => { setOpen(!open) }}
      >
        {currentLanguage}
        <span className={classes.arrow} />
      </button>
      <div
        className={classes.options}
        ref={$options}
      >
        {Object.values(languages)
          .filter((l) => l.language_code !== currentLanguage)
          .map((lang) => (
            <a
              className={classes.option}
              key={lang.id}
              href={cleanOrigin(lang.url)}
            >
              {lang.language_code}
            </a>
          ))}
      </div>
    </div>
  )
}

export default LangSwitcher
