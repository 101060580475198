const style = (theme) => {
  const output = {
    root: {
      backgroundColor: theme.colors[0],
    },
    page: {
      '& > *:not(:first-child)': {
        marginTop: 100,
        paddingBottom: 100,
      },
      '& > *:last-child': {
        paddingBottom: 0,
      },
    },
    wrapper: {
      extend: theme.smallWrapper,
      position: 'relative',
    },
    wrapperList: {
      extend: theme.smallWrapper,
      position: 'relative',
      zIndex: 1,
      '& h2': {
        fontFamily: theme.fonts[0],
        color: theme.colors[4],
        fontSize: 11,
        textTransform: 'uppercase',
        fontWeight: 'bold',
        letterSpacing: 1,
        marginBottom: 70,
      },
    },
    featuredProductsHeading: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: 40,
    },
    pretitle: {
      extend: theme.pretitle,
      color: theme.colors[0],
    },
  }

  /*------------------------------
  MD
  ------------------------------*/
  output[theme.mediaInverse.md] = {
  }

  /*------------------------------
  SM
  ------------------------------*/
  output[theme.mediaInverse.sm] = {
    page: {
      '& > *:not(:first-child)': {
        marginTop: 120,
        paddingBottom: 0,
      },
    },
    wrapperList: {
      '& h2': {
      },
    },
  }

  return output
}

export default style
