import { useRef, useState, useEffect, useCallback, useContext } from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { createUseStyles } from 'react-jss'
import CreateJs from 'preload-js'
import { Context as GeneralContext } from '@/context'
import { Context as LoadingContext } from '@/context/loading'
import LocomotiveScroll from '@/components/LocomotiveScroll'
import InViewTrigger from '@/components/InViewTrigger'
import RevealFadeIn from '@/components/RevealFadeIn'
import CarouselSimple from '@/components/CarouselSimple'
import Banner from '@/components/Banner'
import Meta from '@/components/Meta'
import Hero from '@/components/Hero'
import BtnLink from '@/components/BtnLink'
import Footer from '@/components/Footer'
import useHandleAllMediaWithCb from '@/hooks/useHandleAllMediaWithCb'
import * as contentActions from '@/actions/content'
import { getSlug } from '@/utils/path'
import style from './style'

const useStyles = createUseStyles(style)

const Showroom = () => {
  const { setPageAnimationReady, headerHeight, setIsHome } = useContext(GeneralContext)
  const { firstLoad, setReady, setSiteLoaded } = useContext(LoadingContext)
  const classes = useStyles({ headerHeight })
  const [load, setLoad] = useState(false)
  const preload = useRef(new CreateJs.LoadQueue())
  const location = useLocation()
  const stub = useRef(getSlug(location.pathname))
  const $root = useRef()

  /*------------------------------
  Executes a callback on loading each image
  ------------------------------*/
  useHandleAllMediaWithCb({
    init: load,
    ref: $root.current?.ref,
  })

  /*------------------------------
  Redux Connect
  ------------------------------*/
  const { page } = useSelector((state) => ({
    page: state.content.pages[stub.current] || {},
  }), shallowEqual)

  /*------------------------------
  Redux Actions
  ------------------------------*/
  const dispatch = useDispatch()
  const fetchPage = useCallback((slug) => dispatch(contentActions.fetchPage(slug)), [dispatch])

  /*------------------------------
  Complete Preload
  ------------------------------*/
  const completePreload = () => {
    setReady(true)
    setSiteLoaded(true)
    setLoad(true)
  }

  /*------------------------------
  Preload Media
  ------------------------------*/
  const preloadMedia = () => {
    preload.current.on('complete', completePreload)
    if (page.featured_image) preload.current.loadFile({ src: page.featured_image })
  }

  /*------------------------------
  Preload Media when there are Data of Page in Redux
  ------------------------------*/
  useEffect(() => {
    if (Object.keys(page).length > 0) preloadMedia()
  }, [page])

  /*------------------------------
  Set Featured Products
  ------------------------------*/
  /* useEffect(() => {
    if (featuredProducts.length > 0) {
      const mappedItems = featuredProducts
        ?.filter((item) => item.enabled)
        .slice(0, 3)
        ?.map((item) => ({
          id: item.id,
          title: item.name,
          description: item.description,
          link: `${SITE}${PATH}shop/${item.parentId ? 'c' : 'p'}/${slugify(item.name)}-${item.id}`,
          image: item?.thumbnailUrl,
          price: item?.defaultDisplayedPriceFormatted,
        }))
      setProducts(mappedItems)
    }
  }, [featuredProducts]) */

  /*------------------------------
  Fetch all data
  ------------------------------*/
  const fetchData = (slugPage) => {
    if (Object.keys(page).length === 0) fetchPage(slugPage)
    return false
  }

  /*------------------------------
  Initialize
  ------------------------------*/
  const initialize = () => {
    fetchData(stub.current)
  }

  /*------------------------------
  Did Mount
  ------------------------------*/
  useEffect(() => {
    if (!load) initialize()
    setIsHome(true)
    return () => {
      setIsHome(false)
    }
  }, [])

  /*------------------------------
  Activate Page Animation
  ------------------------------*/
  useEffect(() => {
    if (load && !firstLoad) setPageAnimationReady(true)
  }, [load, firstLoad])

  /*------------------------------
  Render Helmet
  ------------------------------*/
  const renderHelmet = () => {
    return load && (
      <Meta
        title={page.title.rendered}
        meta={page.yoast_meta}
        schema={page.yoast_json_ld}
      />
    )
  }

  /*------------------------------
  Render Hero
  ------------------------------*/
  const renderHero = () => {
    return Object.keys(page).length > 0 && (
      <Hero
        image={page.featured_image}
        title={page.title.rendered}
        text={page.content.rendered}
        aboveTheFold={true}
        verticalCentered={false}
      />
    )
  }

  /*------------------------------
  Render Gallery
  ------------------------------*/
  const renderGallery = () => {
    return Object.keys(page).length > 0 && (
      <div
        className={classes.gallery}
      >
        <InViewTrigger>
          <RevealFadeIn delay={0.5}>
            <CarouselSimple
              imagesList={page.acf.gallery.gallery}
            />
          </RevealFadeIn>
        </InViewTrigger>
      </div>
    )
  }

  /*------------------------------
  Render Maps
  ------------------------------*/
  const renderMaps = () => {
    return Object.keys(page).length > 0 && (
      <Banner
        image={page.acf.maps.image.url}
        title={page.acf.maps.title}
        text={page.acf.maps.text}
        label={page.acf.maps.label}
        link={page.acf.maps.link}
        verticalCentered={true}
        excerptChar={700}
      />
    )
  }

  /*------------------------------
  Render Custom Link
  ------------------------------*/
  const renderCustomLink = () => {
    return page.acf.custom_link.link && (
      <InViewTrigger className={classes.wrapper}>
        <RevealFadeIn delay={0.5}>
          <BtnLink
            link={page.acf.custom_link.link}
            label={page.acf.custom_link.label}
            className={classes.btn}
          />
        </RevealFadeIn>
      </InViewTrigger>
    )
  }

  /*------------------------------
Render Footer
------------------------------*/
  const renderFooter = () => <Footer />

  /*------------------------------
Render Content
------------------------------*/
  const renderContent = () => {
    return load && (
      <>
        <div className={classes.page}>
          {renderHero()}
          {renderGallery()}
          {renderMaps()}
          {renderCustomLink()}
        </div>
        {renderFooter()}
      </>
    )
  }

  return (
    <LocomotiveScroll
      init={load}
      className={`page pageShowroom ${classes.root}`}
      ref={$root}
    >
      {renderHelmet()}
      {renderContent()}
    </LocomotiveScroll>
  )
}

export default Showroom
