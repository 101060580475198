const style = (theme) => {
  const output = {
    root: {
      width: 'fit-content',
      position: 'relative',
      zIndex: 1,
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
    },
    label: {
      textTransform: 'uppercase',
      fontFamily: theme.fonts[0],
      fontSize: 11,
      letterSpacing: '1px',
      marginRight: 8,
    },
    rootWhite: {
      color: theme.colors[4],
      '& svg': {
        fill: theme.colors[4],
        position: 'relative',
        bottom: 2,
        transform: 'rotate(-45deg)',
        transformOrigin: 'center',
        transition: `all .3s ${theme.easings['power3.inOut']}`,
        width: 12,
        height: 12,
      },
      '&:hover': {
        '& svg': {
          transform: 'rotate(0deg)',
          // transformOrigin: 'center',
        },
      },
    },
    rootGrey: {
      color: theme.colors[0],
      '& svg': {
        fill: theme.colors[0],
        position: 'relative',
        bottom: 2,
        transform: 'rotate(-45deg)',
        transformOrigin: 'center',
        transition: `all .3s ${theme.easings['power3.inOut']}`,
      },
      '&:hover': {
        '& svg': {
          transform: 'rotate(0deg)',
          // transformOrigin: 'center',
        },
      },
    },
  }

  output[theme.mediaInverse.sm] = {
    rootWhite: {
      '& svg': {
        bottom: 1,
      },
    },
    rootGrey: {
      '& svg': {
        bottom: 1,
      },
    },
  }

  return output
}

export default style
