import injectSheet from 'react-jss'
import RevealGrid from '@/components/RevealGrid'
import NewsItem from '@/components/NewsItem'
import style from './style'

const FeaturedNews = ({
  classes,
  items,
}) => {
  return (
    <div className={classes.root}>
      <RevealGrid
        cols={3}
        spaceBetween={120}
        singleRow
        className={classes.grid}
      >
        {items.map((item) => (
          <NewsItem
            key={item.id}
            item={item}
          />
        ))}
      </RevealGrid>
    </div>
  )
}

export default injectSheet(style)(FeaturedNews)
