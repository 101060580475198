const style = (theme) => {
  const output = {
    root: {
      color: theme.colors[0],
      paddingTop: (props) => props.headerHeight,
      backgroundColor: theme.colors[0],
    },
    page: {
      // minHeight: (props) => `calc(100vh - ${props.headerHeight}px)`,
      '& > *:not(:first-child)': {
        marginTop: 100,
        paddingBottom: 100,
      },
      '& > *:last-child': {
        paddingBottom: 200,
      },
    },
    wrapper: {
      extend: theme.wrapper,
      position: 'relative',
    },

    title: {
      extend: theme.smallWrapper,
      paddingTop: 200,
      color: theme.colors[4],
      fontSize: 80,
      lineHeight: 90,
    },
  }

  /*------------------------------
  MD
  ------------------------------*/
  output[theme.mediaInverse.md] = {
  }

  /*------------------------------
  SM
  ------------------------------*/
  output[theme.mediaInverse.sm] = {
    page: {
      // minHeight: (props) => `calc(100vh - ${props.headerHeight}px)`,
      '& > *:not(:first-child)': {
        marginTop: 100,
        paddingBottom: 0,
      },
      '& > *:nth-child(2)': {
        marginTop: 60,
      },
      '& > *:last-child': {
        paddingBottom: 100,
      },
    },
    title: {
      paddingTop: 100,
      fontSize: 38,
      lineHeight: 42,
    },
  }

  return output
}

export default style
