/* eslint-disable no-underscore-dangle */
import { useRef, useState, useEffect, useCallback, useContext } from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { useParams } from 'react-router-dom'
import { createUseStyles } from 'react-jss'
import CreateJs from 'preload-js'
import { Context as GeneralContext } from '@/context'
import { Context as LoadingContext } from '@/context/loading'
import LocomotiveScroll from '@/components/LocomotiveScroll'
// import Meta from '@/components/Meta'
import Footer from '@/components/Footer'
import Banner from '@/components/Banner'
import RevealText from '@/components/RevealText'
import useHandleAllMediaWithCb from '@/hooks/useHandleAllMediaWithCb'
import * as contentActions from '@/actions/content'
import style from './style'

const useStyles = createUseStyles(style)

const ArchiveProjects = () => {
  const { setPageAnimationReady, pageAnimationReady, headerHeight } = useContext(GeneralContext)
  const { setReady, setSiteLoaded } = useContext(LoadingContext)
  const classes = useStyles({ headerHeight })
  const [load, setLoad] = useState(false)
  const preload = useRef(new CreateJs.LoadQueue())
  const params = useParams()
  const stub = useRef(params._cta_category)
  const $root = useRef()

  /*------------------------------
  Executes a callback on loading each image
  ------------------------------*/
  useHandleAllMediaWithCb({
    init: load,
    ref: $root.current?.ref,
  })

  /*------------------------------
  Redux Connect
  ------------------------------*/
  const { projectsList, strings, currentCategory } = useSelector((state) => ({
    // page: state.content.pages[stub.current] || {},
    projectsList: state.content.cpt._cty_project || [], // eslint-disable-line
    strings: state.options.strings,
    currentCategory: state.content.taxonomies._cta_category?.find((p) => p.slug === stub.current),
  }), shallowEqual)

  /*------------------------------
  Redux Actions
  ------------------------------*/
  const dispatch = useDispatch()
  // const fetchPage = useCallback((slug) => dispatch(contentActions.fetchPage(slug)), [dispatch])
  const fetchCpts = useCallback((cpt, tax, term) => dispatch(contentActions.fetchCpts(cpt, tax, term)), [dispatch])
  const fetchTaxonomy = useCallback((tax, save) => dispatch(contentActions.fetchTaxonomy(tax, save)), [dispatch])
  /*------------------------------
  Complete Preload
  ------------------------------*/
  const completePreload = () => {
    setReady(true)
    setSiteLoaded(true)
    setLoad(true)
  }

  /*------------------------------
  Activate Page Animation
  ------------------------------*/
  useEffect(() => {
    if (load) setPageAnimationReady(true)
  }, [load])

  /*------------------------------
  Preload Media
  ------------------------------*/
  const preloadMedia = () => {
    preload.current.on('complete', completePreload)
    projectsList.filter((item) => item._cta_category[0] === currentCategory.id).map((item) => {
      preload.current.loadFile({ id: `img-${item.id}`, src: item.featured_image })
      return false
    })
  }

  /*------------------------------
  Preload Media when there are Data of Page in Redux
  ------------------------------*/
  useEffect(() => {
    window.console.log('currentCategory ---->', currentCategory)
    if (projectsList.length > 1 && currentCategory) preloadMedia()
  }, [projectsList, currentCategory])

  /*------------------------------
  Fetch all data
  ------------------------------*/
  // const fetchData = (slugPage) => {
  //   if (Object.keys(page).length === 0) fetchPage(slugPage)
  //   return false
  // }

  /*------------------------------
  Initialize
  ------------------------------*/
  const initialize = () => {
    // fetchData(stub.current)
    if (projectsList.length === 0 || projectsList.length === 1) {
      fetchCpts('_cty_project')
    }
    fetchTaxonomy('_cta_category', true)
  }

  /*------------------------------
  Did Mount
  ------------------------------*/
  useEffect(() => {
    if (!load) initialize()
  }, [])

  /*------------------------------
  Render Helmet
  ------------------------------*/
  // const renderHelmet = () => {
  //   return load && (
  //     <Meta
  //       title={page.title.rendered}
  //       meta={page.yoast_meta}
  //       schema={page.yoast_json_ld}
  //     />
  //   )
  // }

  /*------------------------------
  Render Heading
  ------------------------------*/
  const renderHeading = () => {
    return projectsList.length > 0 && (
      <RevealText
        tag="h1"
        className={classes.title}
        type="lines"
        value={currentCategory.name}
        visible={pageAnimationReady}
      />
    )
  }

  /*------------------------------
  Render Heading
  ------------------------------*/
  const renderProducts = () => {
    window.console.log('stab ---->', stub)
    return projectsList.length > 0 && (
      projectsList
        .filter((item) => item._cta_category[0] === currentCategory.id)
        .map((item, index) => (
          <Banner
            image={item.featured_image}
            title={item.title.rendered}
            text={item.content.rendered}
            label={strings.read_more_label}
            link={item.link}
            key={index.toString()}
            imageRight={!(index % 2 === 0)}
            verticalCentered={true}
          />
        ))
    )
  }

  /*------------------------------
  Render Grid
  ------------------------------*/
  // const renderGrid = () => {
  //   return products.length > 0 && (
  //     <div className={`${classes.wrapper} ${classes.products}`}>
  //       <RevealGrid cols={3}>
  //         {products.map((product) => (
  //           <ProductItem
  //             key={product.id}
  //             item={product}
  //             shoppable
  //             onClick={() => handleAddToCart(product.id, 1)}
  //             onClickWishlist={() => addOrRemoveToLiked(product)}
  //             isFavourite={containsObject(product, wishlist, 'id')}
  //           />
  //         ))}
  //       </RevealGrid>
  //     </div>
  //   )
  // }

  /*------------------------------
  Render Footer
  ------------------------------*/
  const renderFooter = () => <Footer />

  /*------------------------------
  Render Content
  ------------------------------*/
  const renderContent = () => {
    return load && (
      <>
        <div className={classes.page}>
          {renderHeading()}
          {renderProducts()}
        </div>
        {renderFooter()}
      </>
    )
  }

  return (
    <LocomotiveScroll
      init={load}
      className={`page pageArchiveProjects ${classes.root}`}
      ref={$root}
    >
      {/* {renderHelmet()} */}
      {renderContent()}
    </LocomotiveScroll>
  )
}

export default ArchiveProjects
