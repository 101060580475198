import { forwardRef, useEffect, useRef } from 'react'
import classNames from 'classnames'
import gsap from 'gsap'
import { useSelector, shallowEqual } from 'react-redux'
import { createUseStyles, useTheme } from 'react-jss'
import DelayLink from '@/components/DelayLink'
import style from './style'

const useStyles = createUseStyles(style)

const Logo = forwardRef(({
  tabIndex,
  className,
  isTop,
  inHeader,
}, ref) => {
  const classes = useStyles()
  const theme = useTheme()
  const $text = useRef()
  const $logo = useRef()

  /*------------------------------
  Redux Connect
  ------------------------------*/
  const { currentLanguage, strings } = useSelector((state) => ({
    currentLanguage: state.locale.currentLanguage,
    strings: state.options.strings,
  }), shallowEqual)

  useEffect(() => {
    // window.console.log('isTop ---->', isTop)
    if (inHeader && !isTop) {
      gsap.to($text.current, {
        opacity: 0,
        duration: 0.4,
        ease: 'power3.out',
      })
      gsap.to($logo.current, {
        x: -20,
        y: 4,
        duration: 0.8,
        ease: 'power1.inOut',
      })
    } else {
      gsap.to($text.current, {
        opacity: inHeader && !isTop ? 0 : 1,
        duration: 0.4,
        ease: 'power3.out',
        delay: 0.6,
      })
      gsap.to($logo.current, {
        x: 0,
        y: 0,
        duration: 0.8,
        ease: 'power1.inOut',
      })
    }
  }, [isTop])

  return (
    <div
      className={classNames({
        [classes.root]: true,
        [className]: className,
      })}
      ref={ref}
    >
      <DelayLink
        to={`/${currentLanguage}`}
        className={classes.logo}
        tabIndex={tabIndex}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 90 65"
          width={theme.header.logo.width}
          height={theme.header.logo.height}
          fill={theme.header.logo.fill}
        >
          <title>{`${strings.site_title} Logo`}</title>
          <g ref={$logo}><path d="M36.5 17.9v17.6L19 17.9zM71.5.4v17.5H54v17.6H36.5L54 53h17.5V35.5h17.6V17.9z" /></g>
          <g ref={$text}>
            <path d="M19.3 27.1l-5.8 5.8-2.1-2.2 5.7-5.7-2.6-2.6 2.2-2.2 7.4 7.4-2.2 2.1zM19.7 33.4l5.1-5.1 2.2 2.2-4 3.9c-1.4 1.4-2.2 2.4-.7 3.9 1.5 1.5 2.5.7 3.9-.7l3.9-3.9 2.2 2.2-5.1 5.1c-2.2 2.2-4.2 1.8-6.8-.8-2.6-2.7-2.8-4.8-.7-6.8M33.2 43.6c.6.6 1.4 1.1 2.1.5.7-.7.2-1.5-.4-2.1l-.8-.8-1.6 1.6.7.8zm.4-6.4l3.4 3.4c1.9 1.9 2.2 4.1.6 5.6a3.5 3.5 0 01-4.7.4l-.1 5.6-2.6-2.6.2-5-2.6 2.6-2.2-2.2 8-7.8zM41.1 51.6c.6.6 1.4 1.1 2.1.5.7-.7.2-1.5-.4-2.1l-.8-.8-1.6 1.6.7.8zm.5-6.5l3.4 3.4c1.9 1.9 2.2 4.1.6 5.6a3.5 3.5 0 01-4.7.4l-.1 5.6-2.6-2.6.2-5-2.6 2.6-2.2-2.2 8-7.8zM41.6 61l8-7.9 2.2 2.2-8 8z" />
            <path d="M6.4 34.6l-1.7-1.7 2.1-2.2 3.9 3.9-7.9 7.9-2.2-2.1zM14.8 42.9c.4-.4.2-1.2-.5-1.8-.7-.7-1.4-.9-1.9-.5-.4.4-.2 1.2.5 1.9s1.5.8 1.9.4m-3.1 3.7c.4-.4.4-1.2-.6-2.2-1-1-1.8-1-2.2-.6-.5.5-.3 1.3.7 2.2.8.8 1.6 1.1 2.1.6m-5.4-5.1c1.2-1.2 2.9-1 3.7-.3-.5-.7-.6-2 .4-3 1.1-1.1 3-1.3 5.6 1.3 2.5 2.5 2.4 4.5 1.3 5.6-1 1-2.3.9-3 .4.7.8.9 2.4-.3 3.7-1.1 1.1-3.3 1.5-6.2-1.4-3.1-3-2.7-5.2-1.5-6.3M22.1 50.3l-4-3.9 2.2-2.2 6.4 6.4-2.1 2.2-9.2 2.4-2.5-2.5zM28.9 60.7c1.4-1.4 1.6-2.9.6-3.9s-2.5-.7-3.9.6c-1.4 1.4-1.6 2.9-.6 3.9.9 1 2.5.8 3.9-.6m-5.6-5.6c2.5-2.5 5.7-2.6 8.1-.2 2.4 2.4 2.3 5.6-.2 8.1s-5.7 2.6-8.1.2c-2.4-2.4-2.4-5.6.2-8.1" />
          </g>
        </svg>
      </DelayLink>
    </div>
  )
})

Logo.defaultProps = {
  inHeader: false,
}

export default Logo
