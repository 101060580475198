const style = (theme) => {
  const output = {
    root: {
      position: 'relative',
      top: 2,
      zIndex: 1,
      color: theme.colors[4],
      fontSize: 13,
      fontFamily: theme.fonts[0],
      letterSpacing: 1,
      '&.open': {
        '& $select': {
          borderRadius: [15, 15, 0, 0],
          '& $arrow': {
            transform: 'rotate(180deg)',
          },
        },
        '& $options': {
          opacity: 1,
          pointerEvents: 'all',
          transform: 'translateY(0%)',
        },
      },
    },
    select: {
      fontSize: 13,
      borderRadius: 20,
      padding: '6px 14px',
      color: 'currentColor',
      cursor: 'pointer',
      margin: 0,
      textTransform: 'uppercase',
      display: 'flex',
      alignItems: 'center',
      fontFamily: theme.fonts[0],
      letterSpacing: 1,
    },
    arrow: {
      marginLeft: 8,
      width: 0,
      height: 0,
      borderStyle: 'solid',
      borderWidth: '4px 3.5px 0 3.5px',
      borderColor: `${theme.colors[4]} transparent transparent transparent`,
      backfaceVisibility: 'hidden',
      willChange: 'transform',
      transition: `all 0.2s ${theme.easings.garret}`,
      position: 'relative',
      bottom: 2,
    },
    options: {
      position: 'absolute',
      zIndex: 1,
      top: '100%',
      left: 0,
      width: '100%',
      borderRadius: [0, 0, 10, 10],
      background: theme.colors[4],
      overflow: 'hidden',
      opacity: 0,
      transform: 'translateY(-2%)',
      transition: 'opacity .2s ease-out, transform .2s ease-out',
      pointerEvents: 'none',
      boxShadow: `0 10px 11px -8px ${theme.getRgba(theme.colors[0], 0.4)}`,
    },
    option: {
      display: 'block',
      width: '100%',
      padding: '8px 14px',
      color: theme.colors[0],
      textTransform: 'uppercase',
      textDecoration: 'none',
      lineHeight: 1,
      background: theme.colors[4],
      transition: 'background-color .2s ease-out',
      // borderTop: `1px solid ${theme.getRgba(theme.colors[0], 0.4)}`,
      '&:hover': {
        background: theme.getRgba(theme.colors[0], 0.1),
      },
      '&:before': {
        display: 'none',
      },
    },
  }

  /*------------------------------
  MD
  ------------------------------*/
  output[theme.mediaInverse.md] = {
    root: {
      color: theme.colors[0],
    },
    arrow: {
      borderColor: `${theme.colors[0]} transparent transparent transparent`,
    },
    options: {
      background: theme.colors[0],
    },
    option: {
      color: theme.colors[4],
      background: theme.colors[0],

    },
  }

  /*------------------------------
  SM
  ------------------------------*/
  output[theme.mediaInverse.sm] = {}

  return output
}

export default style
