const style = (theme) => {
  const output = {
    root: {
    },
    grid: {
      // '& > *:nth-child(1)': {
      //   paddingRight: '5%',
      //   borderRight: `1px solid ${theme.colors[4]}`,
      //   boxSizing: 'content-box',
      // },
      // '& > *:nth-child(2)': {
      //   paddingRight: '5%',
      //   paddingLeft: '5%',
      //   borderRight: `1px solid ${theme.colors[4]}`,
      //   boxSizing: 'content-box',
      // },
      // '& > *:nth-child(3)': {
      //   paddingLeft: '5%',
      //   boxSizing: 'content-box',
      // },
    },
  }

  /*------------------------------
  MD
  ------------------------------*/
  output[theme.mediaInverse.md] = {
  }

  /*------------------------------
  SM
  ------------------------------*/
  output[theme.mediaInverse.sm] = {
    grid: {
      // '& > *:nth-child(1)': {
      //   paddingRight: 0,
      //   borderRight: 'none',
      //   boxSizing: 'border-box',
      //   marginBottom: 60,
      // },
      // '& > *:nth-child(2)': {
      //   paddingRight: 0,
      //   paddingLeft: 0,
      //   borderRight: 'none',
      //   boxSizing: 'border-box',
      //   marginBottom: 60,
      // },
      // '& > *:nth-child(3)': {
      //   paddingLeft: 0,
      //   boxSizing: 'border-box',
      // },
    },
  }

  return output
}
export default style
