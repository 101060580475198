const style = (theme) => {
  const output = {
    root: {
      extend: theme.smallWrapper,
      position: 'relative',
      zIndex: 1,
    },
    wrapperBanner: {
      display: 'flex',
    },
    wrapperBannerLeft: {
      width: '52.5%',
      '& img': {
        objectFit: 'cover',
        objectPosition: '50% 50%',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
      },
      '& span': {
        '& span': {
          position: 'relative',
          paddingBottom: '65%',
          overflow: 'hidden',
        },
      },
    },
    wrapperBannerRight: {
      width: '47.5%',
      paddingLeft: '10%',
      '& h2': {
        fontFamily: theme.fonts[1],
        fontSize: 40,
        color: theme.colors[4],
        lineHeight: 60,
        marginBottom: 15,
      },
      '& h3': {
        fontFamily: theme.fonts[1],
        fontSize: 20,
        color: theme.colors[4],
        marginBottom: 60,
      },
      '& h4': {
        fontFamily: theme.fonts[0],
        color: theme.colors[4],
        fontSize: 11,
        textTransform: 'uppercase',
        fontWeight: 'bold',
        letterSpacing: 1,
        marginBottom: 20,
      },
    },
    verticalCentered: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
    },
    verticalCenteredInverse: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-end',
    },
    triggerImage: {
      height: '100%',
    },
    linkImage: {
      display: 'block',
    },
    wrapperBannerRightInverse: {
      paddingLeft: 0,
      paddingRight: '10%',
      '& h2': {
        textAlign: 'right',
      },
      '& h3': {
        textAlign: 'right',
      },
      '& h4': {
        textAlign: 'right',
      },
      '& > a': {
        '& > div': {
          '& > div': {
            display: 'flex',
            justifyContent: 'flex-end',
          },
        },
      },
      '& *': {
        textAlign: 'right',
        textAlignLast: 'right',
        '& p': {
          '& div': {
            textAlign: 'right',
            textAlignLast: 'right',
          },
        },
      },
    },
    wrapperBannerLeftInverse: {
      order: 2,
    },
  }

  output[theme.mediaInverse.sm] = {
    wrapperBanner: {
      display: 'block',
    },
    wrapperBannerLeft: {
      width: '100%',
    },
    wrapperBannerRight: {
      width: '100%',
      paddingLeft: 0,
      marginTop: 40,
      '& h2': {
        fontSize: 25,
        lineHeight: 30,
        marginBottom: 20,
      },
      '& h3': {
        fontFamily: theme.fonts[1],
        fontSize: 18,
        marginBottom: 20,
      },
    },
    wrapperBannerRightInverse: {
      paddingRight: 0,
      '& h2': {
        textAlign: 'left',
      },
      '& h3': {
        textAlign: 'left',
      },
      '& h4': {
        textAlign: 'left',
      },
      '& *': {
        textAlign: 'left',
        textAlignLast: 'left',
        '& p': {
          '& div': {
            textAlign: 'left',
            textAlignLast: 'left',
          },
        },
      },
      '& > a': {
        '& > div': {
          '& > div': {
            display: 'flex',
            justifyContent: 'flex-start',
          },
        },
      },
    },
    verticalCenteredInverse: {
      alignItems: 'flex-start',
    },

  }

  return output
}

export default style
