export const REQUEST_ROUTING = 'REQUEST_ROUTING'
export const RECEIVE_ROUTING = 'RECEIVE_ROUTING'

export const REQUEST_OPTIONS = 'REQUEST_OPTIONS'
export const RECEIVE_OPTIONS = 'RECEIVE_OPTIONS'

export const SEND_CONTACT_FORM = 'SEND_CONTACT_FORM'
export const SENDED_CONTACT_FORM = 'SENDED_CONTACT_FORM'

export const REQUEST_NAV = 'REQUEST_NAV'
export const RECEIVE_NAV = 'RECEIVE_NAV'

export const OPEN_LAYER = 'OPEN_LAYER'
export const CLOSE_LAYER = 'CLOSE_LAYER'
export const CLOSE_ALL_LAYER = 'CLOSE_ALL_LAYER'

export const REQUEST_PAGE = 'REQUEST_PAGE'
export const RECEIVE_PAGE = 'RECEIVE_PAGE'

export const REQUEST_POSTS = 'REQUEST_POSTS'
export const RECEIVE_POSTS = 'RECEIVE_POSTS'
export const REQUEST_POST = 'REQUEST_POST'
export const RECEIVE_POST = 'RECEIVE_POST'
export const RESET_CURRENT_POST = 'RESET_CURRENT_POST'
export const REQUEST_TAXONOMY = 'REQUEST_TAXONOMY'
export const RECEIVE_TAXONOMY = 'RECEIVE_TAXONOMY'
export const REQUEST_CATEGORY = 'REQUEST_CATEGORY'
export const RECEIVE_CATEGORY = 'RECEIVE_CATEGORY'

export const REQUEST_CPTS = 'REQUEST_CPTS'
export const RECEIVE_CPTS = 'RECEIVE_CPTS'
export const REQUEST_CPT = 'REQUEST_CPT'
export const RECEIVE_CPT = 'RECEIVE_CPT'
export const RESET_CURRENT_CPT = 'RESET_CURRENT_CPT'

export const REQUEST_FORM = 'REQUEST_FORM'
export const RECEIVE_FORM = 'RECEIVE_FORM'

export const SET_LANGUAGE = 'SET_LANGUAGE'
export const REQUEST_LOCALES = 'REQUEST_LOCALES'
export const RECEIVE_LOCALES = 'RECEIVE_LOCALES'
