import { useRef, useState, useEffect, useCallback, useContext } from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { createUseStyles } from 'react-jss'
import CreateJs from 'preload-js'
import { Context as GeneralContext } from '@/context'
import { Context as LoadingContext } from '@/context/loading'
import LocomotiveScroll from '@/components/LocomotiveScroll'
import Meta from '@/components/Meta'
import Footer from '@/components/Footer'
import Banner from '@/components/Banner'
import RevealText from '@/components/RevealText'
import useHandleAllMediaWithCb from '@/hooks/useHandleAllMediaWithCb'
import * as contentActions from '@/actions/content'
import { getSlug } from '@/utils/path'
import style from './style'

const useStyles = createUseStyles(style)

const NUM_ITEM = 10

const ArchiveProjects = () => {
  const { setPageAnimationReady, pageAnimationReady, headerHeight } = useContext(GeneralContext)
  const { setReady, setSiteLoaded } = useContext(LoadingContext)
  const classes = useStyles({ headerHeight })
  const [load, setLoad] = useState(false)
  const preload = useRef(new CreateJs.LoadQueue())
  const location = useLocation()
  const stub = useRef(getSlug(location.pathname))
  const $root = useRef()
  const [pagination, setPagination] = useState(1)

  /*------------------------------
  Executes a callback on loading each image
  ------------------------------*/
  useHandleAllMediaWithCb({
    init: load,
    ref: $root.current?.ref,
  })

  /*------------------------------
  Redux Connect
  ------------------------------*/
  const { page, strings, posts } = useSelector((state) => ({
    page: state.content.pages[stub.current] || {},
    strings: state.options.strings,
    posts: state.content.posts,
  }), shallowEqual)

  /*------------------------------
  Redux Actions
  ------------------------------*/
  const dispatch = useDispatch()
  const fetchPage = useCallback((slug) => dispatch(contentActions.fetchPage(slug)), [dispatch])
  const fetchAllPosts = useCallback((numItem, paged, category, save) => dispatch(contentActions.fetchAllPosts(numItem, paged, category, save)), [dispatch])

  /*------------------------------
  Complete Preload
  ------------------------------*/
  const completePreload = () => {
    setReady(true)
    setSiteLoaded(true)
    setLoad(true)
  }

  /*------------------------------
  Activate Page Animation
  ------------------------------*/
  useEffect(() => {
    if (load) setPageAnimationReady(true)
  }, [load])

  /*------------------------------
  Preload Media
  ------------------------------*/
  const preloadMedia = () => {
    preload.current.on('complete', completePreload)
    posts.map((item) => {
      preload.current.loadFile({ id: `img-${item.id}`, src: item.featured_image })
      return false
    })
  }

  /*------------------------------
  Preload Media when there are Data of Page in Redux
  ------------------------------*/
  useEffect(() => {
    if (Object.keys(page).length > 0 && posts.length > 0) preloadMedia()
  }, [posts, page])

  /*------------------------------
  Fetch all data
  ------------------------------*/
  const fetchData = (slugPage) => {
    if (Object.keys(page).length === 0) fetchPage(slugPage)
    return false
  }

  /*------------------------------
  Initialize
  ------------------------------*/
  const initialize = () => {
    fetchData(stub.current)
    if (posts.length === 0 || posts.length === 1) {
      fetchAllPosts(NUM_ITEM, pagination, '', true)
    }
  }

  /*------------------------------
  Did Mount
  ------------------------------*/
  useEffect(() => {
    if (!load) initialize()
  }, [])

  /*------------------------------
  Render Helmet
  ------------------------------*/
  const renderHelmet = () => {
    return load && (
      <Meta
        title={page.title.rendered}
        meta={page.yoast_meta}
        schema={page.yoast_json_ld}
      />
    )
  }

  /*------------------------------
  Render Heading
  ------------------------------*/
  const renderHeading = () => {
    return posts.length > 0 && (
      <RevealText
        tag="h1"
        className={classes.title}
        type="lines"
        value={page.title.rendered}
        visible={pageAnimationReady}
      />
    )
  }

  /*------------------------------
  Render Heading
  ------------------------------*/
  const renderProducts = () => {
    return posts.length > 0 && (
      posts.map((item, index) => (
        <Banner
          image={item.featured_image}
          title={item.title.rendered}
          text={item.content.rendered}
          label={strings.read_more_label}
          link={item.link}
          key={index.toString()}
          imageRight={!(index % 2 === 0)}
          verticalCentered={true}
        />
      ))
    )
  }

  /*------------------------------
  Render Footer
  ------------------------------*/
  const renderFooter = () => <Footer />

  /*------------------------------
  Render Content
  ------------------------------*/
  const renderContent = () => {
    return load && (
      <>
        <div className={classes.page}>
          {renderHeading()}
          {renderProducts()}
        </div>
        {renderFooter()}
      </>
    )
  }

  return (
    <LocomotiveScroll
      init={load}
      className={`page pageArchiveProjects ${classes.root}`}
      ref={$root}
    >
      {renderHelmet()}
      {renderContent()}
    </LocomotiveScroll>
  )
}

export default ArchiveProjects
