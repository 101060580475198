const style = (theme) => {
  const transition = `all 1s ${theme.easings['power4.out']}`

  const output = {
    root: {
      position: 'fixed',
      top: 0,
      width: '100%',
      zIndex: theme.zindex.header,
      transition: 'all 0.6s',
      pointerEvents: 'none',
      background: 'transparent',
    },
    background: {},
    minimize: {
      background: theme.colors[0],
      '-webkit-box-shadow': '0px 4px 14px -7px rgba(0,0,0,0.28)',
      boxShadow: '0px 4px 14px -7px rgba(0,0,0,0.28)',
      '& $logo': {
      },
    },
    container: {
      margin: '0 auto',
      paddingTop: theme.header.top,
      paddingBottom: theme.header.bottom,
    },
    wrapper: {
      extend: theme.wrapper,
      position: 'relative',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      transition,
    },
    logo: {
      display: 'inline-block',
      pointerEvents: 'all',
      '& svg': {
        transition: 'all 0.3s',
        fill: theme.colors[4],

      },
    },
    logoMinimize: {
      '& svg': {
        fill: theme.colors[0],
      },
    },
    nav: {
      display: 'flex',
      alignItems: 'center',
      pointerEvents: 'all',
      height: '100%',
      position: 'relative',
    },
    burgerButton: {
      position: 'relative',
    },
    changeBurgerColor: {
      '& .inner': {
        '&::before': {
          backgroundColor: theme.colors[0],
        },
        '&::after': {
          backgroundColor: theme.colors[0],
        },
      },
    },
    back: {
      opacity: 1,
      marginRight: 50,
      transition: 'opacity .6s',
      width: 11,
      height: 11,
      position: 'relative',
      bottom: 1.5,
      cursor: 'pointer',
      '& svg': {
        transform: 'rotate(180deg)',
        transformOrigin: 'center',
        width: '100%',
        height: '100%',
        fill: theme.colors[4],
      },
    },
    // backVisible: {
    //   opacity: 1,
    // },
    hide: {
      opacity: 0,
      pointerEvents: 'none',
    },
    lang: {
      transition: 'all .3s',
      marginRight: 42,
    },
    hideLang: {
      opacity: 0,
      pointerEvents: 'none',
    },
    headerLink: {
      fontSize: 13,
      fontFamily: theme.fonts[0],
      textTransform: 'uppercase',
      color: theme.colors[4],
      marginRight: 50,
      position: 'relative',
      top: 2,
      letterSpacing: 1,
      transition: 'all .3s',
    },
    hideHeaderLink: {
      opacity: 0,
      pointerEvents: 'none',
    },
  }

  output[theme.mediaInverse.md] = {
    lang: {
      marginRight: 0,
      opacity: 0,
      pointerEvents: 'none',
      position: 'absolute',
      right: 30,
    },
    hideLang: {
      opacity: 1,
      pointerEvents: 'all',
    },

    /* langMobile: {
      opacity: 1,
      pointerEvents: 'all',
    }, */
  }

  output[theme.mediaInverse.sm] = {
    wrapper: {
      extend: theme.smallWrapper,
    },
    burgerButton: {
      marginLeft: 20,
    },
    back: {
      marginRight: 20,
    },
    headerLink: {
      display: 'none',
    },
  }

  return output
}

export default style
