import { useSelector, shallowEqual } from 'react-redux'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { createUseStyles } from 'react-jss'
import ConditionalWrapper from '@/components/ConditionalWrapper'
import Loader from '@/components/Loader'
import Header from '@/components/Header'
import useFontPreload from '@/hooks/useFontPreload'
import LogoSpinner from '@/components/LogoSpinner'
import Nav from '@/components/Nav'
import SVGSprite from '@/components/SVGSprite'
import CookieBanner from '@/components/CookieBanner'
import { CONFIG } from '@/constants'
import global from '@/global'

const useStyles = createUseStyles(global)

const Layout = ({ children }) => {
  const classes = useStyles()

  useFontPreload({ fonts: ['ATSurt-Light', 'ATSurt-Bold'] })

  const { mainNav, extraNav, strings } = useSelector((state) => ({
    extraNav: state.nav.extra_menu,
    languageNav: state.nav.language_menu,
    mainNav: state.nav.main_menu,
    strings: state.options.strings,
  }), shallowEqual)

  return (
    <div className={`${classes.root} content-root`}>
      <ConditionalWrapper
        condition={process.env.NODE_ENV === 'production'}
        wrapper={(child) => <GoogleReCaptchaProvider reCaptchaKey={CONFIG.RECAPTCHA_SITE_KEY}>{child}</GoogleReCaptchaProvider>}
      >
        <Loader />
        <LogoSpinner />
        <Nav
          mainNav={mainNav}
          extraNav={extraNav}
        />
        <Header />
        <div className="wrapper">
          {children}
        </div>
        <SVGSprite />
        <CookieBanner
          text={strings.cookies_short_text}
          acceptLabel={strings.cookies_btn_text}
          link={strings.cookies_info_url}
          linkLabel={strings.cookies_info_label}
        />
      </ConditionalWrapper>
    </div>
  )
}

export default Layout
