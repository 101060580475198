import { useRef, useContext } from 'react'
import classNames from 'classnames'
import { createUseStyles } from 'react-jss'
import BtnLink from '@/components/BtnLink'
import { Context as GeneralContext } from '@/context'
import ConditionalWrapper from '@/components/ConditionalWrapper'
import RevealText from '@/components/RevealText'
import RevealFadeIn from '@/components/RevealFadeIn'
import InViewTrigger from '@/components/InViewTrigger'
import ScrollIcon from '@/components/ScrollIcon'
import style from './style'

const useStyles = createUseStyles(style)

const Hero = ({
  image,
  title,
  titleStroke,
  text,
  link,
  label,
  textAlignCenter,
  textUppercase,
  scrollIcon,
  buttonColor,
  aboveTheFold,
  verticalCentered,
  data,
  luogo,
  categoria,
}) => {
  const { pageAnimationReady } = useContext(GeneralContext)
  const classes = useStyles()
  const $root = useRef()

  return (
    <div
      className={classes.root}
      ref={$root}
    >
      <div className={classNames({
        [classes.hero]: true,
        [classes.textAlignCenter]: textAlignCenter,
      })}
      >
        {image && (
          <InViewTrigger className={classes.triggerHeroImage}>
            <RevealFadeIn className={classes.revealHeroImage}>
              <img
                src={image}
                alt={title}
              />
            </RevealFadeIn>
          </InViewTrigger>
        )}
        <div className={classNames({
          [classes.wrapperHero]: true,
          [classes.titleStroke]: titleStroke,
          [classes.wrapperHeroH2]: verticalCentered === false,
          [classes.wrapperHeroAlignCenter]: textAlignCenter,
          [classes.textUppercase]: textUppercase,
        })}
        >
          <ConditionalWrapper
            condition={!aboveTheFold}
            wrapper={(child) => <InViewTrigger>{child}</InViewTrigger>}
          >
            <RevealText
              tag="h3"
              type="lines"
              value={data}
              visible={aboveTheFold && pageAnimationReady}
              className={classes.info}
              lineHeight={1}
            />
            <RevealText
              tag="h3"
              type="lines"
              value={luogo}
              visible={aboveTheFold && pageAnimationReady}
              className={classes.info}
              lineHeight={1}
            />
            <RevealText
              tag="h3"
              type="lines"
              value={categoria}
              visible={aboveTheFold && pageAnimationReady}
              className={classes.info}
              lineHeight={1}
            />
            <RevealText
              tag="h1"
              type="lines"
              value={title}
              visible={aboveTheFold && pageAnimationReady}
              lineHeight={1}
            />
            <RevealText
              tag="h2"
              type="lines"
              value={text}
              delay={aboveTheFold ? 0.2 : 0}
              visible={aboveTheFold && pageAnimationReady}
            />
            {link && label ? (
              <RevealFadeIn
                className={classNames({
                  [classes.wrapperHeroLink]: true,
                  [classes.wrapperHeroLinkCenter]: textAlignCenter,
                })}
                delay={aboveTheFold ? 0.4 : 0}
                visible={aboveTheFold && pageAnimationReady}
              >
                <BtnLink
                  link={link}
                  label={label}
                  color={buttonColor}
                />
              </RevealFadeIn>
            ) : <span />}
          </ConditionalWrapper>
        </div>
        {scrollIcon && (
          <div className={classes.scrollIndicator}>
            <ScrollIcon
              show={true}
              line
            />
          </div>
        )}
      </div>
    </div>
  )
}

Hero.defaultProps = {
  aboveTheFold: false,
  labelLink: 'Scopri',
  textAlignCenter: false,
  titleStroke: false,
  scrollIcon: false,
  buttonColor: 'white',
  link: '',
  verticalCentered: true,
}

export default Hero
