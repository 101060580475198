import { useRef, useState, useEffect, useCallback, useContext } from 'react'
import HTMLParse from 'react-html-parser'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { createUseStyles } from 'react-jss'
import { Context as GeneralContext } from '@/context'
import { Context as LoadingContext } from '@/context/loading'
import LocomotiveScroll from '@/components/LocomotiveScroll'
import WpContactForm7 from '@/components/WpContactForm7'
import RevealFadeIn from '@/components/RevealFadeIn'
import RevealText from '@/components/RevealText'
import DelayLink from '@/components/DelayLink'
import Meta from '@/components/Meta'
import { getSlug } from '@/utils/path'
import * as contentActions from '@/actions/content'
import * as formActions from '@/actions/form'
import { EMITTER, EVENTS } from '@/constants'
import style from './style'

const useStyles = createUseStyles(style)

const Contact = () => {
  const { setPageAnimationReady, pageAnimationReady, headerHeight, setHeaderInverse } = useContext(GeneralContext)
  const { setReady, setSiteLoaded } = useContext(LoadingContext)
  const classes = useStyles({ headerHeight })
  const [load, setLoad] = useState(false)
  const $root = useRef()
  const location = useLocation()
  const stub = useRef(getSlug(location.pathname))
  const formId = useRef()

  /*------------------------------
  Redux Connect
  ------------------------------*/
  const { page, currentForm, strings } = useSelector((state) => ({
    page: state.content.pages[stub.current] || {},
    currentForm: state.form[formId.current] || [],
    strings: state.options.strings || {},
  }), shallowEqual)

  /*------------------------------
  Redux Actions
  ------------------------------*/
  const dispatch = useDispatch()
  const fetchPage = useCallback((slug) => dispatch(contentActions.fetchPage(slug)), [dispatch])
  const fetchForm = useCallback((id) => dispatch(formActions.fetchForm(id)), [dispatch])
  const sendContactForm = useCallback((id, data, isMultipart) => dispatch(formActions.sendContactForm(id, data, isMultipart)), [dispatch])

  /*------------------------------
  Complete Preload
  ------------------------------*/
  const completePreload = () => {
    setTimeout(() => {
      setReady(true)
      setSiteLoaded(true)
      setLoad(true)
    }, 500)
  }
  /*------------------------------
  If Page exist call API form
  ------------------------------*/
  useEffect(() => {
    if (Object.keys(page).length > 0) {
      formId.current = page.acf.formId
      fetchForm(page.acf.formId)
    }
  }, [page])

  /*------------------------------
  Complete Preload if form exist
  ------------------------------*/
  useEffect(() => {
    if (currentForm.length > 0 && Object.keys(page).length > 0) {
      completePreload()
    }
  }, [currentForm, page])

  /*------------------------------
  Fetch all data
  ------------------------------*/
  const fetchData = (slugPage) => {
    if (Object.keys(page).length === 0) fetchPage(slugPage)
    return false
  }

  /*------------------------------
  Initialize
  ------------------------------*/
  const initialize = () => {
    fetchData(stub.current)
  }

  /*------------------------------
  Did Mount
  ------------------------------*/
  useEffect(() => {
    if (!load) initialize()
  }, [])

  useEffect(() => {

  })

  /*------------------------------
  Activate Page Animation
  ------------------------------*/
  useEffect(() => {
    if (load) setPageAnimationReady(true)
  }, [load])

  /*------------------------------
  Inverse Header
  ------------------------------*/
  useEffect(() => {
    setHeaderInverse(true)
    return () => {
      setHeaderInverse(false)
    }
  })

  /*------------------------------
  Render Helmet
  ------------------------------*/
  const renderHelmet = () => {
    return load && (
      <Meta
        title={page.title.rendered}
        meta={page.yoast_meta}
        schema={page.yoast_json_ld}
      />
    )
  }

  /*------------------------------
  Render Form
  ------------------------------*/
  const renderForm = () => {
    return (
      <div className={`${classes.formContent} ${classes.formContentCenter}`}>
        <div className={classes.pretitle}>{page.contact_form_title}</div>
        <WpContactForm7
          fields={currentForm}
          formID={formId.current}
          sendContactForm={sendContactForm}
          submitLabel={strings.label_submit}
          onSuccessSend={() => EMITTER.emit(EVENTS.DATALAYER.FORM_SENDED)}
        />
      </div>
    )
  }

  const renderGrid = (children) => {
    return (
      <div className={classes.smallWrapper}>
        <div className={classes.left}>
          <RevealFadeIn
            delay={0.4}
            visible={pageAnimationReady}
          >
            {HTMLParse(strings.sede1)}
            Tel:
            {' '}
            <a href={`tel:${strings.telephone1}`}>{strings.telephone1}</a>
            <br />
            <br />

            {HTMLParse(strings.sede2)}
            Tel:
            {' '}
            <a href={`tel:${strings.telephone2}`}>{strings.telephone2}</a>
            <br />
            <br />

            Email:
            {' '}
            <a href={`mailto:${strings.email}`}>{strings.email}</a>
            <br />
            <br />

            P.IVA:
            {' '}
            {strings.piva}
          </RevealFadeIn>
        </div>
        <RevealFadeIn
          className={classes.right}
          delay={0.8}
          visible={pageAnimationReady}
        >
          {children}
        </RevealFadeIn>
      </div>
    )
  }

  /*------------------------------
  Render Footer
  ------------------------------*/

  const renderFooter = () => {
    return (
      <div className={classes.footer}>
        <div className={classes.containerCopy}>
          <p>{strings.copyright}</p>
          <div>
            <DelayLink
              to={strings.cookie}
            >
              {strings.cookie_label}
            </DelayLink>
            <div>&nbsp;</div>
            -
            <div>&nbsp;</div>
            <DelayLink
              to={strings.privacy}
            >
              {strings.privacy_label}
            </DelayLink>
          </div>
        </div>
        <div className={classes.containerSocial}>
          { strings.facebook && (
          <DelayLink
            to={strings.facebook}
          >
            {strings.label_facebook}
          </DelayLink>
          )}
          { strings.twitter && (
            <DelayLink
              to={strings.twitter}
            >
              {strings.label_twitter}
            </DelayLink>
          )}
          { strings.instagram && (
            <DelayLink
              to={strings.instagram}
            >
              {strings.label_instagram}
            </DelayLink>
          )}
        </div>
      </div>
    )
  }

  /*------------------------------
  Render Content
  ------------------------------*/
  const renderContent = () => {
    return load && (
      <>
        <div className={classes.page}>
          <RevealText
            tag="h1"
            type="lines"
            value={page.title.rendered}
            visible={pageAnimationReady}
          />
          {renderGrid(renderForm())}
          {renderFooter()}
        </div>
      </>
    )
  }

  return (
    <LocomotiveScroll
      init={load}
      className={`page pageContact ${classes.root}`}
      ref={$root}
    >
      {renderHelmet()}
      {renderContent()}
    </LocomotiveScroll>
  )
}

export default Contact
