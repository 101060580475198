import Home from '@/pages/Home'
import ArchiveProjects from '@/pages/ArchiveProjects'
import ArchiveProjectsCategory from '@/pages/ArchiveProjectsCategory'
import ArchiveProducts from '@/pages/ArchiveProducts'
import ArchiveNews from '@/pages/ArchiveNews'
import Editorial from '@/pages/Editorial'
import Contact from '@/pages/Contact'
import GenericCpt from '@/pages/GenericCpt'
import Team from '@/pages/Team'
import Showroom from '@/pages/Showroom'
import Post from '@/pages/Post'
import NotFound from '@/pages/NotFound'
import SimplePage from '@/pages/SimplePage'
import { internazionalizePath, getPathFromCpt } from '@/utils/path'

export default (routing) => [
  {
    key: 0,
    path: internazionalizePath('/'),
    exact: true,
    render: () => <Home stub={routing.find((r) => r.cpt === 'home')?.slug} />,
  },
  {
    key: 1,
    path: internazionalizePath(
      getPathFromCpt(routing, 'archive_projects'),
    ),
    render: () => <ArchiveProjects />,
  },
  {
    key: 2,
    path: internazionalizePath(
      getPathFromCpt(routing, '_cta_category'),
    ),
    render: () => <ArchiveProjectsCategory />,
  },
  {
    key: 3,
    path: internazionalizePath(
      getPathFromCpt(routing, 'editorial'),
    ),
    render: () => <Editorial />,
  },
  {
    key: 4,
    exact: true,
    path: internazionalizePath(
      getPathFromCpt(routing, 'contact'),
    ),
    render: () => <Contact />,
  },
  {
    key: 5,
    path: internazionalizePath(
      getPathFromCpt(routing, 'project'),
    ),
    render: () => <GenericCpt cptFromProps="_cty_project" />,
  },
  {
    key: 6,
    path: internazionalizePath(
      getPathFromCpt(routing, 'product'),
    ),
    render: () => <GenericCpt cptFromProps="_cty_product" />,
  },
  {
    key: 7,
    path: internazionalizePath(
      getPathFromCpt(routing, 'team'),
    ),
    render: () => <Team />,
  },
  {
    key: 8,
    path: internazionalizePath(
      getPathFromCpt(routing, 'showroom'),
    ),
    render: () => <Showroom />,
  },
  {
    key: 9,
    exact: true,
    path: internazionalizePath(
      getPathFromCpt(routing, 'archive_products'),
    ),
    render: () => <ArchiveProducts />,
  },
  {
    key: 10,
    path: internazionalizePath(
      getPathFromCpt(routing, 'post'),
    ),
    render: () => <Post />,
  },
  {
    key: 11,
    exact: true,
    path: internazionalizePath(
      getPathFromCpt(routing, 'archive_news'),
    ),
    render: () => <ArchiveNews />,
  },
  {
    key: 12,
    exact: true,
    path: internazionalizePath(
      getPathFromCpt(routing, 'simple_page'),
    ),
    render: () => <SimplePage />,
  },
  {
    key: 999,
    render: () => <NotFound />,
  },
]
