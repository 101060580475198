const style = (theme) => {
  const output = {
    root: {
      position: 'relative',
      opacity: 0,
      '& p': {
        marginBottom: 0,
      },
      '& *': {
        lineHeight: 1.25,
        // whiteSpace: 'pre',
        display: 'block',
        position: 'relative',
        flex: 'none',
        textAlign: 'left',
        textAlignLast: 'left',
        '& p': {
          '& div': {
            textAlign: 'left',
            textAlignLast: 'left',
          },
        },
      },
    },
    oneLine: {
      '& *': {
        whiteSpace: 'pre',
      },
    },
    wrap: {
      overflow: 'hidden',
      '& div.line:not(:last-child)': {
        marginBottom: (props) => `${props.linesMargin}em`,
        overflow: 'hidden',
        '& div': {
          overflow: 'hidden',
        },
      },
    },
  }

  /*------------------------------
  SM
  ------------------------------*/
  output[theme.mediaInverse.sm] = {
  }

  return output
}

export default style
