import { useContext } from 'react'
import { createUseStyles } from 'react-jss'
import classNames from 'classnames'
import { Context as LoadingContext } from '@/context/loading'
import Logo from '@/components/Logo'
import style from './style'

const useStyles = createUseStyles(style)

const LogoSpinner = () => {
  const { isLoading } = useContext(LoadingContext)
  const classes = useStyles()

  return (
    <div
      className={classNames({
        [classes.root]: true,
        [classes.visible]: isLoading,
      })}
    >
      <div className={classes.logoContainer}>
        <Logo
          className={classes.logo}
          // ref={$logo}
        />
      </div>
      <div className={classes.spinnerContainer}>
        <div
          // ref={$spinner}
          className={classes.spinner}
        />
      </div>
    </div>
  )
}

export default LogoSpinner
