import { useRef, useState, useEffect, useCallback, useContext } from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { createUseStyles } from 'react-jss'
import CreateJs from 'preload-js'
import { Context as GeneralContext } from '@/context'
import { Context as LoadingContext } from '@/context/loading'
import LocomotiveScroll from '@/components/LocomotiveScroll'
import InViewTrigger from '@/components/InViewTrigger'
import RevealText from '@/components/RevealText'
import Banner from '@/components/Banner'
import Meta from '@/components/Meta'
import Hero from '@/components/Hero'
import ListHover from '@/components/ListHover'
import ListProjects from '@/components/ListProjects'
import Footer from '@/components/Footer'
import useHandleAllMediaWithCb from '@/hooks/useHandleAllMediaWithCb'
import * as contentActions from '@/actions/content'
import style from './style'

const useStyles = createUseStyles(style)

const HomePage = ({
  stub: stubFromProps,
}) => {
  const { setPageAnimationReady, headerHeight, setIsHome } = useContext(GeneralContext)
  const { firstLoad, setReady, setSiteLoaded } = useContext(LoadingContext)
  const classes = useStyles({ headerHeight })
  const [load, setLoad] = useState(false)
  const preload = useRef(new CreateJs.LoadQueue())
  const stub = useRef(stubFromProps)
  const $root = useRef()

  /*------------------------------
  Executes a callback on loading each image
  ------------------------------*/
  useHandleAllMediaWithCb({
    init: load,
    ref: $root.current?.ref,
  })

  /*------------------------------
  Redux Connect
  ------------------------------*/
  const { page } = useSelector((state) => ({
    page: state.content.pages[stub.current] || {},
  }), shallowEqual)

  /*------------------------------
  Redux Actions
  ------------------------------*/
  const dispatch = useDispatch()
  const fetchPage = useCallback((slug) => dispatch(contentActions.fetchPage(slug)), [dispatch])

  /*------------------------------
  Complete Preload
  ------------------------------*/
  const completePreload = () => {
    setReady(true)
    setSiteLoaded(true)
    setLoad(true)
  }

  /*------------------------------
  Preload Media
  ------------------------------*/
  const preloadMedia = () => {
    preload.current.on('complete', completePreload)
    if (page.featured_image) preload.current.loadFile({ src: page.featured_image })
  }

  /*------------------------------
  Preload Media when there are Data of Page in Redux
  ------------------------------*/
  useEffect(() => {
    if (Object.keys(page).length > 0) preloadMedia()
  }, [page])

  /*------------------------------
  Fetch all data
  ------------------------------*/
  const fetchData = (slugPage) => {
    if (Object.keys(page).length === 0) fetchPage(slugPage)
    return false
  }

  /*------------------------------
  Initialize
  ------------------------------*/
  const initialize = () => {
    fetchData(stub.current)
  }

  /*------------------------------
  Did Mount
  ------------------------------*/
  useEffect(() => {
    if (!load) initialize()
    setIsHome(true)
    return () => {
      setIsHome(false)
    }
  }, [])

  /*------------------------------
  Activate Page Animation
  ------------------------------*/
  useEffect(() => {
    if (load && !firstLoad) setPageAnimationReady(true)
  }, [load])

  /*------------------------------
  Render Helmet
  ------------------------------*/
  const renderHelmet = () => {
    return load && (
      <Meta
        title={page.title.rendered}
        meta={page.yoast_meta}
        schema={page.yoast_json_ld}
      />
    )
  }

  /*------------------------------
  Render Hero
  ------------------------------*/
  const renderHero = () => {
    return Object.keys(page).length > 0 && (
      <Hero
        image={page.acf.hero.image.url}
        title={page.acf.hero.title}
        text={page.acf.hero.text}
        link={page.acf.hero.link}
        label={page.acf.hero.label}
        textUppercase={true}
        textAlignCenter={true}
        scrollIcon={true}
        aboveTheFold={true}
      />
    )
  }

  /*------------------------------
  Render Hover List
  ------------------------------*/
  const renderHoverList = () => {
    return (
      <div className={classes.wrapperList}>
        <InViewTrigger>
          <RevealText
            tag="h2"
            type="lines"
            value={page.acf.voices_hover.pretitle}
          />
        </InViewTrigger>
        <ListHover items={page.acf.voices_hover.voices_hover} />
      </div>
    )
  }

  /*------------------------------
  Render Projects
  ------------------------------*/
  const renderProjects = () => {
    return (
      <div className={classes.wrapperList}>
        <InViewTrigger>
          <RevealText
            tag="h2"
            type="lines"
            value={page.acf.projects.pretitle}
          />
        </InViewTrigger>
        <ListProjects items={page.acf.projects.projects} />
      </div>
    )
  }

  /*------------------------------
  Render Banner Home
  ------------------------------*/
  const renderBannerHome = () => {
    return (
      <Banner
        pretitle={page.acf.related_page1.pretitle}
        image={page.acf.related_page1.image.url}
        title={page.acf.related_page1.title}
        text={page.acf.related_page1.text}
        label={page.acf.related_page1.label}
        link={page.acf.related_page1.link}
      />
    )
  }

  /*------------------------------
  Render Second Hero
  ------------------------------*/
  const renderRelatedCategory = () => {
    return Object.keys(page).length > 0 && (
      <Hero
        image={page.acf.related_page2.image.url}
        title={page.acf.related_page2.title}
        link={page.acf.related_page2.link}
        label={page.acf.related_page2.label}
        buttonColor="white"
      />
    )
  }

  /*------------------------------
  Render Footer
  ------------------------------*/
  const renderFooter = () => <Footer />

  /*------------------------------
  Render Content
  ------------------------------*/
  const renderContent = () => {
    return load && (
      <>
        <div className={classes.page}>
          { renderHero() }
          { renderHoverList() }
          { renderProjects() }
          { renderBannerHome() }
          { renderRelatedCategory() }
        </div>
        {renderFooter()}
      </>
    )
  }

  return (
    <LocomotiveScroll
      init={load}
      className={`page pageHome ${classes.root}`}
      ref={$root}
    >
      {renderHelmet()}
      {renderContent()}
    </LocomotiveScroll>
  )
}

export default HomePage
