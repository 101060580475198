const style = (theme) => {
  const transition = `all 1s ${theme.easings['power3.out']}`

  const output = {
    root: {
      transition,
    },
    wrapper: {
      // background: theme.colors[7],
      // borderRadius: 12,
      // padding: 30,
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      '& > *:not(:last-child)': {
        marginBottom: 20,
      },
    },
    title: {
      fontSize: 20,
      '& a': {
        color: theme.colors[4],
        fontFamily: theme.fonts[1],
        position: 'relative',
        textTransform: 'uppercase',
        lineHeight: 1,
        letterSpacing: '1px',
        '& p': {
          margin: 0,
        },
      },
    },
    cover: {
      position: 'relative',
      '& > a': {
        display: 'block',
      },
    },
    image: {
      position: 'relative',
      width: '100%',
      paddingBottom: '70%',
      background: theme.colors[4],
      overflow: 'hidden',
    },
    img: {
      position: 'absolute',
      // top: '5%',
      // left: '5%',
      // width: '90%',
      // height: '90%',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      fontFamily: "'object-fit: cover;'",
      transition,
    },
    description: {
      fontSize: 20,
      fontWeight: 400,
      color: theme.colors[4],
      fontFamily: theme.fonts[1],
    },
    control: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: 'auto',
    },
    price: {
      fontSize: 16,
      fontWeight: 400,
      textTransform: 'uppercase',
      color: theme.colors[4],
      fontFamily: theme.fonts[1],
    },
    hover: {
      '& $title': {
        '& a': {
          '&:before': {
            transformOrigin: '0% 0',
            transform: 'scaleX(1)',
          },
        },
      },
      '& $img': {
        transform: 'scale(1.02)',
      },
    },
  }
  output[theme.mediaInverse.md] = {
    title: {
      // fontSize: 20,
    },
    root: {
      textAlign: 'left',
    },
  }

  return output
}

export default style
