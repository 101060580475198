const style = (theme) => {
  const output = {
    visible: {},
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      pointerEvents: 'none',
      transition: 'opacity 0.5s',
      opacity: 0,
      position: 'fixed',
      zIndex: theme.zindex.mainNav,
      top: 'calc(50%)',
      left: 'calc(50%)',
      transform: 'translate(-50%, -50%)',
      '&$visible': {
        opacity: 1,
      },
    },
    logoContainer: {
      '& svg': {
        fill: theme.colors[4],
        width: 180,
        height: 132,
      },
    },
    spinner: {
      marginTop: 30,
      width: 220,
      height: 2,
      background: theme.colors[5],
      borderRadius: 20,
      position: 'relative',
      overflow: 'hidden',
      '&:after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        top: 0,
        left: 0,
        width: 0,
        height: '100%',
        background: theme.colors[4],
        animation: '$loading 1s ease-in-out infinite',
      },
    },
    '@keyframes loading': {
      '50%': { width: '100%' },
      '100%': { width: 0, right: 0, left: 'unset' },
    },
  }

  output[theme.mediaInverse.sm] = {
    logoContainer: {
      '& svg': {
        width: 120,
        height: 88,
      },
    },
    spinner: {
      width: 180,
    },
  }

  return output
}

export default style
