const style = (theme) => {
  const output = {
    root: {
      position: 'relative',
      width: '100vw',
      // height: '100vh',
      fallbacks: {
        height: 'calc(var(--vh-nav) * 100)',
      },
      overflow: 'hidden',
    },
    hero: {
      position: 'relative',
      zIndex: 0,
      minHeight: 300,
      // height: '100vh',
      height: 'auto',
      '&:before': {
        content: '""',
        position: 'absolute',
        zIndex: 1,
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: 'linear-gradient(180deg, rgba(0,0,0,0.8) 0%, rgba(9,9,121,0) 60%, rgba(0,212,255,0) 100%)',
        pointerEvents: 'none',
      },
      '&:after': {
        content: '""',
        position: 'absolute',
        zIndex: 1,
        bottom: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: 'linear-gradient(0deg, rgba(0,0,0,0.8) 0%, rgba(9,9,121,0) 60%, rgba(0,212,255,0) 100%)',
        pointerEvents: 'none',
      },
      '& img': {
        objectFit: 'cover',
        objectPosition: '50% 50%',
        width: '100%',
        height: '100%',
      },
    },
    triggerHeroImage: {
      width: '100%',
      height: '100%',
      position: 'absolute',
      top: 0,
      left: 0,
    },
    revealHeroImage: {
      width: '100%',
      height: '100%',
    },
    wrapperHero: {
      width: '100%',
      // height: '100%',
      height: 'var(--vh-fixed)',
      extend: theme.smallWrapper,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      position: 'relative',
      zIndex: 2,
      '& h1': {
        fontSize: 80,
        fontFamily: theme.fonts[1],
        verticalAlign: 'baseline',
        color: theme.colors[4],
        position: 'relative',
        left: -7,
      },
      '& h2': {
        fontSize: 20,
        fontFamily: theme.fonts[1],
        fontWeight: 400,
        display: 'inline-block',
        verticalAlign: 'baseline',
        color: theme.colors[4],
        marginTop: 30,
        width: '50%',
      },
    },
    wrapperHeroH2: {
      paddingBottom: '10vh',
      justifyContent: 'flex-end',
      // '& h2': {
      //   fontSize: 40,
      //   fontFamily: theme.fonts[1],
      //   fontWeight: 400,
      //   width: '100%',
      // },
      '& h2': {
        marginTop: 10,
      },
    },
    wrapperHero1: {
      width: '100%',
      height: 'auto',
      extend: theme.smallWrapper,
      position: 'relative',
      zIndex: 2,
      paddingTop: 50,
      paddingBottom: 100,
      '& h3': {
        fontSize: 24,
        fontFamily: theme.fonts[1],
        fontWeight: 400,
        color: theme.colors[4],
        width: '50%',
      },
    },
    textAlignCenter: {
      textAlign: 'center',
      '& h1': {
        left: 0,
      },
    },
    textUppercase: {
      '& h1': {
        textTransform: 'uppercase',
      },
    },
    wrapperHeroAlignCenter: {
      alignItems: 'center',
      '& h2': {
        '& div': {
          '& p': {
            textAlign: 'center',
            textAlignLast: 'center',
          },
        },
      },
      '& h1': {
        '& div': {
          textAlign: 'center',
          textAlignLast: 'center',
        },
      },
    },
    titleStroke: {
      '& h1': {
        color: 'transparent',
        '-webkit-text-fill-color': 'transparent',
        '-webkit-text-stroke-width': '1px',
        '-webkit-text-stroke-color': theme.colors[4],
        textTransform: 'uppercase',
      },
    },
    wrapperHeroLink: {
      display: 'block',
      width: 'fit-content',
      // extend: theme.smallWrapper,
      paddingLeft: 3,
    },
    wrapperHeroLinkCenter: {
      position: 'absolute',
      left: '50%',
      bottom: 90,
      transform: 'translateX(-50%)',
    },
    scrollIndicator: {
      width: 'auto',
      position: 'absolute',
      bottom: '0',
      left: '50%',
    },
    info: {
      fontFamily: theme.fonts[0],
      fontSize: 11,
      letterSpacing: '1px',
      color: theme.colors[4],
      textTransform: 'uppercase',
      marginTop: 1,
      '&:nth-child(3)': {
        marginBottom: 20,
      },
    },
  }

  output[theme.mediaInverse.xl] = {

  }

  output[theme.mediaInverse.md] = {
    wrapperHero: {
      '& h1': {
        fontSize: 38,
        left: -2,
      },
      '& h2': {
        fontSize: 20,
        width: '100%',
        marginTop: 10,
      },
    },
    wrapperHero1: {
      paddingTop: 50,
      paddingBottom: 50,
      '& h3': {
        fontSize: 15,
        width: '100%',
      },
    },
    wrapperHeroH2: {
      paddingBottom: '5vh',
    },
  }

  output[theme.mediaInverse.sm] = {

  }

  return output
}

export default style
