import { useRef, useEffect, useState } from 'react'
import { oneOfType, array, object, number, string, bool } from 'prop-types'
import classNames from 'classnames'
import { createUseStyles, useTheme } from 'react-jss'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import useScrollTrigger from '@/hooks/useScrollTrigger'
import usePrevious from '@/hooks/usePrevious'
import style from './style'

gsap.registerPlugin(ScrollTrigger)

const useStyles = createUseStyles(style)

const RevealGrid = ({
  children,
  className,
  cols,
  init,
  reset,
  singleRow,
  spaceBetween,
}) => {
  const classes = useStyles({ cols, spaceBetween, singleRow })
  const theme = useTheme()
  const $root = useRef()
  const { animationReady } = useScrollTrigger()
  const trigger = useRef()
  const [childLength, setChildLength] = useState(0)

  const scrollTrigger = () => {
    trigger.current = ScrollTrigger.batch($root.current.children, {
      onEnter: (el) => {
        gsap.to(el, {
          opacity: 1,
          stagger: 0.2,
        })
      },
    })
  }

  useEffect(() => {
    if (animationReady && init) {
      if ($root.current) scrollTrigger()
    }
    if (!theme.detect.isIE11) window.dispatchEvent(new Event('resize'))
  }, [animationReady, init, children])

  const prevChild = usePrevious(children)
  useEffect(() => {
    if (
      prevChild
      && prevChild.length !== children.length
    ) setChildLength(children.length)
  }, [children])

  useEffect(() => {
    if (reset) {
      trigger.current.map((t) => t.kill())
      gsap.to($root.current.children, {
        opacity: 0,
        onComplete: () => {
          setTimeout(() => {
            scrollTrigger()
            if (!theme.detect.isIE11) window.dispatchEvent(new Event('resize'))
          }, 100)
        },
      })
    }
  }, [reset])

  return (
    <div
      ref={$root}
      className={classNames({
        [classes.root]: true,
        [classes.cols]: true,
        revealGrid__root: true,
        [className]: className,
      })}
    >
      {children}
    </div>
  )
}

RevealGrid.propTypes = {
  children: oneOfType([
    array,
    object,
  ]).isRequired,
  className: string,
  cols: number,
  init: bool,
  reset: bool,
  singleRow: bool,
  spaceBetween: number,
}

RevealGrid.defaultProps = {
  className: '',
  cols: 3,
  init: true,
  reset: false,
  singleRow: false,
  spaceBetween: 30,
}

export default RevealGrid
