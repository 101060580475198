const style = (theme) => {
  const output = {
    root: {
      position: 'relative',
      zIndex: 1,
      display: 'flex',
      justifyContent: 'center',
    },
    button: {
      pointerEvents: 'all',
      width: 45,
      height: 45,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      border: 'none',
      padding: 0,
      borderRadius: '50%',
      background: theme.colors[1],
      cursor: 'pointer',
      transform: 'translateY(-50%)',
      transition: 'opacity .2s ease-out',
      '@media (hover: hover)': {
        '&:hover': {
          opacity: 0.8,
        },
      },
      '& svg': {
        width: 15,
        height: 15,
        fill: theme.colors[0],
      },
    },
    arrow: {
      position: 'absolute',
      zIndex: 2,
      top: '50%',
    },
    prev: {
      // left: -100,
      left: 0,
      '& svg': {
        transform: 'translateX(2px)',
      },
    },
    next: {
      // right: -100,
      right: 0,
      '& svg': {
        transform: 'translateX(-2px)',
      },
    },
    images: {
      position: 'relative',
      zIndex: 1,
      height: 0,
      paddingBottom: '50%',
      width: '80%',
      overflow: 'hidden',
      userSelect: 'none',
      touchAction: 'pan-y',
      cursor: 'grab',
    },
    isDragging: {
      cursor: 'grabbing',
    },
    image: {
      pointerEvents: 'none',
      position: 'absolute',
      top: 0,
      left: 0,
      height: '100%',
      width: '100%',
      zIndex: 1,
      opacity: 0,
      transition: `opacity 0.5s ${theme.easings['power3.out']}`,
      '& img': {
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
        zIndex: 1,
        objectFit: 'contain',
      },
    },
  }

  /*------------------------------
  MD
  ------------------------------*/
  output[theme.mediaInverse.md] = {
    button: {
      bottom: 0,
      top: 'auto',
      transform: 'translate(-50%)',
      width: 35,
      height: 35,
      '& svg': {
        width: 13,
        height: 13,
        fill: theme.colors[0],
      },
    },
    prev: {
      left: 'calc(50% - 25px)',
      bottom: -55,
    },
    next: {
      left: 'calc(50% + 25px)',
      bottom: -55,
    },
    images: {
      paddingBottom: '70%',
      width: '100%',
    },
  }

  return output
}

export default style
