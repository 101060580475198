import { mainAxios as axios } from '@/base/axios'
import { REQUEST_FORM, RECEIVE_FORM, SEND_CONTACT_FORM, SENDED_CONTACT_FORM } from '@/actions/types'
import { API } from '@/constants'
import { convertJsontoUrlencoded, convertJsontoFormData } from '@/utils/form'

const requestForm = () => ({ type: REQUEST_FORM })

const receiveForm = (data, key) => ({
  type: RECEIVE_FORM,
  payload: data,
  key,
})

const fetchForm = (id) => async (dispatch) => {
  dispatch(requestForm())
  const response = await axios.get(`${API.CF7_GET}/${id}?&lang=it`)
  dispatch(receiveForm(response.data.form, id))
}

const sendContactForm = (id, data, isMultipart) => async (dispatch) => {
  dispatch({ type: SEND_CONTACT_FORM })
  const params = { headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8' } }
  if (isMultipart) params.headers = { 'Content-Type': 'multipart/form-data' }
  const response = await axios.post(
    `${API.CF7_FEEDBACK}/${id}/feedback`,
    isMultipart ? convertJsontoFormData(data) : convertJsontoUrlencoded(data),
    params,
  )
  dispatch({ type: SENDED_CONTACT_FORM })
  return response
}

export {
  fetchForm,
  sendContactForm,
}
