import { useState } from 'react'
import { string, number, shape } from 'prop-types'
import { createUseStyles } from 'react-jss'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import DelayLink from '@/components/DelayLink'
import BtnLink from '@/components/BtnLink'
import useMediaQuery from '@/hooks/useMediaQuery'
import { decodeEntities } from '@/utils/decodeEntities'
import style from './style'

const useStyles = createUseStyles(style)

const NewsItem = ({
  item,
  className,
}) => {
  const classes = useStyles()
  const [hover, setHover] = useState(false)
  const mediaQueryKey = useMediaQuery()
  const strings = useSelector((state) => state.options.strings)

  const handleMouseMove = (e) => {
    if (e.type === 'mouseenter' && !hover && mediaQueryKey > 1) setHover(true)
    if (e.type === 'mouseleave' && hover && mediaQueryKey > 1) setHover(false)
  }

  if (item.description?.length > 120) {
    item.description = item.description.substring(0, 120).trim()
    item.description += '...'
  }

  return (
    <div
      className={classNames({
        [classes.root]: true,
        productItem__root: true,
        [classes.hover]: hover,
        productItem__hover: hover,
        [className]: className,
      })}
    >
      <div className={classes.wrapper}>
        <div className={`${classes.title} productItem__title`}>
          <DelayLink
            to={item.link}
            onMouseEnter={(e) => handleMouseMove(e)}
            onMouseLeave={(e) => handleMouseMove(e)}
          >
            {item.title && (
            <p>
              {decodeEntities(item.title)}
            </p>
            )}
          </DelayLink>
        </div>
        <div
          className={`${classes.cover} productItem__cover`}
          onMouseEnter={(e) => handleMouseMove(e)}
          onMouseLeave={(e) => handleMouseMove(e)}
        >
          <DelayLink to={item.link}>
            <div className={`${classes.image} productItem__image`}>
              {item.image && (
                <img
                  src={item.image}
                  alt={item.title}
                  className={classes.img}
                />
              )}
            </div>
          </DelayLink>
        </div>
        {item.description && (
          <div
            className={`${classes.description} productItem__category`}
            dangerouslySetInnerHTML={{ __html: item.description }}
          />
        )}
        <div className={`${classes.control} productItem__control`}>
          {item.link && (
            <BtnLink
              link={item.link}
              label={strings.read_more_label || 'Scopri'}
              color="white"
            />
          )}
        </div>
      </div>
    </div>
  )
}

NewsItem.propTypes = {
  item: shape({
    id: number.isRequired,
    title: string.isRequired,
    description: string.isRequired,
    link: string.isRequired,
  }).isRequired,
}

export default NewsItem
