const style = (theme) => {
  const output = {
    root: {
      position: 'fixed',
      right: 0,
      bottom: 0,
      zIndex: theme.zindex.cookiebar,
      paddingBottom: '1.5vh',
      paddingRight: '4.5vw',
    },
    banner: {
      background: 'rgba(0, 0, 0, .7)',
      padding: '4px 11px 4px 15px',
      borderRadius: 10,
      maxWidth: 600,
    },
    content: {
      position: 'relative',
      fontSize: 12,
      padding: '8px 10px',
      paddingRight: 85,
      lineHeight: '1.1',
    },
    message: {
      lineHeight: 1.3,
      color: theme.colors[4],
      display: 'inline-block',
      paddingRight: 20,
      '& a': {
        textDecoration: 'underline',
        color: theme.colors[4],
        display: 'block',
      },
      '& p': {
        margin: 0,
      },
    },
    close: {
      position: 'absolute',
      top: 2,
      right: 4,
      background: theme.colors[4],
      color: theme.colors[0],
      fontSize: '11px',
      textTransform: 'uppercase',
      padding: '5px 10px',
      borderRadius: 5,
      margin: '5px 0px 5px 10px',
      cursor: 'pointer',
    },
  }

  /*------------------------------
  SM
  ------------------------------*/
  output[theme.mediaInverse.sm] = {
    root: {
      paddingLeft: 0,
      paddingRight: 0,
      width: '100%',
      textAlign: 'center',
    },
    banner: {
      paddingLeft: 14,
      paddingRight: 14,
      // borderRadius: '20px',
      margin: '0 10px',
      background: 'rgba(0, 0, 0, .9)',
    },
    content: {
      paddingLeft: 0,
      paddingRight: 0,
    },
    message: {
      display: 'block',
      paddingRight: 0,
      color: theme.getRgba(theme.colors[4], 0.8),
    },
    close: {
      position: 'static',
      margin: '10px auto 0',
    },
  }

  return output
}

export default style
