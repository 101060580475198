const style = (theme) => {
  const output = {
    root: {
      position: 'fixed',
      top: 0,
      right: 0,
      width: '100vw',
      height: 'var(--vh)',
      /* display: 'flex',
      flexDirection: 'column',opalina
      justifyContent: 'flex-end', */
      zIndex: theme.zindex.mainNav,
      overflowX: 'hidden',
      overflowY: 'hidden',
      pointerEvents: 'none',
    },
    open: {
      overflowY: 'auto',
    },
    opalina: {
      backgroundColor: theme.colors[2],
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: 'var(--vh)',
      zIndex: theme.zindex.mainNav,
      pointerEvents: 'all',
    },
    container: {
      height: '100%',
      minHeight: 670,
      background: theme.colors[4],
      width: '40vw',
      position: 'absolute',
      top: 0,
      right: 0,
      zIndex: theme.zindex.mainNav + 1,
    },
    wrapper: {
      paddingTop: (props) => props.headerHeight,
      height: '100%',
      position: 'relative',
      margin: 0,
      color: theme.colors[0],
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
    },
    wrapperMain: {
      height: '100%',
    },
    wrapperMainNav: {
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      flex: 1,
      width: '100%',
      height: '100%',
      // display: 'table',
    },
    wrapperTableNav: {
      height: '100%',
      // display: 'table-cell',
      position: 'relative',
      // verticalAlign: 'middle',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      '& > *:not(:last-child)': {
        // borderBottom: `1px solid ${theme.getRgba(theme.colors[0], 0.12)}`,
      },
    },
    nav: {
      textAlign: 'center',
      paddingTop: 60,
      paddingBottom: 60,
      paddingLeft: '2.7vw',
      paddingRight: '2.7vw',
      fontSize: 14,
      '& li.special': {
        '& a': {
          fontFamily: theme.fonts[1],
          color: theme.colors[0],
        },
      },
      '& a': {
        position: 'relative',
        fontFamily: theme.fonts[1],
        color: theme.colors[0],
        '&:after': {
          content: '""',
          bottom: 0,
          display: 'block',
          height: 2,
          left: 0,
          position: 'absolute',
          background: 'currentColor',
          transition: 'width 0.4s ease 0s',
          width: 0,
        },
        '&:hover': {
          '&:after': {
            width: '100%',
          },
        },
      },
    },
    mainNav: {
      fontSize: 60,
      paddingBottom: 0,
      paddingTop: 0,
    },
    extraNav: {
      fontSize: 30,
      '& a': {
        '&:after': {
          height: 1,
        },
      },
      '& li': {
        '&:not(:last-child)': {
          marginBottom: 5,
        },
      },
    },
    socialNav: {
      extend: theme.wrapper,
      position: 'absolute',
      bottom: 0,
      left: '50%',
      transform: 'translateX(-50%)',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
      width: '100%',
      paddingTop: 60,
      paddingBottom: 60,
      '& a': {
        '&:after': {
          display: 'none',
        },
      },
    },
    active: {
      pointerEvents: 'none',
      cursor: 'default',
      '&:after': {
        width: '100% !important',
      },
    },
    visible: {
      opacity: 1,
    },
    containerSocial: {
      display: 'flex',
      '& a': {
        color: theme.colors[0],
        '& svg': {
          fill: theme.colors[0],
          height: 18,
          width: 18,
          marginLeft: 18,
        },
      },
    },
    containerCopy: {
      display: 'flex',
      '& p': {
        margin: 0,
        marginLeft: 70,
      },
    },
  }

  output[theme.mediaInverse.md] = {
    /* root: {
      width: '30vw',
    }, */
    container: {
      width: '100vw',
    },
    wrapper: {
      paddingTop: (props) => 0,
    },
  }

  output[theme.mediaInverse.sm] = {
    root: {
      top: () => 0,
      paddingTop: () => 0,
      right: 0,
      width: '100vw',
      maxHeight: 'unset',
      fallback: {
        height: 'var(--vh)',
      },
      borderRadius: 0,
    },
    nav: {
      padding: 50,
      fontSize: 20,
    },
    mainNav: {
      fontSize: 32,
      paddingBottom: 0,
      paddingTop: 0,
    },
    extraNav: {
      paddingBottom: 0,
    },
    socialNav: {
      extend: theme.wrapper,
      fontSize: 12,
      paddingTop: 20,
      paddingBottom: 25,
    },

  }

  return output
}

export default style
