const global = (theme) => {
  const output = {
    root: {},
    '@global': {
      body: {
        extend: theme.typography.general,
        color: theme.colors[0],
        background: theme.colors[0],
        overflowY: theme.detect.isNativeScroll || theme.detect.isIE11 ? 'visible' : 'hidden',
        overflowX: 'hidden',
        '& a': {
          textDecoration: 'none',
          transition: 'color .3s ease-in-out',
        },
        '& button': {
          fontSize: 16,
          fontFamily: theme.fonts[1],
          padding: 0,
        },
        '& input': {
          fontSize: 16,
          fontFamily: theme.fonts[1],
          padding: 0,
        },
        '& h1, & h2, & h3, & h4': {
          fontWeight: 'normal',
          margin: 0,
          padding: 0,
        },
      },
      '::selection': {
        background: theme.colors[1],
        color: theme.colors[0],
      },
      '::-moz-selection': {
        background: theme.colors[1],
        color: theme.colors[0],
      },
    },
  }

  return output
}

export default global
