import { useRef, useState, useEffect, useCallback, useContext } from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import wpautop from 'wpautop'
import { useLocation } from 'react-router-dom'
import { createUseStyles } from 'react-jss'
import { Context as GeneralContext } from '@/context'
import { Context as LoadingContext } from '@/context/loading'
import LocomotiveScroll from '@/components/LocomotiveScroll'
import RevealFadeIn from '@/components/RevealFadeIn'
import Meta from '@/components/Meta'
import Footer from '@/components/Footer'
import { getSlug } from '@/utils/path'
import * as contentActions from '@/actions/content'
import style from './style'

const useStyles = createUseStyles(style)

const SimplePage = () => {
  const { headerHeight, setPageAnimationReady, pageAnimationReady } = useContext(GeneralContext)
  const { setReady, setSiteLoaded } = useContext(LoadingContext)
  const classes = useStyles({ headerHeight })
  const [load, setLoad] = useState(false)
  const $root = useRef()
  const location = useLocation()
  const stub = useRef(getSlug(location.pathname))

  /*------------------------------
  Redux Connect
  ------------------------------*/
  const { page } = useSelector((state) => ({
    page: state.content.pages[stub.current] || {},
  }), shallowEqual)

  /*------------------------------
  Redux Actions
  ------------------------------*/
  const dispatch = useDispatch()
  const fetchPage = useCallback((slug) => dispatch(contentActions.fetchPage(slug)), [dispatch])

  /*------------------------------
  Complete Preload
  ------------------------------*/
  const completePreload = () => {
    setReady(true)
    setSiteLoaded(true)
    setLoad(true)
  }

  /*------------------------------
  Preload Media when there are Data of Page in Redux
  ------------------------------*/
  useEffect(() => {
    if (Object.keys(page).length > 0) completePreload()
  }, [page])

  /*------------------------------
  Initialize
  ------------------------------*/
  const initialize = () => {
    fetchPage(stub.current)
  }

  /*------------------------------
  Did Mount
  ------------------------------*/
  useEffect(() => {
    if (!load) initialize()
  }, [])

  /*------------------------------
  Activate Page Animation
  ------------------------------*/
  useEffect(() => {
    if (load) setPageAnimationReady(true)
  }, [load])

  /*------------------------------
  Render Helmet
  ------------------------------*/
  const renderHelmet = () => {
    return load && (
      <Meta
        title={page.yoast_meta.find((y) => y.property?.includes('title')).content || page.title.rendered}
        meta={page.yoast_meta}
        schema={page.yoast_json_ld}
      />
    )
  }

  /*------------------------------
  Render Hero
  ------------------------------*/
  const renderCoverHome = () => {
    return (
      <RevealFadeIn
        className={classes.page}
        visible={pageAnimationReady}
      >
        <h1 className={classes.pageTitle}>{page.title.rendered}</h1>
        <div
          className={classes.pageContent}
          dangerouslySetInnerHTML={{ __html: wpautop(page.content.rendered) }}
        />
      </RevealFadeIn>
    )
  }

  /*------------------------------
  Render Footer
  ------------------------------*/
  const renderFooter = () => <Footer />

  /*------------------------------
  Render Content
  ------------------------------*/
  const renderContent = () => {
    return load && (
      <>
        {renderCoverHome()}
        {renderFooter()}
      </>
    )
  }

  return (
    <LocomotiveScroll
      init={load}
      className={`page pageSimple ${classes.root}`}
      ref={$root}
    >
      {renderHelmet()}
      {renderContent()}
    </LocomotiveScroll>
  )
}

export default SimplePage
