import constants from '@/constants'

const pushToDataLayer = (data) => {
  (global.dataLayer || []).push({ ...data })
}

export default () => {
  const {
    COOKIE_CONSENT,
    FORM_SENDED,
  } = constants.EVENTS.DATALAYER
  const { EMITTER } = constants

  EMITTER.on(COOKIE_CONSENT, () => {
    pushToDataLayer({
      event: 'cookie-consent',
    })
  })

  EMITTER.on(FORM_SENDED, () => {
    pushToDataLayer({
      event: 'form-sended',
    })
  })
}
