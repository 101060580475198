const style = (theme) => {
  const output = {
    root: {
      paddingBottom: 80,
      userSelect: 'none',
      height: '50vh',
      cursor: 'grab',
      position: 'relative',
      // overflow: 'hidden',
      zIndex: 1,
    },
    down: {
      cursor: 'grabbing',
    },
    carousel: {
      height: '100%',
      width: '100%',
      margin: [0, 'auto'],
      position: 'relative',
      zIndex: 1,
      touchAction: 'pan-x',
    },
    wrapper: {
      display: 'flex',
      height: '100%',
    },
    container: {
      width: '30%',
      flexShrink: 0,
      '& h3': {
        fontFamily: theme.fonts[0],
        fontSize: 11,
        letterSpacing: 1,
        textTransform: 'uppercase',
        color: theme.colors[4],
        // marginTop: 20,
        padding: [0, 30],
      },
      '& h2': {
        fontSize: 30,
        textTransform: 'uppercase',
        color: theme.colors[4],
        marginTop: 10,
        padding: [0, 30],
        textAlign: 'left',
      },
    },
    image: {
      position: 'relative',
      width: '100%',
      // height: '100%',
      paddingTop: '100%',

      '& img': {
        height: '100%',
        width: '100%',
        pointerEvents: 'none',
        // opacity: 0.3,
        transition: 'all .7s ease-out',
        position: 'absolute',
        top: 0,
        left: 0,
        padding: 30,
      },
    },
    active: {
      '& img': {
        opacity: 1,
      },
    },
    indicator: {
      marginRight: 30,
      fontSize: 11,
      color: theme.colors[4],
      fontFamily: theme.fonts[0],
      letterSpacing: 2,
      transform: 'translateY(2px)',
    },
    controllers: {
      position: 'absolute',
      bottom: -50,
      left: 0,
      right: 0,
      margin: [0, 'auto'],
      zIndex: 1,
      display: 'flex',
      alignItems: 'center',
      padding: [12, 0],
    },
    barWrapper: {
      height: 2,
      position: 'relative',
      zIndex: 1,
      flex: '1 1 auto',
      marginRight: 30,
      background: theme.colors[5],
    },
    bar: {
      position: 'absolute',
      zIndex: 1,
      top: 0,
      left: 0,
      width: 0,
      height: '100%',
      background: theme.colors[4],
      transformOrigin: '0 0',
    },
    buttons: {
      display: 'flex',
    },
    button: {
      // background: 'black',
      color: '#fff',
      display: 'flex',
      width: 11,
      height: 11,
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: 10,
      border: 'none',
      borderRadius: '50%',
      marginLeft: 10,
      cursor: 'pointer',
      '& svg': {
        fill: theme.colors[4],
        width: 11,
        height: 11,
      },
    },
  }

  /*------------------------------
  SM
  ------------------------------*/
  output[theme.mediaInverse.sm] = {
    container: {
      width: '80%',
      flexShrink: 0,
    },
  }

  return output
}

export default style
