import classNames from 'classnames'
import { string } from 'prop-types'
import injectSheet from 'react-jss'
import DelayLink from '@/components/DelayLink'
import style from './style'

const BtnLink = ({
  link,
  label,
  classes,
  className,
  color,
}) => {
  return (
    <DelayLink
      to={link}
      className={classNames({
        [classes.root]: true,
        [classes.rootWhite]: color === 'white',
        [classes.rootGrey]: color === 'grey',
        [className]: className,
      })}
    >
      {label && <span className={classes.label}>{label}</span>}
      <svg><use xlinkHref="#arrow" /></svg>
    </DelayLink>
  )
}

BtnLink.defaultProps = {
  label: '',
  link: '#',
  color: 'white',
}

BtnLink.propTypes = {
  color: string,
  label: string,
  link: string,
}

export default injectSheet(style)(BtnLink)
