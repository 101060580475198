import { mainAxios as axios } from '@/base/axios'
import { REQUEST_ROUTING, RECEIVE_ROUTING } from '@/actions/types'
import { API } from '@/constants'

const requestRouting = () => ({ type: REQUEST_ROUTING })

const receiveRouting = (data) => ({
  type: RECEIVE_ROUTING,
  payload: data,
})

const fetchRouting = () => async (dispatch, getState) => {
  const { currentLanguage } = getState().locale
  dispatch(requestRouting())
  const response = await axios.get(`${API.ROUTING}?lang=${currentLanguage}`)
  dispatch(receiveRouting(response.data))
}

export {
  fetchRouting,
  receiveRouting,
}
