import classNames from 'classnames'
import { useRef, useState, useEffect, useCallback, useContext } from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { createUseStyles } from 'react-jss'
import { useParams } from 'react-router-dom'
import CreateJs from 'preload-js'
import { Context as GeneralContext } from '@/context'
import { Context as LoadingContext } from '@/context/loading'
import LocomotiveScroll from '@/components/LocomotiveScroll'
import FeaturedNews from '@/components/FeaturedNews'
import Meta from '@/components/Meta'
import Hero from '@/components/Hero'
import RevealText from '@/components/RevealText'
import InViewTrigger from '@/components/InViewTrigger'
import Footer from '@/components/Footer'
import PageElements from '@/components/PageElements'
import useHandleAllMediaWithCb from '@/hooks/useHandleAllMediaWithCb'
import * as contentActions from '@/actions/content'
import style from './style'

const useStyles = createUseStyles(style)

const GenericCpt = ({
  cptFromProps,
}) => {
  const { setPageAnimationReady, headerHeight, setBackButtonVisible } = useContext(GeneralContext)
  const { setReady, setSiteLoaded } = useContext(LoadingContext)
  const classes = useStyles({ headerHeight })
  const [load, setLoad] = useState(false)
  const preload = useRef(new CreateJs.LoadQueue())
  const { stub } = useParams()
  const $root = useRef()

  /*------------------------------
  Executes a callback on loading each image
  ------------------------------*/
  useHandleAllMediaWithCb({
    init: load,
    ref: $root.current?.ref,
  })

  /*------------------------------
  Redux Connect
  ------------------------------*/
  const { page, relatedProjects, taxonomiesList } = useSelector((state) => ({
    page: state.content.cpt[cptFromProps]?.find((item) => item.slug === stub) || {},
    relatedProjects: state.content.cpt[cptFromProps]?.find((item) => item.slug === stub)?.acf.related_projects || {},
    taxonomiesList: state.content.taxonomies._cta_category || {}, // eslint-disable-line
  }), shallowEqual)

  /*------------------------------
  Redux Actions
  ------------------------------*/
  const dispatch = useDispatch()
  const fetchCpt = useCallback((cpt, slug) => dispatch(contentActions.fetchCpt(cpt, slug)), [dispatch])
  const fetchTaxonomy = useCallback((tax, save) => dispatch(contentActions.fetchTaxonomy(tax, save)), [dispatch])

  /*------------------------------
  Complete Preload
  ------------------------------*/
  const completePreload = () => {
    setReady(true)
    setSiteLoaded(true)
    setLoad(true)
  }

  /*------------------------------
  Preload Media
  ------------------------------*/
  const preloadMedia = () => {
    preload.current.on('complete', completePreload)
    if (page.featured_image) preload.current.loadFile({ src: page.featured_image })
  }

  /*------------------------------
  Preload Media when there are Data of page in Redux
  ------------------------------*/
  useEffect(() => {
    if (Object.keys(page).length > 0) preloadMedia()
  }, [page])

  /*------------------------------
  Fetch all data
  ------------------------------*/
  const fetchData = (slugPage) => {
    fetchCpt(cptFromProps, slugPage)
    return false
  }

  /*------------------------------
  Initialize
  ------------------------------*/
  const initialize = () => {
    fetchData(stub)
    fetchTaxonomy('_cta_category', true)
  }

  /*------------------------------
  Did Mount
  ------------------------------*/
  useEffect(() => {
    if (!load) initialize()
    setBackButtonVisible(true)
    return () => {
      setBackButtonVisible(false)
    }
  }, [])

  /*------------------------------
  Activate Page Animation
  ------------------------------*/
  useEffect(() => {
    if (load) setPageAnimationReady(true)
  }, [load])

  /*------------------------------
  Render Helmet
  ------------------------------*/
  const renderHelmet = () => {
    return load && (
      <Meta
        title={page.title.rendered}
        meta={page.yoast_meta}
        schema={page.yoast_json_ld}
      />
    )
  }

  /*------------------------------
  Render Hero
  ------------------------------*/
  const renderHero = () => {
    return Object.keys(page).length > 0 && (
      <Hero
        image={page.featured_image}
        // imageMobile={page.acf.featured_image_mobile.url}
        title={page.title.rendered}
        text={page.content.rendered}
        aboveTheFold={true}
        verticalCentered={false}
        data={page.acf.data_rp ? page.acf.data_rp : ''}
        luogo={page.acf.luogo_rp ? page.acf.luogo_rp : ''}
        categoria={page._cta_category ? taxonomiesList.find((p) => p.id === page._cta_category[0])?.name : ''} // eslint-disable-line
      />
    )
  }

  /*------------------------------
  Render Elements
  ------------------------------*/
  const renderElements = () => {
    return page.acf.page_builder && (
      <PageElements
        className={classes.pageElements}
        elements={page.acf.page_builder}
      />
    )
  }

  /*------------------------------
  Render FeaturedEvents
  ------------------------------*/
  const renderRelatedProjects = () => {
    return relatedProjects.length > 0 && (
      <div className={classNames({
        [classes.wrapper]: true,
        [classes.wrapperFeaturedEvents]: true,
      })}
      >
        {/* <div className={classes.featuredProductsHeading}>
          <span className={classes.pretitle}>{page.acf.pretitle_rp}</span>
        </div> */}
        {page.acf.pretitle_rp && (
        <InViewTrigger className={classes.featuredProductsHeading}>
          <RevealText
            tag="h2"
            type="lines"
            value={page.acf.pretitle_rp}
            className={classes.pretitle}
          />
        </InViewTrigger>
        )}
        <FeaturedNews
          items={relatedProjects.map((post) => ({
            id: post?.id,
            title: post.title?.rendered,
            description: post.content?.rendered,
            // date: post.acf.date,
            // place: post.acf.place,
            link: post?.link,
            image: post?.featured_image,
          }))}
        />
      </div>
    )
  }

  /*------------------------------
  Render Footer
  ------------------------------*/
  const renderFooter = () => <Footer />

  /*------------------------------
  Render Content
  ------------------------------*/
  const renderContent = () => {
    return load && (
      <>
        <div className={classes.page}>
          {renderHero()}
          {renderElements()}
          {renderRelatedProjects()}
        </div>
        {renderFooter()}
      </>
    )
  }

  return (
    <>
      <LocomotiveScroll
        init={load}
        className={`page pageGenericCpt ${classes.root}`}
        ref={$root}
      >
        {renderHelmet()}
        {renderContent()}
      </LocomotiveScroll>
    </>
  )
}

export default GenericCpt
