/* eslint-disable jsx-a11y/control-has-associated-label */
import { useCallback, useEffect, useContext, useRef, useLayoutEffect, useState } from 'react'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import { useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { createUseStyles } from 'react-jss'
import gsap from 'gsap'
import ally from 'ally.js'
import { Context } from '@/context'
import DelayLink from '@/components/DelayLink'
import usePrevious from '@/hooks/usePrevious'
import { getSlug } from '@/utils/path'
import { decodeEntities } from '@/utils/decodeEntities'
import * as layerActions from '@/actions/layer'
import style from './style'

const useStyles = createUseStyles(style)

const Nav = ({
  mainNav,
  extraNav,
}) => {
  const { headerHeight, headerRef, cookieRef } = useContext(Context)
  const $mainNav = useRef()
  const $extraNav = useRef()
  const $socialNav = useRef()
  const location = useLocation()

  /*------------------------------
  Redux Connect
  ------------------------------*/
  const { pathname, isMenuOpen, strings } = useSelector((state) => ({
    pathname: state.router.location.pathname,
    isMenuOpen: state.layer.layers.some((layer) => layer.id === 'menu' && layer.isOpen),
    strings: state.options.strings || {},
  }), shallowEqual)
  const prevPathname = usePrevious(pathname)
  const classes = useStyles({ headerHeight, isMenuOpen })

  /*------------------------------
  Redux Actions
  ------------------------------*/
  const dispatch = useDispatch()
  const closeMenu = useCallback(() => dispatch(layerActions.closeMenu()), [dispatch])

  const $root = useRef()
  const $opalina = useRef()
  const $container = useRef()
  const disableAllTabIndex = useRef(null)

  const [ready, setReady] = useState(false)

  useEffect(() => {
    if (mainNav?.length && extraNav?.length) setReady(true)
  }, [mainNav, extraNav])

  /*------------------------------
  Initialize
  ------------------------------*/
  const init = () => {
    // gsap.set([$root.current, $mainNav.current, $extraNav.current, $socialNav.current], {
    //   autoAlpha: 0,
    // })
    gsap.set($opalina.current, {
      autoAlpha: 0,
    })
    gsap.set($container.current, {
      x: '100%',
    })
    gsap.set([$mainNav.current, $extraNav.current, $socialNav.current], {
      autoAlpha: 0,
      // y: 20,
    })
  }

  const handleKeyDown = (e) => {
    if (e.key === 'Escape' && e.keyCode === 27 && isMenuOpen) closeMenu()
  }

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)
    return () => window.removeEventListener('keydown', handleKeyDown)
  }, [isMenuOpen])

  const openMainMenu = () => {
    gsap.killTweensOf([$container.current, $mainNav.current, $extraNav.current, $socialNav.current])
    $root.current.style.pointerEvents = 'all'
    const tl = gsap.timeline()
    tl
      .to($container.current, {
        duration: 0.6,
        x: '0%',
        ease: 'power3.inOut',
      }, 0)
      .to($opalina.current, {
        duration: 0.4,
        autoAlpha: 0.4,
        ease: 'power3.out',
      }, 0.4)
      .to([$mainNav.current, $extraNav.current, $socialNav.current], {
        duration: 1,
        autoAlpha: 1,
        // y: 0,
        stagger: 0.15,
      }, 0.45)
  }

  const closeMainMenu = () => {
    gsap.killTweensOf([$container.current, $mainNav.current, $extraNav.current, $socialNav.current])
    $root.current.style.pointerEvents = 'none'
    gsap.to($opalina.current, {
      duration: 0.5,
      autoAlpha: 0,
      ease: 'power3.inOut',
    })
    gsap.to($container.current, {
      duration: 0.5,
      x: '100%',
      ease: 'power3.inOut',
    })
    gsap.to([$mainNav.current, $extraNav.current, $socialNav.current], {
      duration: 0.5,
      autoAlpha: 0,
      // y: 20,
    })
  }

  /*------------------------------
  Init
  ------------------------------*/
  useEffect(() => {
    if (ready) init()
  }, [ready])

  /*------------------------------
  Manage Accessibility
  ------------------------------*/
  useEffect(() => {
    if (isMenuOpen) {
      disableAllTabIndex.current = ally.maintain.disabled({
        filter: [$root.current, headerRef.current, ...cookieRef.current ? [cookieRef.current] : []],
      })
    }
    if (!isMenuOpen && disableAllTabIndex.current !== null) disableAllTabIndex.current.disengage()
  }, [isMenuOpen])

  /*------------------------------
  Close Nav when isMenuOpen changed
  ------------------------------*/
  const prevIsOpen = usePrevious(isMenuOpen)
  useEffect(() => {
    if (prevIsOpen !== undefined && isMenuOpen) openMainMenu()
    if (prevIsOpen !== undefined && !isMenuOpen) closeMainMenu()
  }, [isMenuOpen])

  /*------------------------------
  Close Nav on change page
  ------------------------------*/
  useLayoutEffect(() => {
    if (prevPathname !== pathname) {
      setTimeout(() => {
        closeMenu()
      }, 500)
    }
  }, [pathname])

  const renderMainNav = () => {
    return (
      <nav className={`${classes.nav} ${classes.mainNav}`}>
        <ul ref={$mainNav}>
          {mainNav.map((item) => (
            <li
              className={item.classes.join(' ')}
              key={item.ID}
            >
              <DelayLink
                className={classNames({
                  [classes.active]: getSlug(item.url) === getSlug(location.pathname),
                })}
                to={item.url}
              >
                {decodeEntities(item.title)}
              </DelayLink>
            </li>
          ))}
        </ul>
      </nav>
    )
  }

  const renderExtraNav = () => {
    return (
      <nav className={`${classes.nav} ${classes.extraNav}`}>
        <ul ref={$extraNav}>
          {extraNav.map((item) => (
            <li
              className={`${item.classes.join(' ')}`}
              key={item.ID}
            >
              <DelayLink
                className={classNames({
                  [classes.active]: getSlug(item.url) === getSlug(location.pathname),
                })}
                to={item.url}
              >
                {decodeEntities(item.title)}
              </DelayLink>
            </li>
          ))}
        </ul>
      </nav>
    )
  }

  const renderSocialNav = () => {
    return (
      <nav className={`${classes.nav} ${classes.socialNav}`} ref={$socialNav}>
        <div className={classes.containerCopy}>
          <DelayLink
            to={strings.cookie}
          >
            {strings.cookie_label}
          </DelayLink>
          <div>&nbsp;</div>
          -
          <div>&nbsp;</div>
          <DelayLink
            to={strings.privacy}
          >
            {strings.privacy_label}
          </DelayLink>
        </div>
        <div className={classes.containerSocial}>
          { strings.facebook && (
          <DelayLink
            to={strings.facebook}
          >
            {strings.label_facebook}
          </DelayLink>
          )}
          { strings.twitter && (
            <DelayLink
              to={strings.twitter}
            >
              {strings.label_twitter}
            </DelayLink>
          )}
          { strings.instagram && (
            <DelayLink
              to={strings.instagram}
            >
              {strings.label_instagram}
            </DelayLink>
          )}
        </div>
      </nav>
    )
  }

  return ready && (
    <div
      className={classNames({
        [classes.root]: true,
        [classes.open]: isMenuOpen,
      })}
      ref={$root}
      role="dialog"
      aria-modal="true"
    >
      <button
        ref={$opalina}
        className={classes.opalina}
        onClick={closeMenu}
      />
      <div className={classes.container} ref={$container}>
        <div className={classes.wrapper}>
          <div className={classes.wrapperMain}>
            <div className={classes.wrapperMainNav}>
              <div className={classes.wrapperTableNav}>
                {renderMainNav()}
                {renderExtraNav()}
                {renderSocialNav()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Nav.propTypes = {
  mainNav: PropTypes.array,
  extraNav: PropTypes.array,
}

Nav.defaultProps = {
  mainNav: [],
  extraNav: [],
}

export default Nav
