import { useRef, useState, useEffect, useCallback, useContext } from 'react'
import { useSelector, useDispatch, shallowEqual } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { createUseStyles } from 'react-jss'
import CreateJs from 'preload-js'
import { Context as GeneralContext } from '@/context'
import { Context as LoadingContext } from '@/context/loading'
import LocomotiveScroll from '@/components/LocomotiveScroll'
import CarouselTeam from '@/components/CarouselTeam'
import Meta from '@/components/Meta'
import Footer from '@/components/Footer'
import RevealText from '@/components/RevealText'
import useHandleAllMediaWithCb from '@/hooks/useHandleAllMediaWithCb'
import * as contentActions from '@/actions/content'
import { getSlug } from '@/utils/path'
import style from './style'

const useStyles = createUseStyles(style)

const Team = () => {
  const { setPageAnimationReady, pageAnimationReady, headerHeight } = useContext(GeneralContext)
  const { setReady, setSiteLoaded } = useContext(LoadingContext)
  const classes = useStyles({ headerHeight })
  const [load, setLoad] = useState(false)
  const preload = useRef(new CreateJs.LoadQueue())
  const location = useLocation()
  const stub = useRef(getSlug(location.pathname))
  const $root = useRef()

  /*------------------------------
  Executes a callback on loading each image
  ------------------------------*/
  useHandleAllMediaWithCb({
    init: load,
    ref: $root.current?.ref,
  })

  /*------------------------------
  Redux Connect
  ------------------------------*/
  const { page, teamList } = useSelector((state) => ({
    page: state.content.pages[stub.current] || {},
    teamList: state.content.cpt._cty_team || [], // eslint-disable-line
  }), shallowEqual)

  /*------------------------------
  Redux Actions
  ------------------------------*/
  const dispatch = useDispatch()
  const fetchPage = useCallback((slug) => dispatch(contentActions.fetchPage(slug)), [dispatch])
  const fetchCpts = useCallback((cpt) => dispatch(contentActions.fetchCpts(cpt)), [dispatch])

  /*------------------------------
  Complete Preload
  ------------------------------*/
  const completePreload = () => {
    setReady(true)
    setSiteLoaded(true)
    setLoad(true)
  }

  /*------------------------------
  Activate Page Animation
  ------------------------------*/
  useEffect(() => {
    if (load) setPageAnimationReady(true)
  }, [load])

  /*------------------------------
  Preload Media
  ------------------------------*/
  const preloadMedia = () => {
    preload.current.on('complete', completePreload)
    teamList.map((item) => {
      preload.current.loadFile({ id: `img-${item.id}`, src: item.featured_image })
      return false
    })
  }

  /*------------------------------
  Preload Media when there are Data of Page in Redux
  ------------------------------*/
  useEffect(() => {
    if (Object.keys(page).length > 0 && teamList.length > 0) preloadMedia()
  }, [teamList, page])

  /*------------------------------
  Fetch all data
  ------------------------------*/
  const fetchData = (slugPage) => {
    if (Object.keys(page).length === 0) fetchPage(slugPage)
    return false
  }

  /*------------------------------
  Initialize
  ------------------------------*/
  const initialize = () => {
    fetchData(stub.current)
    fetchCpts('_cty_team')
  }

  /*------------------------------
  Did Mount
  ------------------------------*/
  useEffect(() => {
    if (!load) initialize()
  }, [])

  /*------------------------------
  Render Helmet
  ------------------------------*/
  const renderHelmet = () => {
    return load && (
      <Meta
        title={page.title.rendered}
        meta={page.yoast_meta}
        schema={page.yoast_json_ld}
      />
    )
  }

  /*------------------------------
  Render Heading
  ------------------------------*/
  const renderHeading = () => {
    return teamList.length > 0 && (
      <RevealText
        tag="h1"
        className={classes.title}
        type="lines"
        value={page.title.rendered}
        visible={pageAnimationReady}
      />
    )
  }

  /*------------------------------
  Render Heading
  ------------------------------*/
  const renderProducts = () => {
    return teamList.length > 0 && (
      <CarouselTeam
        items={teamList}
        className={classes.slider}
      />
    )
  }

  /*------------------------------
  Render Grid
  ------------------------------*/
  // const renderGrid = () => {
  //   return products.length > 0 && (
  //     <div className={`${classes.wrapper} ${classes.products}`}>
  //       <RevealGrid cols={3}>
  //         {products.map((product) => (
  //           <ProductItem
  //             key={product.id}
  //             item={product}
  //             shoppable
  //             onClick={() => handleAddToCart(product.id, 1)}
  //             onClickWishlist={() => addOrRemoveToLiked(product)}
  //             isFavourite={containsObject(product, wishlist, 'id')}
  //           />
  //         ))}
  //       </RevealGrid>
  //     </div>
  //   )
  // }

  /*------------------------------
  Render Footer
  ------------------------------*/
  const renderFooter = () => <Footer />

  /*------------------------------
  Render Content
  ------------------------------*/
  const renderContent = () => {
    return load && (
      <>
        <div className={classes.page}>
          {renderHeading()}
          {renderProducts()}
        </div>
        {renderFooter()}
      </>
    )
  }

  return (
    <LocomotiveScroll
      init={load}
      className={`page pageTeam ${classes.root}`}
      ref={$root}
    >
      {renderHelmet()}
      {renderContent()}
    </LocomotiveScroll>
  )
}

export default Team
