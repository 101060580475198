import classNames from 'classnames'
import injectSheet from 'react-jss'
import { useSelector, shallowEqual } from 'react-redux'
import DelayLink from '@/components/DelayLink'
import HTMLParse from 'react-html-parser'
import Logo from '@/components/Logo'
import style from './style'

const Footer = ({
  classes,
}) => {
  /*------------------------------
  Redux Connect
  ------------------------------*/
  const { strings } = useSelector((state) => ({
    strings: state.options.strings || {},
  }), shallowEqual)

  const renderFooter = () => {
    return (
      <div
        className={classNames({
          [classes.container]: true,
        })}
      >
        <div className={classes.containerLeft}>
          <Logo
            className={classes.logo}
          />
          <div
            className={classes.info}
          >
            {HTMLParse(strings.sede1)}
            Tel:
            {' '}
            <a href={`tel:${strings.telephone1}`}>{strings.telephone1}</a>
            <br />
            <br />

            {HTMLParse(strings.sede2)}
            Tel:
            {' '}
            <a href={`tel:${strings.telephone2}`}>{strings.telephone2}</a>
            <br />
            <br />

            Email:
            {' '}
            <a href={`mailto:${strings.email}`}>{strings.email}</a>
            <br />
            <br />

            P.IVA:
            {' '}
            {strings.piva}
          </div>
        </div>
        <div className={classes.containerRight}>
          <div className={classes.containerSocial}>
            { strings.facebook && (
            <DelayLink
              to={strings.facebook}
            >
              {strings.label_facebook}
            </DelayLink>
            )}
            { strings.twitter && (
            <DelayLink
              to={strings.twitter}
            >
              {strings.label_twitter}
            </DelayLink>
            )}
            { strings.instagram && (
            <DelayLink
              to={strings.instagram}
            >
              {strings.label_instagram}
            </DelayLink>
            )}
          </div>
          <div className={classes.containerCopy}>
            <p>
              <DelayLink
                to={strings.cookie}
              >
                {strings.cookie_label}
              </DelayLink>
              <span>&nbsp;</span>
              -
              <span>&nbsp;</span>
              <DelayLink
                to={strings.privacy}
              >
                {strings.privacy_label}
              </DelayLink>
            </p>
            <p>{strings.copyright}</p>
          </div>
        </div>
      </div>
    )
  }

  return (
    <footer
      className={classNames({
        [classes.root]: true,
        Footer__root: true,
      })}
    >
      {renderFooter()}
    </footer>
  )
}

Footer.defaultProps = {
  storeLocator: false,
}

export default injectSheet(style)(Footer)
