import content from '@/reducers/content'
import form from '@/reducers/form'
import layer from '@/reducers/layer'
import nav from '@/reducers/nav'
import options from '@/reducers/options'
import routing from '@/reducers/routing'
import locale from '@/reducers/locale'

export default {
  content,
  form,
  layer,
  nav,
  options,
  routing,
  locale,
}
