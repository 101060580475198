const style = (theme) => {
  const output = {
    root: {
      minHeight: '100vh',
      // paddingTop: (props) => (props.headerHeight),
      background: theme.colors[0],
    },
    page: {
      // minHeight: '100vh',
      '& h1': {
        extend: theme.smallWrapper,
        fontSize: 40,
        lineHeight: 48,
        marginBottom: 40,
        color: theme.colors[4],
        paddingTop: 200,
      },
    },
    smallWrapper: {
      extend: theme.smallWrapper,
      display: 'flex',
      justifyContent: 'space-between',
    },
    left: {
      flex: '0 0 50%',
      color: theme.colors[4],
      fontSize: 14,
      '& h1': {
        // textTransform: 'uppercase',
        fontSize: 40,
        lineHeight: 48,
        marginBottom: 20,
      },
      '& p': {
        fontFamily: theme.fonts[1],
        lineHeight: 0.4,
      },
      '& a': {
        color: theme.colors[4],
      },
    },
    right: {
      flex: '0 0 50%',
      marginTop: -60,
    },
    pretitle: {
      fontSize: 43,
      color: theme.colors[0],
    },
    address: {
      fontSize: 43,
    },
    iconsList: {
      marginTop: 50,
      '& > *:not(:last-child)': {
        marginBottom: 20,
      },
    },
    itemList: {
      display: 'flex',
      '& svg': {
        width: 15,
        height: 15,
        fill: theme.colors[0],
        marginTop: 5,
        marginRight: 15,
      },
      '& p': {
        fontSize: 16,
        color: theme.colors[0],
        marginBottom: 0,
        '& a': {
          color: theme.colors[0],
          transition: 'color 0.4s ease 0s',
          position: 'relative',
          '&:after': {
            content: '""',
            bottom: 0,
            display: 'block',
            height: 1,
            left: 0,
            position: 'absolute',
            background: 'currentColor',
            transition: 'width 0.4s ease 0s',
            width: 0,
          },
          '&:hover': {
            color: theme.colors[0],
            '&:after': {
              width: '100%',
            },
          },
        },
      },
    },
    footer: {
      extend: theme.wrapper,
      width: '100%',
      display: 'flex',
      justifyContent: 'space-between',
      position: 'absolute',
      left: 0,
      bottom: 0,
      paddingBottom: 30,
    },
    containerSocial: {
      display: 'flex',
      '& a': {
        color: theme.colors[4],
        fontSize: 14,
        '& svg': {
          fill: theme.colors[4],
          height: 18,
          width: 18,
          marginRight: 18,
        },
      },
    },
    containerCopy: {
      display: 'flex',
      color: theme.colors[4],
      fontSize: 14,
      '& p': {
        margin: 0,
        marginRight: 70,
      },
      '& a': {
        color: theme.colors[4],
      },
      '& > div': {
        display: 'flex',
      },
    },
  }

  /*------------------------------
  MD
  ------------------------------*/
  output[theme.mediaInverse.md] = {
    address: {
      fontSize: 30,
    },
  }

  /*------------------------------
  SM
  ------------------------------*/
  output[theme.mediaInverse.sm] = {
    page: {
      '& h1': {
        paddingTop: 200,
      },
    },
    smallWrapper: {
      flexDirection: 'column',
    },
    left: {
      flex: '0 0 100%',
      // paddingTop: 50,
    },
    right: {
      paddingTop: 50,
      flex: '0 0 100%',
    },
    footer: {
      extend: theme.wrapper,
      position: 'static',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      left: 0,
      bottom: 0,
      paddingBottom: 30,
    },
    containerSocial: {
      display: 'flex',
      order: 1,
      '& > *:last-child': {
        '& svg': {
          marginRight: 0,
        },
      },
    },
    containerCopy: {
      display: 'flex',
      flexDirection: 'column',
      order: 2,
      '& p': {
        marginRight: 0,
        // width: '100%',
        order: 2,
        marginTop: 10,
      },
      '& > div': {
        justifyContent: 'center',
        order: 1,
        marginTop: 10,
      },
    },
  }

  return output
}

export default style
