const SVGSprite = () => {
  return (
    <svg style={{ display: 'none' }}>
      <symbol id="ico-close" viewBox="0 0 20 20">
        <path d="M20 1.42L18.58 0 10 8.58 1.42 0 0 1.42 8.58 10 0 18.58 1.42 20 10 11.42 18.58 20 20 18.58 11.42 10z" />
      </symbol>
      <symbol id="ico-arrow-left" viewBox="0 0 11.314 11.314">
        <path data-name="Rettangolo 24" d="M5.657 11.314L0 5.657l.707-.707 5.657 5.657z" />
        <path data-name="Rettangolo 25" d="M0 5.657L5.657 0l.707.707L.707 6.364z" />
      </symbol>
      <symbol id="ico-arrow-right" viewBox="0 0 11.314 11.314">
        <path data-name="Rettangolo 24" d="M5.657 0l5.657 5.657-.707.707L4.95.707z" />
        <path data-name="Rettangolo 25" d="M11.314 5.657l-5.657 5.657-.707-.707 5.657-5.657z" />
      </symbol>
      <symbol id="ico-arrow-right-triangular" viewBox="0 0 24 24">
        <path d="M21 12L3 24V0z" />
      </symbol>
      <symbol id="ico-play" viewBox="0 0 31.5 36.37">
        <path d="M30.5 18.19l-15 8.66-15 8.66V.87l15 8.66 15 8.66z" />
      </symbol>
      <symbol id="ico-fullscreen" viewBox="0 0 17 17">
        <path d="M0 7V0h7v2H2v5zm10-7h7v7h-2V2h-5zm7 10v7h-7v-2h5v-5zM7 17H0v-7h2v5h5z" />
      </symbol>
      <symbol id="ico-search" viewBox="0 0 71.3 71.3">
        <path d="M71.3 65.6L56.1 50.4a31 31 0 006.5-19.1 31.3 31.3 0 10-31.3 31.3 31 31 0 0019.1-6.5l15.2 15.2zM8 31.3a23.3 23.3 0 1123.3 23.2A23.32 23.32 0 018 31.3z" />
      </symbol>
      <symbol id="ico-shopping-bag" viewBox="0 0 20 20">
        <path d="M18.4 3.32h-4.31a4.11 4.11 0 00-8.09 0H1.72V20H18.4zM10.06.91a3.22 3.22 0 013.1 2.41H7A3.23 3.23 0 0110.06.91zm7.43 18.18H2.63V4.23H6V6.1h.91V4.23h6.4V6.1h.91V4.23h3.32z" />
      </symbol>
      <symbol id="ico-cart" viewBox="0 0 19.028 17.419">
        <path d="M15.07 15.996a.949.949 0 11.95-.95.952.952 0 01-.95.95zm0-3.323a2.373 2.373 0 102.373 2.372 2.38 2.38 0 00-2.373-2.372z" />
        <path d="M16.044 10.229H6.077L4.748 4.651H17.42zm2.847-6.717a.744.744 0 00-.57-.284H4.391L3.751.546A.689.689 0 003.062 0H.713a.712.712 0 100 1.424h1.779l2.326 9.683a.687.687 0 00.688.546H16.59a.688.688 0 00.688-.546l1.732-7a.74.74 0 00-.12-.593z" />
        <path d="M6.455 15.996a.949.949 0 11.949-.95.953.953 0 01-.949.95zm0-3.323a2.373 2.373 0 102.372 2.372 2.38 2.38 0 00-2.372-2.372z" />
      </symbol>
      <symbol id="ico-heart" viewBox="0 0 14 14">
        <path d="M9.48 1.65A3.92 3.92 0 007 2.61a3.86 3.86 0 00-2.48-1A3.47 3.47 0 001 5v.07c0 2.36 2.05 4.19 4.9 6.7l.22.22a1.24 1.24 0 001.75 0l.23-.22C10.94 9.34 13 7.53 13 5.15a3.47 3.47 0 00-3.44-3.5h-.08z" />
      </symbol>
      <symbol id="circle-hover" viewBox="0 0 581.661 79.899" preserveAspectRatio="none">
        <path d="M328.323 6.532C234.464-.76 135.834-4.002 48.881 20.274c-14.443 4.032-28.729 8.947-39.339 16.528-5.1 3.645-9.456 8.444-7.981 13.295 1.645 5.407 9.769 8.787 17.434 11.058 21.386 6.335 44.814 9.052 68.074 11.1 66.226 5.841 133.242 6.738 200.062 6.317q82.131-.517 164.157-3.672c29.939-1.152 59.895-2.57 89.536-5.561 8.317-.839 26.553-3.28 32.8-6.31 8.458-3.588 5.65-8.46 3.83-10.031-6.061-5.237-21.513-11.354-33.652-14.249a853.427 853.427 0 00-10.79-2.5c-72.461-16.309-149.6-23.856-226.266-22.1" fill="none" stroke="#b3874b" strokeMiterlimit="10" strokeWidth="2.5" />
      </symbol>
      <symbol id="plus" viewBox="0 0 8 8">
        <path d="M3 0h2v8H3z" />
        <path d="M0 3h8v2H0z" />
      </symbol>
      <symbol id="ico-twitter" viewBox="0 0 512 512">
        <path
          transform="translate(0,50)"
          d="M512 97.248c-19.04 8.352-39.328 13.888-60.48 16.576 21.76-12.992 38.368-33.408 46.176-58.016-20.288 12.096-42.688 20.64-66.56 25.408C411.872 60.704 384.416 48 354.464 48c-58.112 0-104.896 47.168-104.896 104.992 0 8.32.704 16.32 2.432 23.936-87.264-4.256-164.48-46.08-216.352-109.792-9.056 15.712-14.368 33.696-14.368 53.056 0 36.352 18.72 68.576 46.624 87.232-16.864-.32-33.408-5.216-47.424-12.928v1.152c0 51.008 36.384 93.376 84.096 103.136-8.544 2.336-17.856 3.456-27.52 3.456-6.72 0-13.504-.384-19.872-1.792 13.6 41.568 52.192 72.128 98.08 73.12-35.712 27.936-81.056 44.768-130.144 44.768-8.608 0-16.864-.384-25.12-1.44C46.496 446.88 101.6 464 161.024 464c193.152 0 298.752-160 298.752-298.688 0-4.64-.16-9.12-.384-13.568 20.832-14.784 38.336-33.248 52.608-54.496z"
        />
      </symbol>
      <symbol id="ico-linkedin" viewBox="0 0 512 512">
        <path d="M0 160h114.496v352H0zM426.368 164.128c-1.216-.384-2.368-.8-3.648-1.152-1.536-.352-3.072-.64-4.64-.896-6.08-1.216-12.736-2.08-20.544-2.08-66.752 0-109.088 48.544-123.04 67.296V160H160v352h114.496V320s86.528-120.512 123.04-32v224H512V274.464c0-53.184-36.448-97.504-85.632-110.336z" />
        <circle cx="56" cy="56" r="56" />
      </symbol>
      <symbol id="ico-instagram" viewBox="0 0 64 64">
        <path d="M18.5.3h27.1C55.7.3 64 8.5 64 18.5v27c0 10-8.3 18.2-18.5 18.2h-27C8.3 63.7 0 55.5 0 45.5v-27C0 8.5 8.3.3 18.5.3zm13.3 14.6c9.9 0 17.9 8 17.9 17.9 0 9.9-8 17.9-17.9 17.9-9.9 0-17.9-8-17.9-17.9 0-9.9 8.1-17.9 17.9-17.9zm0 6c6.5 0 11.8 5.3 11.8 11.8s-5.3 11.8-11.8 11.8c-6.5 0-11.8-5.3-11.8-11.8 0-6.5 5.3-11.8 11.8-11.8zm17.3-8.5c1.6 0 2.9 1.3 2.9 2.9 0 1.6-1.3 2.9-2.9 2.9-1.6 0-2.9-1.3-2.9-2.9 0-1.6 1.3-2.9 2.9-2.9zM20.7 5.5h22.6c8.5 0 15.5 6.9 15.5 15.4v22.8c0 8.5-7 15.4-15.5 15.4H20.7C12.2 59 5.2 52 5.2 43.6V20.8c0-8.4 7-15.3 15.5-15.3z" fillRule="evenodd" clipRule="evenodd" />
      </symbol>
      <symbol id="ico-facebook" viewBox="0 0 512 512">
        <path d="M288 176v-64c0-17.664 14.336-32 32-32h32V0h-64c-53.024 0-96 42.976-96 96v80h-64v80h64v256h96V256h64l32-80h-96z" />
      </symbol>
      <symbol id="ico-youtube" viewBox="0 0 24 24">
        <path d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0C.488 3.45.029 5.804 0 12c.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0C23.512 20.55 23.971 18.196 24 12c-.029-6.185-.484-8.549-4.385-8.816zM9 16V8l8 3.993L9 16z" />
      </symbol>
      <symbol id="arrow" viewBox="0 0 11.314 11.314">
        <path data-name="Rettangolo 23" d="M.814 5.157h10v1h-10z" />
        <path data-name="Rettangolo 24" d="M5.657 0l5.657 5.657-.707.707L4.95.707z" />
        <path data-name="Rettangolo 25" d="M11.314 5.657l-5.657 5.657-.707-.707 5.657-5.657z" />
      </symbol>
    </svg>
  )
}
export default SVGSprite
