import hexToRgba from 'hex-rgba'
import generateBreakPoints from 'react-jss-grid/utils/breakpoints'

const padding = [0, '80px']
const paddingMobile = [0, '20px']

export const sizes = {
  xs: 575,
  sm: 576,
  md: 720,
  lg: 992,
  xl: 1025,
  xxl: 1440,
}
const media = {
  xs: `@media (max-width: ${sizes.sm - 1}px)`,
  sm: `@media (min-width: ${sizes.sm}px)`,
  md: `@media (min-width: ${sizes.md}px)`,
  lg: `@media (min-width: ${sizes.lg}px)`,
  xl: `@media (min-width: ${sizes.xl}px)`,
  xxl: `@media (min-width: ${sizes.xxl}px)`,
}
const mediaInverse = {
  xs: `@media (max-width: ${sizes.sm - 1}px)`,
  sm: `@media (max-width: ${sizes.md - 1}px)`,
  md: `@media (max-width: ${sizes.lg - 1}px)`,
  lg: `@media (max-width: ${sizes.xl - 1}px)`,
  xl: `@media (max-width: ${sizes.xxl - 1}px)`,
  xxl: `@media (min-width: ${sizes.xxl}px)`,
  smLand: `@media (max-width: ${sizes.md - 1}px) and (orientation: landscape)`,
}

function supportPassive() {
  let passiveIfSupported = false
  try {
    window.addEventListener('test', null, Object.defineProperty({}, 'passive', { get: () => passiveIfSupported = { passive: true } }))
  } catch (err) {
    window.console.log('error:: passive event')
  }
  return passiveIfSupported
}

const colors = [
  '#383836', // #0 Dark Turri
  '#ffffff', // #1 White
  '#000000', // #2 Black
  '#c9c4bd', // #3 Grey Turri
  '#EFEFEF', // #4 White Turri
  '#6e6e6e', // #5 Grey
  '#e2e2e2', // #6 Medium Grey
  '#f4f2f2', // #7 Light Grey
  '', // #8
  '', // #9
  '#eac02d', // #10 Yellow Selection CSS

]

const fonts = [
  '"ATSurt-Bold", "Helvetica Neue", Helvetica, Arial, sans-serif', // [0]
  '"ATSurt-Light", Helvetica, Arial, sans-serif', // [1]
]

const easings = {
  'power3.out': 'cubic-bezier(0.215, 0.610, 0.355, 1.000)', // [0]
  'power3.inOut': 'cubic-bezier(0.645, 0.045, 0.355, 1.000)', // [1]
  'power4.out': 'cubic-bezier(0.76, 0, 0.24, 1)', // [2]
}

const general = {
  fontFamily: fonts[1],
  fontSize: 20,
  lineHeight: 1.25,
  color: colors[4],
  '& a': {
    color: colors[4],
  },
  textAlign: 'left',
  // textJustify: 'inter-word',
}

const zindex = {
  overlay: 97,
  mainNav: 99,
  header: 100,
  cursor: 102,
  modal: 103,
  cookiebar: 300,
  spinner: 499,
  loader: 500,
}

const headings = {
  fontFamily: fonts[1],
  fontWeight: 'normal',
  lineHeight: 1.2,
  margin: 0,
  padding: 0,
  color: colors[4],
  '& strong, & b': {
    fontWeight: 'bold',
  },
  '& p': {
    margin: 0,
  },
}

const pretitle = {
  fontSize: 11,
  fontWeight: 700,
  letterSpacing: '1px',
  fontFamily: fonts[2],
  textTransform: 'uppercase',
}

const typography = {
  general: {
    extend: general,
  },
  headings: {
    extend: headings,
  },
}

const wrapper = {
  padding: [0, '4.2vw'],
  // [mediaInverse.md]: {
  //   padding: [0, '4vw'],
  // },
}

const smallWrapper = {
  padding: [0, '9vw'],
  [mediaInverse.md]: {
    padding: [0, '7vw'],
  },
}

export const theme = {
  media,
  mediaInverse,
  colors,
  zindex,
  wrapper,
  smallWrapper,
  easings,
  fonts,
  pretitle,
  typography,
  detect: {
    hasTouchWin: navigator.msMaxTouchPoints && navigator.msMaxTouchPoints > 1,
    hasPointer: !!window.navigator.msPointerEnabled,
    hasTouch: 'ontouchstart' in document,
    passive: supportPassive(),
    isIos: !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform),
    isSafari: navigator.vendor && navigator.vendor.indexOf('Apple') > -1 && navigator.userAgent && navigator.userAgent.indexOf('CriOS') === -1 && navigator.userAgent.indexOf('FxiOS') === -1,
    isIE11: !!window.MSInputMethodContext && !!document.documentMode,
    isNativeScroll: ('ontouchstart' in document && window.innerWidth <= 1280) || (!!window.MSInputMethodContext && !!document.documentMode), // (hasTouch && winW <= 1280) || isIE11
    // isNativeScroll: true,
    dom2webgl: !(!!window.MSInputMethodContext && !!document.documentMode) && !('ontouchstart' in document && window.innerWidth <= 1280), // Non IE11 e Non touch con larghezza > 1280
  },
  maxWidth: 1600,
  padding,
  paddingMobile,
  header: {
    top: 20,
    bottom: 20,
    logo: {
      width: 90,
      height: 65,
      // fill: colors[4],
    },
    burger: {
      wrap: 45,
      width: 15,
      height: 10,
    },
  },
  animations: {
    ease: 'cubic-bezier(0.650, 0.075, 0.400, 0.935)',
    time: '0.6s',
  },
  getRgba: (col, opacity) => hexToRgba(col, opacity * 100),
  breakpoints: generateBreakPoints({
    values: sizes,
  }),
}
