import { useEffect, useCallback } from 'react'
import { ThemeProvider } from 'react-jss'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'
import GeneralContext from '@/context'
import ScrollbarContext from '@/context/scrollbar'
import MouseContext from '@/context/mouse'
import LoadingContext from '@/context/loading'
import Handlers from '@/components/Handlers'
import BannerIE11 from '@/components/BannerIE11'
import Routes from '@/router'
import Layout from '@/layout'
import * as routingActions from '@/actions/routing'
import * as optionsActions from '@/actions/options'
import * as navActions from '@/actions/nav'
import * as localeActions from '@/actions/locale'
import useViewport from '@/hooks/useViewport'
import { theme } from './style'

const App = () => {
  const { routes, currentLanguage } = useSelector((state) => ({
    routes: state.routing.routes,
    currentLanguage: state.locale.currentLanguage,
  }), shallowEqual)

  const { vw, vh, vwfixed, vhfixed } = useViewport()

  document.documentElement.style.setProperty('--vw', `${vw}px`)
  document.documentElement.style.setProperty('--vh', `${vh}px`)
  document.documentElement.style.setProperty('--vw-fixed', `${vwfixed}px`)
  document.documentElement.style.setProperty('--vh-fixed', `${vhfixed}px`)

  const dispatch = useDispatch()
  const fetchRouting = useCallback(() => dispatch(routingActions.fetchRouting()), [dispatch])
  const receiveRouting = useCallback((data) => dispatch(routingActions.receiveRouting(data)), [dispatch])
  const fetchOptions = useCallback(() => dispatch(optionsActions.fetchOptions()), [dispatch])
  const fetchMenu = useCallback((name) => dispatch(navActions.fetchMenu(name)), [dispatch])
  const fetchLocales = useCallback(() => dispatch(localeActions.fetchLocales()), [dispatch])
  const setLanguage = useCallback(() => dispatch(localeActions.setLanguage()), [dispatch])

  useEffect(() => {
    setLanguage()
  }, [])

  useEffect(() => {
    if (currentLanguage !== null) {
      if (window.routing) {
        receiveRouting(window.routing)
      } else {
        fetchRouting()
      }
      fetchLocales()
      fetchOptions()
      fetchMenu('main_menu')
      fetchMenu('extra_menu')
    }
  }, [currentLanguage])

  return routes?.length > 0 && (
    <ThemeProvider theme={theme}>
      <Handlers>
        <GeneralContext>
          <LoadingContext>
            <MouseContext>
              <ScrollbarContext>
                <Layout>
                  {theme.detect.isIE11 && (
                    <BannerIE11 />
                  )}
                  <Routes routes={routes} />
                </Layout>
              </ScrollbarContext>
            </MouseContext>
          </LoadingContext>
        </GeneralContext>
      </Handlers>
    </ThemeProvider>
  )
}

export default App
