import classNames from 'classnames'
import injectSheet from 'react-jss'
import { useSelector } from 'react-redux'
import InViewTrigger from '@/components/InViewTrigger'
import RevealText from '@/components/RevealText'
import RevealFadeIn from '@/components/RevealFadeIn'
import DelayLink from '@/components/DelayLink'
import RevealImage from '@/components/RevealImage'
import BtnLink from '@/components/BtnLink'
import { cutExcerpt } from '@/utils/strings'
import style from './style'

const Banner = ({
  pretitle,
  image,
  title,
  text,
  label,
  link,
  classes,
  className,
  imageRight,
  verticalCentered,
  excerptChar,
}) => {
  const strings = useSelector((state) => state.options.strings)
  return (
    <div
      className={classNames({
        [classes.root]: true,
        [className]: className,
      })}
    >
      <div
        className={classes.wrapperBanner}
      >
        <div
          className={classNames({
            [classes.wrapperBannerLeft]: true,
            [classes.wrapperBannerLeftInverse]: imageRight,
          })}
        >
          <DelayLink
            to={link}
            className={classes.linkImage}
          >
            <InViewTrigger
              className={classes.triggerImage}
            >
              <RevealImage
                image={image}
                label={title}
              />
            </InViewTrigger>
          </DelayLink>
        </div>
        <div
          className={classNames({
            [classes.wrapperBannerRight]: true,
            [classes.wrapperBannerRightInverse]: imageRight,
            [classes.verticalCentered]: verticalCentered && !imageRight,
            [classes.verticalCenteredInverse]: verticalCentered && imageRight,
          })}
        >
          <DelayLink
            to={link}
          >
            <InViewTrigger>
              <RevealText
                tag="h4"
                type="lines"
                value={pretitle}
              />
              <RevealText
                tag="h2"
                type="lines"
                value={title}
              />
              <RevealText
                tag="h3"
                type="lines"
                value={text?.length > excerptChar ? cutExcerpt(text, excerptChar) : text}
              />
            </InViewTrigger>
          </DelayLink>
          <InViewTrigger>
            <RevealFadeIn>
              <BtnLink
                link={link}
                label={label || strings.read_more_label}
              />
            </RevealFadeIn>
          </InViewTrigger>
        </div>
      </div>
    </div>
  )
}

Banner.defaultProps = {
  imageRight: false,
  verticalCentered: false,
  excerptChar: 200,
}

export default injectSheet(style)(Banner)
