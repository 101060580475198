const style = (theme) => {
  const output = {
    root: {
      minHeight: '100vh',
      paddingTop: (props) => (props.headerHeight),
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    page: {
      extend: theme.wrapper,
      paddingTop: 50,
      paddingBottom: 100,
    },
    pageTitle: {
      fontSize: 43,
      color: theme.colors[4],
      marginBottom: 30,
    },
    pageContent: {
      fontSize: 14,
      color: theme.colors[4],
      '& a': {
        color: theme.colors[4],
        textDecoration: 'underline',
      },
      '& > *:not(:last-child)': {
        marginBottom: 25,
      },
    },
  }

  /*------------------------------
  MD
  ------------------------------*/
  output[theme.mediaInverse.md] = {
  }

  /*------------------------------
  SM
  ------------------------------*/
  output[theme.mediaInverse.sm] = {
  }

  return output
}

export default style
