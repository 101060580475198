/* eslint-disable react/jsx-props-no-spreading */
import { useState, useEffect, useRef, createRef } from 'react'
import { createUseStyles } from 'react-jss'
import { useDrag } from 'react-use-gesture'
import gsap from 'gsap'
import classNames from 'classnames'
// import MagneticButton from '@/components/MagneticButton'
import usePrevious from '@/hooks/usePrevious'
import style from './style'

const useStyles = createUseStyles(style)

const CarouselSimple = ({ imagesList }) => {
  const classes = useStyles()
  const [active, setActive] = useState(0)
  const [arrow, setArrow] = useState('next')
  const [isDown, setDown] = useState(false)
  const prevActive = usePrevious(active)
  const $images = useRef(imagesList.map(() => createRef()))

  gsap.defaults({
    ease: 'power3.out',
    duration: 0.5,
  })

  useEffect(() => {
    gsap.set($images.current[active].current, {
      opacity: 1,
    })
  }, [])

  useEffect(() => {
    gsap.to($images.current[prevActive]?.current, {
      opacity: 0,
      x: arrow === 'next' ? '-100%' : '100%',
      scale: 0.8,
    })
    gsap.fromTo($images.current[active].current, {
      opacity: 0,
      scale: 0.8,
      x: arrow === 'prev' ? '-100%' : '100%',
    },
    {
      opacity: 1,
      scale: 1,
      x: '0%',
    })
  }, [active, arrow])

  const goPrev = () => {
    setActive(active - 1 < 0 ? imagesList.length - 1 : active - 1)
    setArrow('prev')
  }

  const goNext = () => {
    setActive(active + 1 >= imagesList.length ? 0 : active + 1)
    setArrow('next')
  }

  const renderButtons = () => {
    return (
      <>
        <button
          className={classNames({
            [classes.button]: true,
            [classes.arrow]: true,
            [classes.prev]: true,
          })}
          onClick={goPrev}
        >
          <svg><use xlinkHref="#ico-arrow-left" /></svg>
        </button>
        <button
          className={classNames({
            [classes.button]: true,
            [classes.arrow]: true,
            [classes.next]: true,
          })}
          onClick={goNext}
        >
          <svg><use xlinkHref="#ico-arrow-right" /></svg>
        </button>
      </>
    )
  }

  const renderImages = () => {
    return imagesList.map((img, index) => (
      <div
        ref={$images.current[index]}
        key={index.toString()}
        className={classNames({
          [classes.image]: true,
          [classes.active]: active === index,
        })}
      >
        <img
          src={img.sizes.large}
          alt={img.name}
        />
      </div>
    ))
  }

  const bindDrag = useDrag(({ down, direction: [xDir], distance }) => {
    setDown(down)
    if (!down && distance > 50) {
      if (xDir > 0) {
        goPrev()
      } else {
        goNext()
      }
    }
  })

  return (
    <div className={classes.root}>
      <div
        className={classNames({
          [classes.images]: true,
          [classes.isDragging]: isDown,
        })}
        {...bindDrag()}
      >
        {renderImages()}
      </div>
      {renderButtons()}
    </div>
  )
}

export default CarouselSimple
